import {
  ConditionType,
  AssessmentQuestionType,
  StateType,
  ActionTypeEnum,
  ActionType,
  AssessmentAnswerType,
  AssessmentTranslationField,
  LanguageKey,
} from './AssessmentEditor.types';

import { QuestionQuestionType, TextTranslationType } from '@/apollo/types';
import { replaceInArray, removeFromArray } from '@/utils/arrayUtils';
import { ASSESSMENT_FIELDS_WITH_TRANSLATIONS } from '@/utils/constants';

const blankCondition = {
  question: null,
  answer: null,
} as ConditionType;

export const blankTranslation = {
  en: '',
  es: '',
} as TextTranslationType;

export const blankAnswer = {
  text: '',
  assessmentValue: 0,
  textTranslations: blankTranslation,
};

const blankQuestion = {
  answers: null,
  conditions: [],
  isOptional: false,
  isSensitive: false,
  questionType: null,
  keyString: null,
  text: '',
  textTranslations: blankTranslation,
} as AssessmentQuestionType;

const shouldHaveAnswers = (questionType: QuestionQuestionType): boolean => (
  questionType === QuestionQuestionType.MultiSelect || questionType === QuestionQuestionType.MultipleChoice
);

const checkConditionForErrors = (condition: ConditionType): ConditionType => {
  const hasConditionQuestion = !!condition.question || condition.question === 0;
  const hasConditionAnswer = !!condition.answer || condition.answer === 0;
  let error: string = null;
  if (hasConditionQuestion && !hasConditionAnswer) {
    error = 'Condition must have an answer it depends on';
  }
  if (!hasConditionQuestion && !hasConditionAnswer) {
    error = 'Condition must have a question it depends on';
  }
  return {
    ...condition,
    error,
  };
};

const checkAnswerForErrors = (answer: AssessmentAnswerType): AssessmentAnswerType => {
  let error: string = null;
  if (answer.assessmentValue < 0) {
    error = 'Answer assessment value cannot be negative';
  }
  if (!answer.text) {
    error = 'Answer text is required';
  }
  return {
    ...answer,
    error,
  };
};

const checkQuestionForErrors = (question: AssessmentQuestionType): AssessmentQuestionType => {
  let error: string = null;
  if (!question.text) {
    error = 'Question text is required';
  }
  if (!question.questionType) {
    error = 'Question type is required';
  }
  let { answers } = question;
  if (shouldHaveAnswers(question.questionType)) {
    if (!Array.isArray(question.answers) || question.answers.length === 0) {
      error = `Questions with question type ${question.questionType} are required to have answer choices`;
    } else {
      answers = question.answers.map((answer) => checkAnswerForErrors(answer));
    }
  }
  const conditions = question.conditions.map((condition) => checkConditionForErrors(condition));
  return {
    ...question,
    answers,
    conditions,
    error,
  };
};

export const checkStateForErrors = (state: StateType): StateType => {
  let error: string = null;
  if (state.questions.length === 0) {
    error = 'Assessment must have at least one valid question';
  }
  if (!state.nameInternal) {
    error = 'Assessment must have an internal name';
  }
  if (!state.name) {
    error = 'Assessment must have a name';
  }
  const questions = state.questions.map((question) => checkQuestionForErrors(question));
  return {
    ...state,
    questions,
    error,
    hasErrors: !!error || questions.some(
      (question) => (
        question.error
          || (question.answers && question.answers.some((answer) => answer.error))
          || (question.conditions && question.conditions.some((condition) => condition.error))
      ),
    ),
  };
};

export const answerExists = (
  question: AssessmentQuestionType,
  conditionAnswer: number,
) => Array.isArray(question?.answers)
    && question.answers.length > 0
    && question.answers.length > conditionAnswer;

/**
 * cleanUpConditions is a helper function for the MOVE_QUESTION action.
 * It exists to repopulate conditions with the correct data after the question order changes. This was
 * necessary as using just the indexes made recalculating the condition questions much harder. This
 * function also cleans up all the intermediary data/structures before updating the state.
 */
export const cleanUpConditions = (questions: AssessmentQuestionType[]) => (
  questions.map((question, index): AssessmentQuestionType => ({
    ...question,
    conditions: question.conditions.map((condition) => {
      const conditionQuestionExists = condition?.question !== null && condition?.question !== undefined;
      if (condition.question >= index || condition.question < 0 || !conditionQuestionExists) {
        return null;
      }
      const conditionCopy = { ...condition };
      delete conditionCopy.questionText;

      const conditionAnswerIndex = conditionCopy.answer as number;
      const answer = answerExists(questions[condition.question], conditionAnswerIndex) ? conditionCopy.answer : null;

      return {
        ...conditionCopy,
        answer,
        question: condition.question,
      };
    }).filter((condition) => !!condition),
  }))
);

export const shiftConditonQuestionIndexesForMove = (
  questions: AssessmentQuestionType[],
  questionIndex: number,
  direction: 'up' | 'down',
) => {
  const shiftAmount = direction === 'up' ? -1 : 1;
  return questions.map((question) => {
    const questionCopy = { ...question };
    questionCopy.conditions = questionCopy.conditions.map((condition) => {
      if (condition.question === questionIndex) {
        return {
          ...condition,
          question: condition.question + shiftAmount,
        };
      }
      if (condition.question === questionIndex + shiftAmount) {
        return {
          ...condition,
          question: condition.question - shiftAmount,
        };
      }
      return condition;
    });
    return questionCopy;
  });
};

export const shiftConditionQuestionIndexesForRemovedQuestion = (
  questions: AssessmentQuestionType[],
  questionIndex: number,
) => questions.map((question) => {
  const questionCopy = { ...question };
  questionCopy.conditions = questionCopy.conditions.map((condition) => {
    if (condition.question === questionIndex) {
      return {
        ...condition,
        question: null,
      };
    }
    if (condition.question > questionIndex) {
      return {
        ...condition,
        question: condition.question - 1,
      };
    }
    return condition;
  });
  return questionCopy;
});

export const editAssessmentField = (state: StateType, action: ActionType): StateType => {
  if (action.type === ActionTypeEnum.EDIT_ASSESSMENT_FIELD) {
    if (ASSESSMENT_FIELDS_WITH_TRANSLATIONS.includes(action.field)) {
      const newText = action.value as string;
      const translationField = `${action.field}Translations` as AssessmentTranslationField;
      return {
        ...state,
        [action.field]: action.value,
        [translationField]: {
          ...state[translationField],
          en: newText,
        },
      };
    }

    return {
      ...state,
      [action.field]: action.value,
    };
  }
  return state;
};

export const addQuestion = (state: StateType, action: ActionType): StateType => {
  if (!state.isLocked && action.type === ActionTypeEnum.ADD_QUESTION) {
    return {
      ...state,
      questions: [...state.questions, blankQuestion],
    };
  }
  return state;
};

export const editQuestion = (state: StateType, action: ActionType): StateType => {
  if (!state.isLocked && action.type === ActionTypeEnum.EDIT_QUESTION) {
    const questionToEdit = {
      ...(state.questions[action.questionIndex]),
      [action.field]: action.value,
    } as AssessmentQuestionType;
    if (action.field === 'questionType') {
      if (action.value === 'MULTIPLE_CHOICE' || action.value === 'MULTI_SELECT') {
        // can automatically set 2 answers here since there should always be at least 2
        questionToEdit.answers = Array.isArray(questionToEdit.answers)
          ? questionToEdit.answers
          : Array(2).fill(blankAnswer);
      } else {
        questionToEdit.answers = null;
      }
    }
    if (action.field === 'text') {
      questionToEdit.textTranslations = {
        ...questionToEdit.textTranslations,
        en: action.value as string,
      };
    }
    const newQuestions = replaceInArray(state.questions, questionToEdit, action.questionIndex);
    return {
      ...state,
      questions: cleanUpConditions(newQuestions),
    };
  }
  return state;
};

/**
 * fillQuestionConditionText is a helper function for the MOVE_QUESTION action.
 * It exists to transfer question condition data to an identifiable format (the question text)
 * for easy repopulation after question orders have changed.
 */
const fillQuestionConditionText = (questions: AssessmentQuestionType[]) => (
  questions.map((question): AssessmentQuestionType => ({
    ...question,
    conditions: question.conditions.map((condition) => ({
      ...condition,
      questionText: questions[condition.question].text,
    })),
  }))
);

export const moveQuestionUp = (questions: AssessmentQuestionType[], questionIndex: number) => {
  if (questionIndex === 0) {
    return questions;
  }
  const questionsCopy: AssessmentQuestionType[] = [...questions];
  [questionsCopy[questionIndex]] = questionsCopy.splice(
    questionIndex - 1,
    1,
    questionsCopy[questionIndex],
  );
  return cleanUpConditions(shiftConditonQuestionIndexesForMove(
    questionsCopy,
    questionIndex,
    'up',
  ));
};

export const moveQuestionDown = (questions: AssessmentQuestionType[], questionIndex: number) => {
  if (questionIndex === questions.length - 1) {
    return questions;
  }

  const questionsCopy: AssessmentQuestionType[] = [...questions];
  [questionsCopy[questionIndex]] = questionsCopy.splice(
    questionIndex + 1,
    1,
    questionsCopy[questionIndex],
  );
  return cleanUpConditions(shiftConditonQuestionIndexesForMove(
    questionsCopy,
    questionIndex,
    'down',
  ));
};

const moveQuestion = (state: StateType, action: ActionType): StateType => {
  if (!state.isLocked && action.type === ActionTypeEnum.MOVE_QUESTION) {
    const questions = fillQuestionConditionText(cleanUpConditions(state.questions));
    let shiftedQuestions = [...questions];
    if (action.direction === 'up') {
      shiftedQuestions = moveQuestionUp(questions, action.questionIndex);
    } else if (action.direction === 'down') {
      shiftedQuestions = moveQuestionDown(questions, action.questionIndex);
    }

    const scrollTo = action.direction === 'up' ? action.questionIndex - 1 : action.questionIndex + 1;
    document.getElementById(`question-${scrollTo}`)?.scrollIntoView();

    return {
      ...state,
      questions: shiftedQuestions,
    };
  }
  return state;
};

export const removeQuestion = (state: StateType, action: ActionType): StateType => {
  if (!state.isLocked && action.type === ActionTypeEnum.REMOVE_QUESTION) {
    const questions = fillQuestionConditionText(cleanUpConditions(state.questions));
    const newQuestions = removeFromArray(questions, action.questionIndex);
    const shiftedQuestions = shiftConditionQuestionIndexesForRemovedQuestion(newQuestions, action.questionIndex);
    return {
      ...state,
      questions: cleanUpConditions(shiftedQuestions),
    };
  }
  return state;
};

const addAnswer = (state: StateType, action: ActionType): StateType => {
  if (!state.isLocked && action.type === ActionTypeEnum.ADD_ANSWER) {
    const questionToAddAnswer = {
      ...(state.questions[action.questionIndex]),
      answers: [...(state.questions[action.questionIndex].answers), blankAnswer],
    };
    const questions = replaceInArray(state.questions, questionToAddAnswer, action.questionIndex);
    return {
      ...state,
      questions,
    };
  }
  return state;
};

const removeAnswer = (state: StateType, action: ActionType): StateType => {
  if (!state.isLocked && action.type === ActionTypeEnum.REMOVE_ANSWER) {
    const questions = fillQuestionConditionText(cleanUpConditions(state.questions));

    const { answers } = questions[action.questionIndex];
    const questionToRemoveAnswer = {
      ...(questions[action.questionIndex]),
      answers: removeFromArray(answers, -1),
    };
    const newQuestions = replaceInArray(questions, questionToRemoveAnswer, action.questionIndex);
    return {
      ...state,
      questions: cleanUpConditions(newQuestions),
    };
  }
  return state;
};

export const editAnswerText = (state: StateType, action: ActionType): StateType => {
  if (!state.isLocked && action.type === ActionTypeEnum.EDIT_ANSWER_TEXT) {
    const questionBeingEdited = state.questions[action.questionIndex];
    const answerBeingEdited = questionBeingEdited.answers[action.answerIndex];
    const editedAnswer = {
      ...answerBeingEdited,
      text: action.value,
      textTranslations: {
        ...answerBeingEdited.textTranslations,
        en: action.value,
      },
    };
    const answers = replaceInArray(questionBeingEdited.answers, editedAnswer, action.answerIndex);
    const editedQuestion = {
      ...questionBeingEdited,
      answers,
    };
    const questions = replaceInArray(state.questions, editedQuestion, action.questionIndex);

    return {
      ...state,
      questions,
    };
  }
  return state;
};

const editAnswerAssessmentValue = (state: StateType, action: ActionType): StateType => {
  if (!state.isLocked && action.type === ActionTypeEnum.EDIT_ANSWER_ASSESSMENT_VALUE) {
    const questionBeingEdited = state.questions[action.questionIndex];
    const answerBeingEdited = questionBeingEdited.answers[action.answerIndex];
    const editedAnswer = {
      ...answerBeingEdited,
      assessmentValue: action.value || undefined,
    };
    const answers = replaceInArray(questionBeingEdited.answers, editedAnswer, action.answerIndex);
    const editedQuestion = {
      ...questionBeingEdited,
      answers,
    };
    const questions = replaceInArray(state.questions, editedQuestion, action.questionIndex);
    return {
      ...state,
      questions,
    };
  }
  return state;
};

const addCondition = (state: StateType, action: ActionType): StateType => {
  if (!state.isLocked && action.type === ActionTypeEnum.ADD_CONDITION) {
    const questionToAddCondition = state.questions[action.questionIndex];
    const questionWithNewCondition = {
      ...questionToAddCondition,
      conditions: [...(state.questions[action.questionIndex].conditions), blankCondition],
      isOptional: true,
    };
    const questions = replaceInArray(state.questions, questionWithNewCondition, action.questionIndex);
    return {
      ...state,
      questions,
    };
  }
  return state;
};

const editCondition = (state: StateType, action: ActionType): StateType => {
  if (!state.isLocked && action.type === ActionTypeEnum.EDIT_CONDITION) {
    const questionToEditCondition = state.questions[action.questionIndex];
    const conditionToEdit = {
      ...questionToEditCondition.conditions[action.conditionIndex],
      [action.field]: action.value,
    };

    if (action.field === 'question') {
      conditionToEdit.answer = null;
    }

    const conditions = replaceInArray(questionToEditCondition.conditions, conditionToEdit, action.conditionIndex);
    const editedQuestion = {
      ...questionToEditCondition,
      conditions,
    };
    const questions = replaceInArray(state.questions, editedQuestion, action.questionIndex);
    return {
      ...state,
      questions,
    };
  }
  return state;
};

const removeCondition = (state: StateType, action: ActionType): StateType => {
  if (!state.isLocked && action.type === ActionTypeEnum.REMOVE_CONDITION) {
    const conditions = removeFromArray(state.questions[action.questionIndex].conditions, -1);
    const questionToRemoveCondition = {
      ...(state.questions[action.questionIndex]),
      conditions,
      isOptional: conditions.length === 0 ? false : state.questions[action.questionIndex].isOptional,
    };
    const questions = replaceInArray(state.questions, questionToRemoveCondition, action.questionIndex);
    return {
      ...state,
      questions,
    };
  }
  return state;
};

export const save = (state: StateType, action: ActionType): StateType => {
  if (action.type === ActionTypeEnum.SAVE) {
    const validatedState = checkStateForErrors(state);
    if (validatedState.hasErrors) {
      return validatedState;
    }
    action.onSave();
    return validatedState;
  }
  return state;
};

const uploadAssessment = (state: StateType, action: ActionType): StateType => {
  if (action.type === ActionTypeEnum.UPLOAD_ASSESSMENT) {
    return action.uploadedAssessment;
  }
  return state;
};

export const editAssessmentTextTranslation = (state: StateType, action: ActionType): StateType => {
  if (action.type === ActionTypeEnum.EDIT_ASSESSMENT_TEXT_TRANSLATION) {
    const { fieldName, language, text } = action;
    const languageKey = language.toLowerCase() as LanguageKey;
    const fieldNameKey = fieldName as AssessmentTranslationField;

    return {
      ...state,
      [fieldNameKey]: {
        ...state[fieldNameKey],
        [languageKey]: text,
      },
    };
  }
  return state;
};

export const editAnswerTextTranslation = (state: StateType, action: ActionType): StateType => {
  if (action.type === ActionTypeEnum.EDIT_ANSWER_TEXT_TRANSLATION) {
    const {
      questionIndex, answerIndex, language, text,
    } = action;
    const languageKey = language.toLowerCase() as LanguageKey;
    const questions = [...state.questions];
    const questionCopy = { ...questions[questionIndex] };
    const answerToEdit = questionCopy.answers[answerIndex];
    const answerCopy = {
      ...answerToEdit,
      textTranslations: {
        ...answerToEdit.textTranslations,
        [languageKey]: text,
      },
    };

    questionCopy.answers = replaceInArray(questionCopy.answers, answerCopy, action.answerIndex);
    const newQuestions = replaceInArray(questions, questionCopy, action.questionIndex);

    return {
      ...state,
      questions: newQuestions,
    };
  }
  return state;
};

export const editQuestionTextTranslation = (state: StateType, action: ActionType): StateType => {
  if (action.type === ActionTypeEnum.EDIT_QUESTION_TEXT_TRANSLATION) {
    const { questionIndex, language, text } = action;
    const languageKey = language.toLowerCase() as LanguageKey;
    const questionCopy = {
      ...state.questions[questionIndex],
      textTranslations: {
        ...state.questions[questionIndex].textTranslations,
        [languageKey]: text,
      },
    };
    const newQuestions = replaceInArray(state.questions, questionCopy, action.questionIndex);
    return {
      ...state,
      questions: newQuestions,
    };
  }
  return state;
};

export const reducer = (state: StateType, action: ActionType): StateType => {
  switch (action.type) {
  case ActionTypeEnum.EDIT_ASSESSMENT_FIELD:
    return editAssessmentField(state, action);
  case ActionTypeEnum.ADD_QUESTION:
    return addQuestion(state, action);
  case ActionTypeEnum.EDIT_QUESTION:
    return editQuestion(state, action);
  case ActionTypeEnum.MOVE_QUESTION:
    return moveQuestion(state, action);
  case ActionTypeEnum.REMOVE_QUESTION:
    return removeQuestion(state, action);
  case ActionTypeEnum.ADD_ANSWER:
    return addAnswer(state, action);
  case ActionTypeEnum.REMOVE_ANSWER:
    return removeAnswer(state, action);
  case ActionTypeEnum.EDIT_ANSWER_TEXT:
    return editAnswerText(state, action);
  case ActionTypeEnum.EDIT_ANSWER_ASSESSMENT_VALUE:
    return editAnswerAssessmentValue(state, action);
  case ActionTypeEnum.ADD_CONDITION:
    return addCondition(state, action);
  case ActionTypeEnum.EDIT_CONDITION:
    return editCondition(state, action);
  case ActionTypeEnum.REMOVE_CONDITION:
    return removeCondition(state, action);
  case ActionTypeEnum.SAVE:
    return save(state, action);
  case ActionTypeEnum.UPLOAD_ASSESSMENT:
    return uploadAssessment(state, action);
  case ActionTypeEnum.EDIT_ASSESSMENT_TEXT_TRANSLATION:
    return editAssessmentTextTranslation(state, action);
  case ActionTypeEnum.EDIT_ANSWER_TEXT_TRANSLATION:
    return editAnswerTextTranslation(state, action);
  case ActionTypeEnum.EDIT_QUESTION_TEXT_TRANSLATION:
    return editQuestionTextTranslation(state, action);
  default:
    throw new Error('Unexpected State');
  }
};
