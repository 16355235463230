import { gql } from '@apollo/client';

import useQuery from '../useQuery';

import { QueryHookOptions, QueryResult } from '@/types';

const getUserInfoPermission = (
  options?: QueryHookOptions,
): Array<QueryResult> => {
  const LIMBIX_USER_QUERY = gql`
    query me {
      me {
        uid
        email
        firstName
        isResearch
        isOperations
      }
    }
  `;
  const user = useQuery(LIMBIX_USER_QUERY, options);

  const STUDY_LIST_QUERY = gql`
    query limbixStudies {
      limbixStudies {
        name
        uid
        startTime
        endTime
        canViewStudy
        canChangeStudy
        canModifyParticipants
        canDeleteParticipants
        isBlinded
      }
    }
  `;
  const studyList = useQuery(STUDY_LIST_QUERY, options);

  return [user, studyList];
};

export default getUserInfoPermission;
