import React, { useState, useEffect } from 'react';
import { useParams, Redirect, useLocation } from 'react-router-dom';
import { LoadingSpinner, Box } from 'react-limbix-ui';
import useDeepCompareEffect from 'use-deep-compare-effect';

import ProviderTable from './ProviderTable';

import { QueryPractitionersArgs } from '@/apollo/types';
import { PRACTITIONERS_LIST } from '@/apollo/queries';
import { UseParamsType } from '@/types';
import history from '@/utils/history';
import { useLazyQuery } from '@/hooks/apollo';
import { isPermissionDeniedError } from '@/utils/errorUtils';
import usePagination, { ColumnToOrderByMap, getDefaultPaginationState } from '@/hooks/usePagination';

const DEFAULT_PAGINATION_STATE = getDefaultPaginationState(
  { columnId: 'createdAt', direction: 'desc' },
);

const columnToOrderByMap: ColumnToOrderByMap = {
  name: ['user__last_name', 'user__first_name'],
  phone: 'user__phone',
  email: 'user__email',
  npi: 'identifier',
  createdAt: 'user__created_at',
  role: 'practitioner_role',
  position: 'position',
  organization: 'organization__name',
  providerPortalAccess: 'portal_access_time',
};

const ProviderList: React.FC = () => {
  const { pdtUid } = useParams<UseParamsType>();
  const { state, pathname } = useLocation<{ searchFilter?: string }>();

  const [searchFilter, setSearchFilter] = useState(state?.searchFilter?.toLowerCase() || '');

  const [
    paginationState,
    paginationQueryVariables,
    updatePaginationState,
  ] = usePagination(
    DEFAULT_PAGINATION_STATE,
    columnToOrderByMap,
  );

  useEffect(() => {
    history.replace(pathname, null);
  }, []);

  const variables = {
    pdtUid,
    ...paginationQueryVariables,
  };
  const [
    practitionerQuery,
    {
      loading,
      data,
      error,
      refetch,
    },
  ] = useLazyQuery<QueryPractitionersArgs>(PRACTITIONERS_LIST, {
    variables,
    fetchPolicy: 'no-cache',
  });
  useDeepCompareEffect(
    () => {
      practitionerQuery({ variables });
    },
    [paginationState],
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  if (isPermissionDeniedError(error)) {
    return <Redirect to="/operations" />;
  }

  const practitioners = data?.practitioners;

  if (practitioners) {
    const totalCount = practitioners.length > 0 ? practitioners[0].total : 0;
    return (
      <Box width="100%">
        <ProviderTable
          pdtUid={pdtUid}
          practitioners={data.practitioners}
          searchString={searchFilter}
          onUpdateSearchString={setSearchFilter}
          paginationState={paginationState}
          updatePaginationState={updatePaginationState}
          totalCount={totalCount}
          onRefetch={refetch}
        />
      </Box>
    );
  }
  return (<></>);
};

export default ProviderList;
