import { useSelector } from 'react-redux';

import useActions from './useActions';

import { RootState } from '@/store';
import { UseAssignmentFiltersReturnType } from '@/types';

const useAssignmentFilters = (): UseAssignmentFiltersReturnType => {
  const { assignmentFilters: assignmentFiltersActions } = useActions();
  const assignmentFilters = useSelector((state: RootState) => (state.assignmentFilters));

  return {
    assignmentFilters,
    assignmentFiltersActions,
  };
};

export default useAssignmentFilters;
