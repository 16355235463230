import { useEffect, useMemo } from 'react';
import { gql, ApolloError } from '@apollo/client';
import useDeepCompareEffect from 'use-deep-compare-effect';

import useQuery from '../useQuery';
import useLazyQuery from '../useLazyQuery';

import {
  QueryStudyAssignmentSchedulesArgs,
  AssignmentScheduleType,
  QueryStudyAssignmentsTableArgs,
  StudyAssignmentsTableType,
} from '@/apollo/types';
import { AccountType, AssignmentStatus } from '@/types';
import { useAssignmentFilters } from '@/hooks/redux';
import usePagination, {
  ColumnToOrderByMap,
  getDefaultPaginationState,
  PaginationState,
  UpdatePaginationStateType,
} from '@/hooks/usePagination';

const DEFAULT_PAGINATION_STATE = getDefaultPaginationState(
  { columnId: 'dueDate', direction: 'desc' },
);

const columnToOrderByMap: ColumnToOrderByMap = {
  assessmentName: 'assessment__name__en',
  pid: 'participant_id',
  participantStatus: 'participant_status',
  visibleDate: 'visible_date',
  dueDate: 'due_date',
  completeTime: 'complete_time',
  clientType: 'type_of_client',
  assignmentType: 'type_of_assignment',
  aboutUser: 'about_participant_id',
};

const STUDY_ASSIGNMENT_SCHEDULES = gql`
  query StudyAssignmentSchedules($studyUid: String!) {
    studyAssignmentSchedules(studyUid: $studyUid) {
      typeForAssignment
      assessment {
        name
        uid
      }
    }
  }
`;

const STUDY_ASSIGNMENTS_QUERY = gql`
  query StudyAssignments(
    $studyUid: String!,
    $userUid: String,
    $assessmentUids: [String],
    $accountType: String,
    $status: String,
    $offset: Int,
    $limit: Int,
    $orderBy: [String]
  ) {
    studyAssignmentsTable(
      studyUid: $studyUid,
      userUid: $userUid,
      assessmentUids: $assessmentUids,
      accountType: $accountType,
      status: $status,
      offset: $offset,
      limit: $limit,
      orderBy: $orderBy,
    ) {
        total
        uid
        accountType
        typeOfAssignment
        typeOfClient
        visibleDate
        dueDate
        completeTime
        weekInProgram
        status
        user {
          uid
        }
        aboutUser {
          uid
        }
        assessment {
          name
          nameInternal
        }
        participantId
        aboutParticipantId
        participantStatus
    }
  }
`;

/**
* Converts a list of Assignment Schedules into a map of assessment's name: uid
* @param assignmentSchedules - List of AssignmentSchedules
* @returns Record<string, string> where the keys are assessment names and the values are their uids
*/
const convertSchedulesIntoAssessmentData = (assignmentSchedules: AssignmentScheduleType[]) => {
  const studyAssignmentSchedules = (assignmentSchedules || []).map((schedule) => ({
    name: schedule?.assessment?.name,
    uid: schedule?.assessment?.uid,
  }));

  const withAdverseEventsRemoved = studyAssignmentSchedules.filter((assignment) => (
    assignment.name && assignment.uid
  ));

  return withAdverseEventsRemoved.reduce((agg, curr) => {
    const aggCopy = { ...agg };
    aggCopy[`${curr.name}`] = curr.uid;
    return aggCopy;
  }, {} as Record<string, string>);
};

type UseStudyAssignmentsType = {
  assessmentNameToUidMap: Record<string, string>,
  studyAssignments: Array<StudyAssignmentsTableType>,
  assignmentsLoading: boolean,
  assessmentsLoading: boolean,
  error: ApolloError,
  paginationState: PaginationState;
  updatePaginationState: UpdatePaginationStateType;
  totalCount: number;
}
const useStudyAssignments = (
  studyUid: string,
  userUid?: string,
): UseStudyAssignmentsType => {
  const { assignmentFilters, assignmentFiltersActions } = useAssignmentFilters();

  const [
    paginationState,
    paginationQueryVariables,
    updatePaginationState,
  ] = usePagination(
    DEFAULT_PAGINATION_STATE,
    columnToOrderByMap,
  );

  const [studyAssignmentsQuery, {
    data,
    loading,
    error,
  }] = useLazyQuery<QueryStudyAssignmentsTableArgs>(
    STUDY_ASSIGNMENTS_QUERY,
    {
      variables: {
        ...assignmentFilters,
        ...paginationQueryVariables,
        studyUid,
        userUid,
      },
      fetchPolicy: 'no-cache',
    },
  );
  const {
    data: scheduleData,
    loading: schedulesLoading,
  } = useQuery<QueryStudyAssignmentSchedulesArgs>(STUDY_ASSIGNMENT_SCHEDULES, { variables: { studyUid } });

  const assessmentNameToUidMap = useMemo(() => (
    convertSchedulesIntoAssessmentData(scheduleData?.studyAssignmentSchedules || [])
  ), [scheduleData?.studyAssignmentSchedules]);

  useEffect(() => {
    assignmentFiltersActions.updateStudyUid(studyUid);
    assignmentFiltersActions.updateUserUid(userUid);
  }, [studyUid, userUid]);

  // reset pagination state when assignment filters change
  useDeepCompareEffect(() => (
    updatePaginationState(DEFAULT_PAGINATION_STATE)
  ), [assignmentFilters]);

  useDeepCompareEffect(() => {
    if (assignmentFilters.studyUid) {
      const cleanVariables = {
        ...assignmentFilters,
        ...paginationQueryVariables,
      };
      if (cleanVariables.accountType === AccountType.None) {
        cleanVariables.accountType = null;
      }
      if (cleanVariables.status === AssignmentStatus.None) {
        cleanVariables.status = null;
      }
      if (!cleanVariables.assessmentUids?.length) {
        cleanVariables.assessmentUids = [''];
      }
      (async () => {
        await studyAssignmentsQuery({
          variables: cleanVariables,
          fetchPolicy: 'no-cache',
        });
      })();
    }
  }, [assignmentFilters, paginationState]);

  const studyAssignments = data?.studyAssignmentsTable || [];
  const totalCount = studyAssignments.length > 0 ? studyAssignments[0].total : 0;

  return {
    assessmentNameToUidMap,
    studyAssignments,
    assignmentsLoading: loading,
    assessmentsLoading: schedulesLoading,
    error,
    paginationState,
    updatePaginationState,
    totalCount,
  };
};

export default useStudyAssignments;
