import React, { useCallback } from 'react';
import {
  DataGrid,
  DataGridRow as Row,
  DataGridColumn as Column,
  Memo,
  Box,
} from 'react-limbix-ui';
import isEqual from 'react-fast-compare';

import {
  AssessmentType,
  ClinicalConcernType,
  EnrollmentTrackerResultType,
} from '@/apollo/types';
import { StudyPermission, MutationResultType } from '@/types';
import { useModal } from '@/hooks/redux';
import {
  makeDynamicData,
  makeUidsForMultiAnswer,
  getEditedRowValues,
  convertRowToAssessmentAnswers,
} from '@/utils/dataGridUtils';

type Props = {
  assessment: AssessmentType;
  assessmentResults: Array<ClinicalConcernType | EnrollmentTrackerResultType>;
  staticColumns: Column[];
  staticCells: Row[];
  permissions: StudyPermission;
  onSaveRow: (uid: string, answers: string[], changeReason: string) => Promise<MutationResultType>;
  onFinishMutations: () => void;
  stickyHeader?: boolean;
};

const AssessmentDataGrid: React.FC<Props> = (props: Props) => {
  const {
    assessment,
    assessmentResults,
    staticColumns,
    staticCells,
    permissions,
    onSaveRow,
    onFinishMutations,
    stickyHeader,
  } = props;

  const {
    dynamicColumns,
    dynamicCells,
  } = makeDynamicData(
    assessment.questions,
    assessmentResults,
    permissions.canModifyParticipants,
  );

  const { showModal } = useModal();

  const columns = [...staticColumns, ...dynamicColumns];
  const rows = dynamicCells.map((dynamicCell, index) => ({
    ...dynamicCell,
    ...staticCells[index],
  }));

  const staticColumnKeys = staticColumns.map((col) => col.field);

  const onSave = useCallback((changedRows: Row[]) => {
    const editedRows = getEditedRowValues(changedRows, staticColumnKeys);
    const mutations = changedRows.map((row) => {
      const uid = row.id.value as string;
      const uidsForMultiAnswer = makeUidsForMultiAnswer(assessment.questions);
      const cleanedRow = convertRowToAssessmentAnswers(row, uidsForMultiAnswer, staticColumnKeys);
      return (changeReason: string) => onSaveRow(uid, cleanedRow, changeReason);
    });
    showModal(
      'DATA_GRID_CONFIRMATION',
      {
        columns,
        editedRows,
        mutations,
        onFinishMutations,
      },
    );
  }, []);

  return (
    <Box width="100%" height="100%">
      <DataGrid
        columns={columns}
        rows={rows}
        onSave={onSave}
        stickyHeader={stickyHeader}
      />
    </Box>
  );
};

export default Memo(AssessmentDataGrid, (prevProps, nextProps) => (
  isEqual(prevProps.assessmentResults, nextProps.assessmentResults)
));
