import React from 'react';

import Styled from '../Modal.styles';
import ModalTitle from '../ModalTitle';

import { ModalPayloadType } from '@/types/ReduxTypes';

type Props = {
  payload: ModalPayloadType['CONTACT_INFO'];
  onClose: () => void;
};

const ContactInfo: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;

  const { studyParticipant } = payload;

  return (
    <div style={{ width: '350px', height: '225px' }}>
      <Styled.DialogContent>
        <div style={{ display: 'block' }}>
          <ModalTitle onClose={onClose}>
            <div>
              Participant Contact Info for
              <strong>
                {` "${studyParticipant.participantId || 'None'}" `}
              </strong>
            </div>
          </ModalTitle>
          <Styled.TextContainer>
            <Styled.BodyText>
              Email:
              <strong>
                {` ${studyParticipant.user.email}`}
              </strong>
              <br />
              Phone Number:
              <strong>
                {` ${studyParticipant.user.phone}`}
              </strong>
            </Styled.BodyText>
          </Styled.TextContainer>
        </div>
      </Styled.DialogContent>
    </div>
  );
};

export default ContactInfo;
