import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import {
  LoadingSpinner,
  PaginatedTable,
  Link,
  Column,
  Row,
  Box,
  calculateNewSortState,
  createColumn,
  createCell,
} from 'react-limbix-ui';

import {
  AssignmentFilters,
} from '@/components';
import {
  AssignmentTypeOfClient,
  AssignmentAccountType,
} from '@/apollo/types';
import { UseParamsType } from '@/types';
import { renderPID } from '@/utils/tableUtils';
import { useStudyAssignments } from '@/hooks/apollo/queries';
import { isPermissionDeniedError } from '@/utils/errorUtils';
import { formatDate } from '@/utils/dateUtils';
import { ASSIGNMENT_FILTER_WIDTH } from '@/utils/constants';
import { usePermissions } from '@/hooks/redux';

const AssignmentList: React.FC = () => {
  const { studyUid, userUid } = useParams<UseParamsType>();
  const [{ studyPermissions }] = usePermissions();

  const {
    assessmentNameToUidMap,
    studyAssignments,
    assessmentsLoading,
    assignmentsLoading,
    error,
    paginationState,
    updatePaginationState,
    totalCount,
  } = useStudyAssignments(studyUid, userUid);

  const { sortState, currentPage, itemsPerPage } = paginationState;

  const permissions = studyPermissions[studyUid];

  if (isPermissionDeniedError(error)) {
    return <Redirect to="/research" />;
  }

  const baseStudyUrl = `/research/study/${studyUid}`;

  const renderAssessmentName = (
    assignmentUserUid: string,
    aboutUserUid: string,
    assessmentName: string,
    assignmentUid: string,
    clientType: AssignmentTypeOfClient,
  ) => {
    const assignmentParam = clientType === AssignmentTypeOfClient.ResopsPortal ? 'research_assignments' : 'assignments';
    const userUidParam = clientType === AssignmentTypeOfClient.ResopsPortal ? aboutUserUid : assignmentUserUid;
    const linkToAssignment = `${baseStudyUrl}/participant/${userUidParam}/${assignmentParam}/${assignmentUid}`;
    return <Link.RouterLink to={linkToAssignment} fontSize="16px">{assessmentName || 'None'}</Link.RouterLink>;
  };

  const renderWeekInProgram = (weekInProgram: string, clientType: AssignmentTypeOfClient) => {
    if (!weekInProgram) {
      return clientType === AssignmentTypeOfClient.ResopsPortal ? 'n/a - research assignment' : 'program not started';
    }
    if (weekInProgram === '0') {
      return 'baseline';
    }
    return `end of week ${weekInProgram}`;
  };

  const columns: Column[] = [
    createColumn('assessmentName', 'Assessment Name', 'string', null, true),
    createColumn('pid', 'PID', 'string', null, true),
    createColumn('participantStatus', 'Participant Status', 'string', null, true),
    createColumn('visibleDate', 'Visible Date', 'date', null, true),
    createColumn('dueDate', 'Due Date', 'date', null, true),
    createColumn('completeTime', 'Complete Time', 'date', null, true),
    createColumn('weekInProgram', 'Week In Program', 'number', null, false),
    createColumn('assignmentStatus', 'Assignment Status', 'string', null, false),
    createColumn('clientType', 'Client Type', 'string', null, true),
    createColumn('assignmentType', 'Assignment Type', 'string', null, true),
    createColumn('aboutUser', 'About User', 'string', null, true),
  ];

  const cleanedAssignments = studyAssignments
    .filter((assignment) => (
      // If blinded, filter out research assignments
      !permissions?.isBlinded || assignment.accountType !== AssignmentAccountType.Research
    ))
    .map((assignment) => (
      {
        uid: assignment.uid,
        assessmentName: (
          assignment.assessment?.name || assignment?.assessment?.nameInternal || assignment?.typeOfAssignment || 'None'
        ),
        assignmentUid: assignment.uid,
        pid: assignment?.participantId,
        userUid: assignment?.user?.uid,
        participantStatus: assignment.participantStatus,
        visibleDate: assignment?.visibleDate,
        dueDate: assignment?.dueDate,
        completeTime: assignment?.completeTime,
        weekInProgram: assignment?.weekInProgram,
        clientType: assignment?.typeOfClient,
        assignmentType: assignment?.typeOfAssignment,
        aboutParticipantPid: assignment?.aboutParticipantId,
        aboutParticipantUserUid: assignment?.aboutUser?.uid,
        assignmentStatus: assignment?.status,
      }
    ));

  const rows: Row[] = cleanedAssignments.map((assignment) => (
     {
       id: assignment.uid,
       cells: [
         createCell(
           'assessmentName',
           assignment.assessmentName,
           renderAssessmentName(
             assignment.userUid,
             assignment.aboutParticipantUserUid,
             assignment.assessmentName,
             assignment.assignmentUid,
             assignment.clientType,
           ),
         ),
         createCell('pid', assignment.pid, renderPID(studyUid, assignment.userUid, assignment.pid)),
         createCell('participantStatus', assignment.participantStatus),
         createCell('visibleDate', new Date(assignment.visibleDate), formatDate(assignment.visibleDate)),
         createCell('dueDate', new Date(assignment.dueDate), formatDate(assignment.dueDate)),
         createCell('completeTime', new Date(assignment.completeTime), formatDate(assignment.completeTime)),
         createCell(
           'weekInProgram',
           assignment.weekInProgram,
           renderWeekInProgram(assignment.weekInProgram, assignment.clientType),
         ),
         createCell('assignmentStatus', assignment.assignmentStatus),
         createCell('clientType', assignment.clientType),
         createCell('assignmentType', assignment.assignmentType),
         createCell(
           'aboutUser',
           assignment.aboutParticipantUserUid,
           renderPID(studyUid, assignment.aboutParticipantUserUid, assignment.aboutParticipantPid),
         ),
       ],
     } as Row
  ));

  return (
    <Box height="calc(100% - 30px)" width="100%" display="inline-flex">
      <Box maxHeight="100%" overflow="scroll" width={ASSIGNMENT_FILTER_WIDTH}>
        {assessmentsLoading ? (
          <LoadingSpinner />
        ) : (
          <AssignmentFilters assessmentNameToUidMap={assessmentNameToUidMap} />
        )}
      </Box>
      <Box maxHeight="100%" overflow="scroll" width={`calc(100% - ${ASSIGNMENT_FILTER_WIDTH})`}>
        {assignmentsLoading ? (
          <LoadingSpinner />
        ) : (
          <PaginatedTable
            ariaLabel="assignment_list"
            columns={columns}
            rows={rows}
            sortState={paginationState.sortState}
            onSortByColumn={
              (colId) => updatePaginationState({ sortState: calculateNewSortState(colId, sortState) })
            }
            currentPage={currentPage}
            onGoToPage={(pageNum) => updatePaginationState({ currentPage: pageNum })}
            totalCount={totalCount}
            itemsPerPage={itemsPerPage}
            skipRowSort
          />
        )}
      </Box>
    </Box>
  );
};

export default AssignmentList;
