import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AccountType,
  AssignmentStatus,
  PaginationType,
  AssignmentFiltersStateType,
} from '@/types';

const defaultAssignmentFiltersState = {
  studyUid: null,
  userUid: null,
  assessmentUids: [],
  status: AssignmentStatus.Past_Due,
  accountType: AccountType.None,
  limit: null,
  offset: null,
  orderBy: ['visible_date'],
} as AssignmentFiltersStateType;

const assignmentFiltersSlice = createSlice({
  name: 'assignmentFilters',
  initialState: defaultAssignmentFiltersState,
  reducers: {
    updateStudyUid: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.studyUid = payload;
    },
    updateUserUid: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.userUid = payload;
    },
    updateAccountType: (state, action: PayloadAction<AccountType>) => {
      const { payload } = action;
      state.accountType = payload;
    },
    updateStatusType: (state, action: PayloadAction<AssignmentStatus>) => {
      const { payload } = action;
      state.status = payload;
    },
    updatePagination: (state, action: PayloadAction<PaginationType>) => {
      const { payload } = action;
      state.limit = payload.limit;
      state.offset = payload.offset;
      state.orderBy = payload.orderBy;
    },
    addAssessmentUids: (state, action: PayloadAction<Array<string>>) => {
      const { payload } = action;
      const assessmentUids = Array.isArray(state.assessmentUids) ? state.assessmentUids : [];
      const assessmentUidsSet = new Set(assessmentUids);
      payload.forEach((value) => {
        if (!assessmentUidsSet.has(value)) {
          assessmentUidsSet.add(value);
        }
      });
      state.assessmentUids = Array.from(assessmentUidsSet).sort();
    },
    removeAssessmentUids: (state, action: PayloadAction<Array<string>>) => {
      const { payload } = action;
      const assessmentUids = Array.isArray(state.assessmentUids) ? state.assessmentUids : [];
      const assessmentUidsSet = new Set(assessmentUids);

      payload.forEach((value) => {
        if (assessmentUidsSet.has(value)) {
          assessmentUidsSet.delete(value);
        }
      });
      state.assessmentUids = Array.from(assessmentUidsSet).sort();
    },
    updateAssessmentUids: (state, action: PayloadAction<Array<string>>) => {
      const { payload } = action;
      state.assessmentUids = payload;
    },
    resetFilters: (state, action: PayloadAction<Array<string>>) => {
      const { payload } = action;
      state.accountType = defaultAssignmentFiltersState.accountType;
      state.status = defaultAssignmentFiltersState.status;
      state.limit = defaultAssignmentFiltersState.limit;
      state.offset = defaultAssignmentFiltersState.offset;
      state.orderBy = defaultAssignmentFiltersState.orderBy;
      state.assessmentUids = payload;
      state.status = AssignmentStatus.None;
    },
  },
});

export type AssignmentFiltersActionsType = typeof assignmentFiltersSlice.actions;

export const { actions, reducer } = assignmentFiltersSlice;
