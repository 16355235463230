import React, { useState } from 'react';
import 'date-fns';
import { Select } from '@material-ui/core';

import Styled from '../Modal.styles';

import { ModalPayloadType } from '@/types/ReduxTypes';
import { LANGUAGE_MAP } from '@/utils/constants';
import { UnknownChangeEvent } from '@/types';
import { LanguageType } from '@/apollo/types';

type Props = {
  payload: ModalPayloadType['UPDATE_PARTICIPANT_LANGUAGE'];
  onClose: () => void;
};
const UpdateParticipantLanguage: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;
  const {
    participantId, currentLanguage, candidateLanguages, onSubmit, participantEmail,
  } = payload;
  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage as LanguageType);
  const formattedParticipantInfo = participantId ? `PID: ${participantId}` : `email: ${participantEmail}`;
  return (
    <div style={{ width: '416px', height: '75%' }}>
      <Styled.DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 16 }}>
          <Styled.HeaderText style={{ marginBottom: 32 }}>
            Set User Language
          </Styled.HeaderText>
          {`Set user language for participant with ${formattedParticipantInfo}`}
          <Select
            labelId="select-language"
            id="select-language"
            native
            value={selectedLanguage}
            onChange={(event: UnknownChangeEvent) => {
              setSelectedLanguage(event.target.value as LanguageType);
            }}
          >
            {candidateLanguages.map((language) => (
              <option key={language} value={language}>
                {LANGUAGE_MAP[language]}
              </option>
            ))}
          </Select>
        </div>
        <div style={{ display: 'flex' }}>
          <Styled.NoButton onClick={onClose}>
            <Styled.NoButtonText>
              Cancel
            </Styled.NoButtonText>
          </Styled.NoButton>
          <Styled.YesButton
            onClick={() => onSubmit(selectedLanguage)}
            color="primary"
          >
            <Styled.YesButtonText>
              Submit
            </Styled.YesButtonText>
          </Styled.YesButton>
        </div>
      </Styled.DialogContent>
    </div>
  );
};

export default UpdateParticipantLanguage;
