import { setContext } from '@apollo/client/link/context';

import { base64ToString } from '@/utils/stringUtils';
import history from '@/utils/history';

const TIME_TO_LIVE_MS = 1000 * 60 * 60;

const checkForTokenExpiration = () => {
  const tokenCreatedAt: string = localStorage.getItem('tokenCreatedAt') || '';
  if (!tokenCreatedAt) {
    localStorage.clear();
    return;
  }
  const tokenExpiration = new Date(parseInt(base64ToString(tokenCreatedAt), 10) + TIME_TO_LIVE_MS);
  if (tokenExpiration <= new Date()) {
    localStorage.clear();
    history.push('/login');
  }
};

export const withTokenLink = setContext((_, { headers }) => {
  checkForTokenExpiration();
  const cachedToken = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: cachedToken ? `JWT ${cachedToken}` : '',
    },
  };
});
