import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ResopsUserType,
  StudyPermissions,
} from '@/types';

const defaultResopsUserTypeState: ResopsUserType = {
  isLoggedIn: false,
  isResearch: false,
  isOperations: false,
  studyPermissions: {} as StudyPermissions,
};

const resopsUserSlice = createSlice({
  name: 'resopsUser',
  initialState: defaultResopsUserTypeState,
  reducers: {
    updateResopsUser: (_, action: PayloadAction<ResopsUserType>) => ({ ...action.payload }),
  },
});

export const { actions, reducer } = resopsUserSlice;
