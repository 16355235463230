import { LanguageType } from '@/apollo/types';

export const GENDER_MAP = {
  M: 'Male',
  F: 'Female',
  X: 'Non-Binary',
  O: 'Other',
};

export const DISTINCT_COLOR_PALETTE = [
  '#e6194B',
  '#3cb44b',
  '#ffe119',
  '#4363d8',
  '#800000',
  '#dcbeff',
  '#808000',
  '#f58231',
  '#000075',
  '#fabed4',
  '#469990',
  '#fffac8',
  '#f032e6',
];

export const ASSIGNMENT_FILTER_WIDTH = '350px';

export const REDACTED_FOR_BLINDING_MESSAGE = '[redacted for blinding]';

export const DEFAULT_LANGUAGE: LanguageType = 'EN' as LanguageType;
export const LANGUAGE_MAP = {
  EN: 'US English',
  ES: 'Spanish',
  KO: 'Korean',
};

export const ASSESSMENT_FIELDS_WITH_TRANSLATIONS = ['contextText', 'introText', 'name', 'preQuestionText'];
