import React, { PropsWithChildren } from 'react';
import {
  IconButton as MuiIconButton,
  withStyles,
} from '@material-ui/core';
import MuiCloseIcon from '@material-ui/icons/Close';
import {
  SizingProps,
  PaletteProps,
  SpacingProps,
  BordersProps,
  FlexboxProps,
} from '@material-ui/system';
import { Box } from 'react-limbix-ui';

const IconButton = withStyles({
  sizeSmall: {
    height: '20px',
    width: '20px',
  },
})(MuiIconButton);

const CloseIcon = withStyles({
  fontSizeSmall: {
    height: '14px',
    width: '14px',
  },
})(MuiCloseIcon);

type Props = {
  onClose?: () => void;
} & PaletteProps
  & Pick<SizingProps, 'width' | 'height' | 'minHeight'>
  & Pick<SpacingProps, 'padding' | 'margin'>
  & Pick<BordersProps, 'borderRadius'>
  & Pick<FlexboxProps, 'alignItems' | 'justifyContent'>;
const Tag: React.FC<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>) => {
  const {
    onClose,
    children,
    width,
    height,
    minHeight,
    bgcolor,
    padding,
    borderRadius,
    margin,
    alignItems,
    justifyContent,
  } = props;

  return (
    <Box
      width={width}
      height={height}
      minHeight={minHeight}
      bgcolor={bgcolor}
      padding={padding}
      border={bgcolor && `1px solid ${bgcolor}`}
      borderRadius={borderRadius}
      margin={margin}
      display="inline-flex"
      alignItems={alignItems || 'center'}
      justifyContent={justifyContent}
    >
      {children}
      {onClose && (
        <IconButton aria-label="close" onClick={onClose} size="small" className="no-padding">
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    </Box>
  );
};

export default Tag;
