import React, { useState } from 'react';

import { LoadingSpinner } from 'react-limbix-ui';
import { TextField } from '@material-ui/core';

import Styled from '../Modal.styles';

import { ModalPayloadType } from '@/types/ReduxTypes';
import ExclamationSVG from '@/assets/ExclamationCircle.svg';

type Props = {
  payload: ModalPayloadType['DELETE_ASSIGNMENT_SCHEDULE'];
  onClose: () => void;
}

const DeleteAssignmentSchedule: React.FC<Props> = ({
  payload,
  onClose,
}: Props) => {
  const {
    assignmentScheduleUid,
    onDelete,
  } = payload;
  const [changeReasonText, setChangeReasonText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [isError, setIsError] = useState(false);

  const canDelete = changeReasonText.length > 0;

  const handleDelete = async () => {
    setIsDeleting(true);
    const response = await onDelete(assignmentScheduleUid, changeReasonText);
    setIsDeleting(false);
    if (response?.errors) {
      setIsError(true);
    } else {
      onClose();
    }
  };

  const modalBody = (
    <>
      <Styled.BodyText>
        {'Are you sure that you want to delete this assignment schedule? \
        Please enter a change reason:'}
      </Styled.BodyText>
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        name="change reason"
        label="Change Reason"
        id="change reason"
        onChange={(event) => setChangeReasonText(event.target.value)}
      />
    </>
  );

  const errorText = (
    <Styled.ErrorText>
      Unable to delete assignment schedule. Check that you have super user permissions.
    </Styled.ErrorText>
  );

  return (
    <Styled.DialogContent>
      <div style={{ display: 'flex', paddingBottom: 32 }}>
        <Styled.WarningSVGContainer>
          <Styled.WarningSVG src={ExclamationSVG} />
        </Styled.WarningSVGContainer>
        <Styled.TextContainer>
          <Styled.HeaderText>
            Delete Assignment Schedule
          </Styled.HeaderText>
          { isError
            ? errorText
            : modalBody }
        </Styled.TextContainer>
      </div>
      <Styled.ButtonContainer>
        { isDeleting
          ? <LoadingSpinner />
          : (
            <>
              <Styled.NoButton onClick={onClose}>
                <Styled.NoButtonText>
                  {isError ? 'Close' : 'No' }
                </Styled.NoButtonText>
              </Styled.NoButton>
              {!isError && (
                <Styled.YesButton
                  onClick={handleDelete}
                  disabled={!canDelete}
                  color={canDelete ? 'primary' : 'secondary'}
                >
                  <Styled.YesButtonText>
                    Delete
                  </Styled.YesButtonText>
                </Styled.YesButton>
              )}
            </>
          )}
      </Styled.ButtonContainer>
    </Styled.DialogContent>
  );
};

export default DeleteAssignmentSchedule;
