import React from 'react';

import { FormControl, InputLabel, Select } from '@material-ui/core';

import { UnknownChangeEvent } from '@/types';
import { formatCapitalSnakeCase } from '@/utils/stringUtils';

type EnumSelectionProps = {
  attributeLabel: string,
  label: string,
  value: string,
  options: {
    label: string,
    value: string,
  }[],
  onSelect?: (event: UnknownChangeEvent) => void,
};

const EnumSelection: React.FC<EnumSelectionProps> = ({
  attributeLabel,
  label,
  value,
  options,
  onSelect,
}) => (
  <FormControl variant="outlined" fullWidth>
    <InputLabel htmlFor={attributeLabel}>{label}</InputLabel>
    <Select
      native
      fullWidth
      value={value}
      onChange={onSelect}
      label={label}
      inputProps={{
        id: attributeLabel,
      }}
    >
      {(options).map((option) => (
        <option key={option.value} value={option.value}>
          {formatCapitalSnakeCase(option.label)}
        </option>
      ))}
    </Select>
  </FormControl>
);

export default EnumSelection;
