import { AssignmentScheduleType } from '@/apollo/types';
import { AccountType, AssignmentPathInfo } from '@/types';

export type HTMLInputChangeEvent = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

export type PointType = {
  x: number,
  y: number
}

export type LineType = {
  start: PointType,
  end: PointType,
}

export type ScheduleType = {
  name: string,
  fillColor: string,
  schedules: LineType[][],
}

export type StateType = {
  accountTypeFilter: AccountType,
  assessmentFilters: Record<string, boolean>,
  pathBeingHovered: string,
  calendarHeight: number,
  cellSize: number,
  generatedSchedules: ScheduleType[],
  colorPalette: string[],
  assignmentPathInfo: AssignmentPathInfo[],
}

export enum ActionTypeEnum {
  UPDATE_ACCOUNT_TYPE_FILTER,
  FLIP_ASSESSMENT_FILTER,
  REPLACE_ASSESSMENT_FILTERS,
  APPLY_ALL_ASSESSMENT_FILTERS,
  UNAPPLY_ALL_ASSESSMENT_FILTERS,
  SET_PATH_BEING_HOVERED,
  SET_CELL_SIZE,
  GENERATE_SCHEDULES,
  CALCULATE_CALENDAR_HEIGHT,
  GENERATE_ASSIGNMENT_PATH_INFO,
}

export type ActionType = {
  type: ActionTypeEnum.UPDATE_ACCOUNT_TYPE_FILTER,
  value: AccountType,
} | {
  type: ActionTypeEnum.FLIP_ASSESSMENT_FILTER,
  assessment: string,
} | {
  type: ActionTypeEnum.REPLACE_ASSESSMENT_FILTERS,
  assessmentFilters: string[],
} | {
  type: ActionTypeEnum.APPLY_ALL_ASSESSMENT_FILTERS,
} | {
  type: ActionTypeEnum.UNAPPLY_ALL_ASSESSMENT_FILTERS,
} | {
  type: ActionTypeEnum.SET_PATH_BEING_HOVERED,
  value: string,
} | {
  type: ActionTypeEnum.SET_CELL_SIZE,
  value: number,
} | {
  type: ActionTypeEnum.GENERATE_SCHEDULES,
  assignmentSchedules: Record<string, AssignmentScheduleType[]>,
} | {
  type: ActionTypeEnum.CALCULATE_CALENDAR_HEIGHT,
} | {
  type: ActionTypeEnum.GENERATE_ASSIGNMENT_PATH_INFO,
  handleHover: (key: string) => void,
};
