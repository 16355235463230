import {
  AppBar as MuiAppBar,
  Toolbar as MuiToolbar,
  Typography as MuiTypography,
} from '@material-ui/core';
import styled from 'styled-components/macro';

const AppBar = styled(MuiAppBar)`
  background-color: #fefefe;
  width: 100vw;
  z-index: 1201;
  position: absolute;
`;

const Toolbar = styled(MuiToolbar)`
  color: black;
  padding: 0;
  z-index: 1201;
`;

const LinkContainer = styled.div<{ withborders?: number }>`
  height: 88px;
  ${(props) => (props.withborders && 'border-right: 1px solid rgba(0, 0, 0, 0.12);')}
`;

const LogoContainer = styled.div`
  margin: 10px 34px 29px 35px;
  text-align: center;
`;

const Logo = styled.img`
  height: 30px;
  width: 113px;
  border-radius: 0px;
`;

const DropdownTabContainer = styled.div<{ withborders?: number }>`
  :hover {
    cursor: pointer;
  }
  :focus {
    outline: none;
  }
  width: min(250px, 55%);
  display: flex;
  height: 88px;
  ${(props) => (props.withborders && 'border-left: 1px solid rgba(0, 0, 0, 0.12);')}
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 10px;
  position: absolute;
  right: 0;
`;

const DropdownTabText = styled.div`
  width: 266px;
`;

const DropdownTabIcon = styled.div`
  margin: auto auto;
`;

const DropdownHeaderText = styled(MuiTypography)`
  font-family: Proxima Nova;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: 0px;
  text-align: center;
`;

const PopperContainer = styled.div`
  padding: 16px;
  transform: translate(4px, 1px);
  width: 250px;
  height: 150px;
  background: #FEFEFE;
  box-shadow: 0px 5.21429px 10.4286px -6.95238px rgba(0, 0, 0, 0.22),
    0px 10.4286px 27.8095px rgba(0, 0, 0, 0.22),
    0px 15.6429px 48.6667px 13.9048px rgba(0, 0, 0, 0.22);
  border-radius: 3.5px;
  z-index: 9999;
`;

export default {
  AppBar,
  Toolbar,
  LinkContainer,
  LogoContainer,
  Logo,
  DropdownTabContainer,
  DropdownHeaderText,
  DropdownTabText,
  DropdownTabIcon,
  PopperContainer,
};
