import {
  Typography as MuiTypography,
  Grid as MuiGrid,
} from '@material-ui/core';
import styled from 'styled-components/macro';

const BoldText = styled(MuiTypography)<{ size?: string }>`
  font-family: Proxima Nova;
  font-size: ${(props) => (props.size ? props.size : '18px')};
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0px;
`;

const DetailText = styled(MuiTypography)`
  font-family: Proxima Nova;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`;

const BORDER_CSS = `
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;
const GridItem = styled(MuiGrid).attrs({
  item: true,
  lg: 3,
  md: 6,
  xs: 12,
})<{ withborders?: number }>`
  @media (max-width: 960px) {
    ${(props) => (props.withborders && 'border-bottom: 1px solid rgba(0, 0, 0, 0.12);')}
    padding: 15px 0px;
  }
  @media (min-width: 960px, max-width: 1279px) {
    ${(props) => (props.withborders && BORDER_CSS)}
    padding: 10px;
  }
  @media (min-width: 1280px) {
    ${(props) => (props.withborders && 'border-right: 1px solid rgba(0, 0, 0, 0.12);')}
    padding: 10px 15px;
  }
`;

const ClickableText = styled.a`
  outline: none;
  text-decoration: none;
  padding: 2px 1px 0;
  display: inline;
  color: blue;
  :visited {
    color: blue;
  }
  :hover {
    cursor: pointer;
  }
  :active {
    color: red;
  }
`;

export default {
  BoldText,
  DetailText,
  GridItem,
  ClickableText,
};
