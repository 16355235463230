import React from 'react';

import AssignmentScheduleForm from '@/components/AssignmentScheduleEditor/AssignmentScheduleForm';

import { ModalPayloadType } from '@/types/ReduxTypes';

type Props = {
  payload: ModalPayloadType['EDIT_ASSIGNMENT_SCHEDULE'];
  onClose: () => void;
}

const EditAssignmentSchedule: React.FC<Props> = ({
  payload,
  onClose,
}: Props) => {
  const {
    assignmentSchedule,
    assessments,
    onSave,
  } = payload;

  return (
    <div style={{ padding: 32 }}>
      <AssignmentScheduleForm
        initialAssignmentSchedule={assignmentSchedule}
        assessments={assessments}
        onSave={onSave}
        onClose={onClose}
      />
    </div>
  );
};

export default EditAssignmentSchedule;
