import React, { useState } from 'react';
import { Snackbar } from '@material-ui/core';
import { GraphQLError } from 'graphql';
import { Alert } from '@material-ui/lab';
import {
  LoadingSpinner, Box, Text,
} from 'react-limbix-ui';
import { useParams } from 'react-router-dom';

import { CLINICAL_CONCERN_QUERY } from '@/apollo/queries';
import {
  REPORT_CLINICAL_CONCERN,
} from '@/apollo/mutations';
import Assessment from '@/components/Assessment';
import {
  QueryAssignmentArgs,
  MutationReportClinicalConcernArgs,
} from '@/apollo/types';
import { GraphQLErrorsType, MutationResultType, UseParamsType } from '@/types';
import { useQuery, useMutation } from '@/hooks/apollo';
import { formatDate } from '@/utils/dateUtils';
import { usePermissions } from '@/hooks/redux';

const ClinicalConcernForm: React.FC = () => {
  const { studyUid, clinicalConcernUid } = useParams<UseParamsType>();
  const [{ studyPermissions }] = usePermissions();
  const permissions = studyPermissions[studyUid];
  const canEdit = permissions?.canModifyParticipants;

  const [mutationErrors, setMutationErrors] = useState<GraphQLErrorsType>(null);
  const { loading, data, error } = useQuery<QueryAssignmentArgs>(CLINICAL_CONCERN_QUERY, {
    variables: {
      studyUid,
      clinicalConcernUid,
    },
  });
  const [reportClinicalConcernMutation] = useMutation<MutationReportClinicalConcernArgs>(
    REPORT_CLINICAL_CONCERN,
    {
      refetchQueries: [{
        query: CLINICAL_CONCERN_QUERY,
        variables: {
          studyUid,
          clinicalConcernUid,
        },
      }],
    },
  );

  if (loading) {
    return <LoadingSpinner />;
  }
  if (error) {
    return (
      <Text>{error.message}</Text>
    );
  }
  const { clinicalConcern } = data;

  const handleSubmit = async (assessmentAnswers: string[], changeReason?: string):Promise<MutationResultType> => {
    const result = await reportClinicalConcernMutation({
      variables: {
        studyUid,
        clinicalConcernUid,
        assessmentAnswers,
        changeReason,
      },
    });
    if (result.errors && result.errors.length > 0) {
      setMutationErrors(result.errors);
    }
    return result;
  };

  const { assessment, studyParticipant, user } = clinicalConcern;

  return (
    <Box padding="32px">
      <Box display="flex" flexDirection="column" marginBottom="32px">
        <Text as="h2" paddingBottom="16px">
          {`Clinical Concern for Participant ${studyParticipant.participantId ?? user.uid}`}
        </Text>
        <Text as="h3" paddingBottom="8px">
          {`Created at: ${formatDate(clinicalConcern.createdAt)}`}
        </Text>
        <Text as="h3">
          {`Updated at: ${formatDate(clinicalConcern.updatedAt)}`}
        </Text>
      </Box>
      <Assessment
        onSubmit={handleSubmit}
        assessment={assessment}
        assessmentResult={clinicalConcern}
        canEditAfterCompletion={canEdit}
        editButtonText="Edit"
        canSkipQuestions
      />
      <Snackbar
        open={!!mutationErrors}
        onClose={() => { setMutationErrors(null); }}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="error">
          {mutationErrors && mutationErrors.map((e:GraphQLError) => e.message)}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ClinicalConcernForm;
