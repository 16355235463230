import {
  ApolloClient,
  ApolloLink,
  NormalizedCacheObject,
  from,
} from '@apollo/client';

import { httpLink } from './httpLink';
import { withTokenLink } from './withTokenLink';
import { networkErrorLink, terminatingErrorLink, retryErrorLink } from './onErrorLink';
import { cache } from './cache';

const link: ApolloLink = from([
  retryErrorLink,
  networkErrorLink,
  terminatingErrorLink,
  withTokenLink,
  httpLink,
]);

// Doc explaining apollo errorPolicy: https://apollographql.com/docs/react/data/error-handling/#graphql-error-policies
export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link,
  cache,
  defaultOptions: {
    mutate: {
      errorPolicy: 'all',
    },
    query: {
      errorPolicy: 'all',
    },
  },
});
