import React from 'react';

import { FormControlLabel, Switch } from '@material-ui/core';

import { HTMLInputChangeEvent } from '@/types';

type BooleanFieldSwitchProps = {
  value: boolean;
  label: string;
  onFlipSwitch: (event: HTMLInputChangeEvent, checked: boolean) => void;
};

const BooleanFieldSwitch: React.FC<BooleanFieldSwitchProps> = ({
  value,
  label,
  onFlipSwitch,
}) => (
  <FormControlLabel
    value="start"
    control={(
      <Switch
        color="primary"
        checked={value}
        value={value}
        onChange={onFlipSwitch}
      />
    )}
    label={label}
    labelPlacement="end"
  />
);

export default BooleanFieldSwitch;
