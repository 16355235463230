import React from 'react';

import Styled from '../Modal.styles';

import ExclamationSVG from '@/assets/ExclamationCircle.svg';
import { ModalPayloadType } from '@/types/ReduxTypes';

type Props = {
  payload: ModalPayloadType['ERROR_POPUP'];
  onClose: () => void;
};

const ErrorPopup: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;

  const { header, errors } = payload;

  return (
    <div style={{ width: '350px', height: '225px' }}>
      <Styled.DialogContent>
        <div style={{ display: 'flex' }}>
          <Styled.WarningSVGContainer>
            <Styled.WarningSVG src={ExclamationSVG} />
          </Styled.WarningSVGContainer>
          <Styled.TextContainer>
            <Styled.HeaderText>
              { header || 'Error' }
            </Styled.HeaderText>
            {
              errors.map((error) => (<Styled.BodyText key={error}>{error}</Styled.BodyText>))
            }
          </Styled.TextContainer>
        </div>
        <Styled.ButtonContainer>
          <Styled.YesButton onClick={onClose}>
            <Styled.YesButtonText>
              Ok
            </Styled.YesButtonText>
          </Styled.YesButton>
        </Styled.ButtonContainer>
      </Styled.DialogContent>
    </div>
  );
};

export default ErrorPopup;
