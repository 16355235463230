import { useEffect } from 'react';
import { gql, ApolloError } from '@apollo/client';

import useQuery from '../useQuery';
import useLazyQuery from '../useLazyQuery';

import {
  QueryLimbixStudyArgs,
  QueryParticipantClinicalConcernsArgs,
  QueryStudyClinicalConcernsArgs,
  AssessmentType,
  ClinicalConcernType,
} from '@/apollo/types';
import { STUDY_CLINICAL_CONCERNS_QUERY, PARTICIPANT_CLINICAL_CONCERNS_QUERY } from '@/apollo/queries';
import usePagination, {
  ColumnToOrderByMap,
  getDefaultPaginationState,
  PaginationState,
  UpdatePaginationStateType,
} from '@/hooks/usePagination';

export const STUDY_CLINICAL_CONCERN_ASSESSMENT = gql`
  query limbixStudy($studyUid: String!) {
    limbixStudy(studyUid: $studyUid) {
      clinicalConcernAssessment {
        questions {
          text
          ordinal
          questionType
          isSensitive
          answers {
            uid
            text
          }
        }
      }
    }
  }
`;

type UseClinicalConcerns = {
  loading: boolean,
  error: ApolloError,
  clinicalConcernAssessment: AssessmentType,
  clinicalConcerns: ClinicalConcernType[],
  clinicianReportAssessment: AssessmentType,
  refetch: () => Promise<void>;
  paginationState: PaginationState;
  updatePaginationState: UpdatePaginationStateType;
  totalCount: number;
}

const DEFAULT_PAGINATION_STATE = getDefaultPaginationState(
  { columnId: 'createdAt', direction: 'desc' },
);

const columnToOrderByMap: ColumnToOrderByMap = {
  createdAt: 'created_at',
};

const useClinicalConcerns = (
  studyUid: string,
  userUid?: string,
): UseClinicalConcerns => {
  const [
    paginationState,
    paginationQueryVariables,
    updatePaginationState,
  ] = usePagination(
    DEFAULT_PAGINATION_STATE,
    columnToOrderByMap,
  );

  const {
    data: studyData,
    loading: assessmentLoading,
  } = useQuery<QueryLimbixStudyArgs>(STUDY_CLINICAL_CONCERN_ASSESSMENT, { variables: { studyUid } });

  const clinicalConcernQueryVariables = {
    ...paginationQueryVariables,
    studyUid,
  };
  const participantClinicalConcernQueryVariables = {
    ...clinicalConcernQueryVariables,
    userUid,
  };

  const [getStudyClinicalConcerns, studyResult] = useLazyQuery<QueryStudyClinicalConcernsArgs>(
    STUDY_CLINICAL_CONCERNS_QUERY,
    {
      variables: clinicalConcernQueryVariables,
      fetchPolicy: 'no-cache',
    },
  );

  const [getParticipantClinicalConcerns, participantResult] = useLazyQuery<QueryParticipantClinicalConcernsArgs>(
    PARTICIPANT_CLINICAL_CONCERNS_QUERY,
    {
      variables: participantClinicalConcernQueryVariables,
      fetchPolicy: 'no-cache',
    },
  );

  useEffect(() => {
    if (userUid) {
      getParticipantClinicalConcerns({ variables: participantClinicalConcernQueryVariables });
    } else {
      getStudyClinicalConcerns({ variables: clinicalConcernQueryVariables });
    }
  }, []);

  const loading = assessmentLoading || studyResult?.loading || participantResult?.loading;
  const error = (
    userUid
      ? participantResult?.error
      : studyResult?.error
  );

  const clinicalConcernAssessment = studyData?.limbixStudy?.clinicalConcernAssessment;
  const clinicalConcerns = (
    userUid
      ? participantResult?.data?.participantClinicalConcerns
      : studyResult?.data?.studyClinicalConcerns
  ) || [];
  const clinicianReportAssessment = (
    userUid
      ? participantResult?.data?.limbixStudy?.clinicianReportAssessment
      : studyResult?.data?.limbixStudy?.clinicianReportAssessment
  );
  const refetch = async () => {
    if (userUid) {
      await getParticipantClinicalConcerns({ variables: participantClinicalConcernQueryVariables });
    } else {
      await getStudyClinicalConcerns({ variables: clinicalConcernQueryVariables });
    }
  };

  const totalCount = clinicalConcerns && clinicalConcerns.length > 0 ? clinicalConcerns[0].total : 0;

  return {
    loading,
    error,
    clinicalConcernAssessment,
    clinicalConcerns,
    clinicianReportAssessment,
    refetch,
    paginationState,
    updatePaginationState,
    totalCount,
  };
};

export default useClinicalConcerns;
