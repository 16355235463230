import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { LoadingSpinner, Box } from 'react-limbix-ui';

import { QueryPractitionersArgs } from '@/apollo/types';
import { PRACTITIONERS_LIST } from '@/apollo/queries';
import { UseParamsType } from '@/types';
import { useQuery } from '@/hooks/apollo';
import { isPermissionDeniedError } from '@/utils/errorUtils';

const ProviderInfo: React.FC = () => {
  const { pdtUid, practitionerUid } = useParams<UseParamsType>();

  const { loading, data, error } = useQuery<QueryPractitionersArgs>(PRACTITIONERS_LIST, {
    variables: {
      pdtUid,
    },
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  if (isPermissionDeniedError(error)) {
    return <Redirect to="/operations" />;
  }

  if (data?.practitioners && data?.practitioners?.length > 0) {
    const practitioner = data.practitioners.find((_practitioner) => _practitioner.uid === practitionerUid);
    if (practitioner) {
      return (
        <Box width="100%">
          {practitioner.uid}
        </Box>
      );
    }
  }
  return (<></>);
};

export default ProviderInfo;
