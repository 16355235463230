import React, { useCallback, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { Button } from '@material-ui/core';
import {
  StaticDatePicker,
  PaginatedTable,
  LoadingSpinner,
  Column,
  Row,
  Text,
  Box,
  calculateNewSortState,
  createCell,
  createColumn,
} from 'react-limbix-ui';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { QueryStudyFreeFormTextsArgs } from '@/apollo/types';
import { STUDY_FREE_FORM_TEXTS } from '@/apollo/queries';
import {
  UseParamsType,
} from '@/types';
import { formatDate } from '@/utils/dateUtils';
import { renderPID } from '@/utils/tableUtils';
import usePagination, { ColumnToOrderByMap, getDefaultPaginationState } from '@/hooks/usePagination';
import { useModal, usePermissions } from '@/hooks/redux';
import { useLazyQuery } from '@/hooks/apollo';
import { isPermissionDeniedError } from '@/utils/errorUtils';

const DEFAULT_PAGINATION_STATE = getDefaultPaginationState(
  { columnId: 'dateCreated', direction: 'asc' },
);

const columnToOrderByMap: ColumnToOrderByMap = {
  dateCreated: 'created_at',
  prompt: 'prompt',
  text: 'value',
};

const FreeFormTextReview: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { studyUid } = useParams<UseParamsType>();
  const { showModal } = useModal();
  const [{ studyPermissions }] = usePermissions();

  const [paginationState, paginationQueryVariables, updatePaginationState] = usePagination(
    DEFAULT_PAGINATION_STATE,
    columnToOrderByMap,
  );
  const variables = {
    studyUid,
    date: selectedDate,
    ...paginationQueryVariables,
  };

  const [fftQuery, { loading, data, error }] = useLazyQuery<QueryStudyFreeFormTextsArgs>(STUDY_FREE_FORM_TEXTS, {
    fetchPolicy: 'no-cache',
    variables,
  });

  const handleChangeDate = useCallback((date: Date) => {
    setSelectedDate(date);
    // reset table state
    updatePaginationState(DEFAULT_PAGINATION_STATE);
  }, []);

  useDeepCompareEffect(
    () => {
      fftQuery({ variables });
    },
    [paginationState, selectedDate],
  );

  const permissions = studyPermissions[studyUid];
  if (permissions.isBlinded) {
    return (
      <Text as="h1">
        You are blinded for this study. You cannot review free form text.
      </Text>
    );
  }

  if (isPermissionDeniedError(error)) {
    return <Redirect to="/research" />;
  }

  const renderFreeFormTextTable = () => {
    if (loading || !data) {
      return <LoadingSpinner />;
    }
    if (data.studyFreeFormTexts?.length === 0) {
      return (
        <Text as="h1">
          {'It seems there aren\'t any free form texts for this date'}
          <br />
          If that seems like a mistake please reach out to Research and Eng
        </Text>
      );
    }

    const { studyFreeFormTexts } = data;
    const totalCount = studyFreeFormTexts.length > 0 ? studyFreeFormTexts[0].total : 0;

    const renderClinicalConcerns = (userUid: string, participantId: string) => (
      <Button
        variant="contained"
        onClick={() => showModal(
          'CLINICAL_CONCERNS',
          {
            userUid,
            participantId,
            studyUid,
            navigate: true,
          },
        )}
      >
        Clinical Concern
      </Button>
    );

    const columns: Column[] = [
      createColumn('pid', 'PID', 'string', null, false),
      createColumn('dateCreated', 'Date Created', 'date', null, true),
      createColumn('prompt', 'Prompt', 'string', null, true),
      createColumn('text', 'Text', 'string', null, true),
    ];

    if (permissions.canModifyParticipants) {
      columns.push(createColumn('clinicalConcerns', 'Clinical Concerns', 'string', null, false));
    }

    const rows: Row[] = studyFreeFormTexts.map((fft, index) => {
      const row: Row = {
        id: `${index}`,
        cells: [
          createCell('pid', fft.participantId, renderPID(studyUid, fft.user.uid, fft.participantId)),
          createCell('dateCreated', new Date(fft.createdAt), formatDate(fft.createdAt)),
          createCell('prompt', fft.prompt),
          createCell('text', fft.value),
        ],
      };
      if (permissions.canModifyParticipants) {
        row.cells.push(
          createCell(
            'clinicalConcerns',
            'Clinical Concerns',
            renderClinicalConcerns(fft.user.uid, fft.participantId),
          ),
        );
      }
      return row;
    });

    return (
      <Box width="100%">
        <PaginatedTable
          ariaLabel="freeform_text_review"
          columns={columns}
          rows={rows}
          sortState={paginationState.sortState}
          onSortByColumn={(colId) => updatePaginationState({
            sortState: calculateNewSortState(colId, paginationState.sortState),
          })}
          currentPage={paginationState.currentPage}
          onGoToPage={(pageNum) => updatePaginationState({ currentPage: pageNum })}
          itemsPerPage={paginationState.itemsPerPage}
          totalCount={totalCount}
          skipRowSort
        />
      </Box>
    );
  };

  return (
    <Box width="100%" display="flex">
      <Box zIndex="0">
        <StaticDatePicker selectedDate={selectedDate} onChange={handleChangeDate} />
      </Box>
      <Box
        style={{
          width: 'calc(100% - 350px)',
          display: 'inline',
          zIndex: 0,
          marginLeft: '40px',
        }}
      >
        {renderFreeFormTextTable()}
      </Box>
    </Box>
  );
};

export default FreeFormTextReview;
