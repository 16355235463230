import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components/macro';
import { Box, Text } from 'react-limbix-ui';

import { MutationResetPasswordArgs } from '@/apollo/types';
import history from '@/utils/history';
import { RESET_PASSWORD } from '@/apollo/mutations';
import { useMutation } from '@/hooks/apollo';

const StyledForm = styled.form`
  width: 100%;
  margin-top: 50px;
`;

const SubmitButton = styled(Button)`
  margin-top: 20px;
  background-color: #556cd6;
`;

const ResetPassword: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>();
  const [sendPasswordResetMutation] = useMutation<MutationResetPasswordArgs>(
    RESET_PASSWORD,
  );
  const sendPasswordReset = async () => {
    const result = await sendPasswordResetMutation({
      variables: {
        email,
      },
    });
    if (result?.errors && result?.errors?.length > 0) {
      setError(result.errors[0]?.message);
    } else {
      history.push('/login');
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !!email) {
      sendPasswordReset();
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box marginTop="10px" display="flex" flexDirection="column" alignItems="center" textAlign="center">
        <StyledForm noValidate>
          <Text as="h1" marginBottom>
            Reset Password
          </Text>
          <Text>
            After you submit this form with your email address,
            please follow the link that is sent to you to finish resetting your password
          </Text>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            value={email}
            autoFocus
            onKeyDown={handleKeyDown}
            onChange={(event) => setEmail(event.target.value?.trim())}
          />
          <a href="/login">return to login</a>
          <Text color="red">
            {error}
          </Text>
          <SubmitButton
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            onClick={sendPasswordReset}
            disabled={!email}
          >
            Reset
          </SubmitButton>
        </StyledForm>
      </Box>
    </Container>
  );
};

export default ResetPassword;
