import React, { useRef, useEffect } from 'react';

type Props = {
  svgData: string;
};

const QRCode: React.FC<Props> = (props: Props) => {
  const { svgData } = props;
  const svgWrapperRef = useRef<HTMLDivElement>();

  useEffect(() => {
    svgWrapperRef.current.innerHTML = svgData;
  }, [svgData]);

  return (
    <div ref={svgWrapperRef} />
  );
};

export default QRCode;
