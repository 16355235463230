import { Link } from 'react-limbix-ui';
import React from 'react';

import { formatCapitalSnakeCase } from './stringUtils';

import {
  PractitionerIdentifierSystemName,
  PractitionerIdentifierType,
} from '@/apollo/types';

export const hasSearchString = (stringList: string[], searchString: string): boolean => {
  if (searchString.startsWith('-')) {
    if (searchString.length <= 1) {
      return true;
    }
    const negationString = searchString.slice(1);
    const regExNegated = new RegExp(negationString.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'));
    return stringList.reduce((acc: boolean, curr) => (
      acc && !curr.startsWith(negationString) && !regExNegated.test(curr)
    ), true);
  }
  const regEx = new RegExp(searchString.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'));
  return stringList.reduce((acc: boolean, curr) => (
    acc || curr.startsWith(searchString) || regEx.test(curr)
  ), false);
};

export const renderPID = (studyUid: string, userUid: string, participantId: string) => {
  if (!userUid) {
    return 'None';
  }
  const linkToPID = `/research/study/${studyUid}/participant/${userUid}`;
  return <Link.RouterLink to={linkToPID} fontSize="16px">{participantId || 'None'}</Link.RouterLink>;
};

export const renderIdentifierLabel = (identifier: PractitionerIdentifierType) => {
  const prefix = identifier?.systemName === PractitionerIdentifierSystemName.Npi
    ? 'NPI'
    : `${formatCapitalSnakeCase(identifier?.state)}`;
  return (
    <span key={identifier?.value}>
      <strong>
        {prefix}
      </strong>
      { ` - ${identifier?.value}`}
      <br />
    </span>
  );
};
