import { ApolloQueryResult, gql, OperationVariables } from '@apollo/client';

import useQuery from '../useQuery';

import {
  QueryStudyAssignmentSchedulesArgs,
  AssignmentScheduleType,
  AssessmentType,
  Query,
} from '@/apollo/types';

const STUDY_ASSIGNMENT_SCHEDULES = gql`
  query StudyAssignmentSchedules($studyUid: String!) {
    studyAssignmentSchedules(studyUid: $studyUid) {
      uid
      isLocked
      accountType
      assessment {
        uid
      }
      count
      ordinal
      repeatFrequencyDays
      startTimeOffsetDays
      visibleDurationDays
      study {
        name
      }
      typeForAssignment
      typeOfClientForAssignment
      typeOfNotificationForAssignment
      sendPushNotifications
      sendEmailNotifications
      sendSmsNotifications
      scheduleOnStudyState
      canSkipQuestions
      canEditAfterCompletion
    }
  }
`;

const ASSESSMENTS = gql`
  query Assessments($showArchived: Boolean) {
    assessments(showArchived: $showArchived) {
      uid
      name
      nameInternal
    }
  }
`;

type UseAssignmentScheduleEditorQueriesType = {
  loading: boolean,
  assignmentSchedules: Array<AssignmentScheduleType>,
  assessments: Array<AssessmentType>,
  refetchAssignmentSchedules: (variables?: Partial<OperationVariables | QueryStudyAssignmentSchedulesArgs>) =>
    Promise<ApolloQueryResult<Query>>,
}
const useAssignmentScheduleEditorQueries = (
  studyUid: string,
): UseAssignmentScheduleEditorQueriesType => {
  const {
    data: { assessments } = {},
    loading: assessmentsLoading,
  } = useQuery<QueryStudyAssignmentSchedulesArgs>(ASSESSMENTS, {
    variables: {
      showArchived: true,
    },
    fetchPolicy: 'no-cache',
  });

  const {
    data: { studyAssignmentSchedules } = {},
    loading: assignmentSchedulesLoading,
    refetch: refetchAssignmentSchedules,
  } = useQuery<QueryStudyAssignmentSchedulesArgs>(
    STUDY_ASSIGNMENT_SCHEDULES,
    {
      variables: {
        studyUid,
      },
      fetchPolicy: 'no-cache',
    },
  );

  return {
    loading: assignmentSchedulesLoading || assessmentsLoading,
    assignmentSchedules: studyAssignmentSchedules,
    assessments,
    refetchAssignmentSchedules,
  };
};

export default useAssignmentScheduleEditorQueries;
