import React from 'react';
import {
  Route,
  Switch,
  Redirect,
  RouteComponentProps,
} from 'react-router-dom';

import { Selection, Settings } from '@/pages/Common';
import {
  Pdt,
  Patient,
  Provider,
  Assessment,
} from '@/pages/Operations';
import
{
  StudyPages,
  ParticipantPages,
  AssignmentPages,
  GenericPages,
} from '@/pages/Research';
import { BreadCrumbs } from '@/components';
import { ResopsUserType } from '@/types/ReduxTypes';

const STUDY_BASE_URL = '/research/study/:studyUid';
const PARTICIPANT_BASE_URL = `${STUDY_BASE_URL}/participant/:userUid`;
const ASSIGNMENT_BASE_URL = `${PARTICIPANT_BASE_URL}/assignments/:assignmentUid`;
const RESEARCH_ASSIGNMENT_BASE_URL = `${PARTICIPANT_BASE_URL}/research_assignments`;

const PDT_BASE_URL = '/operations/pdt/:pdtUid';

type Props = {
  permissions: ResopsUserType
}
const Routes: React.FC<Props> = (props: Props) => {
  const { permissions } = props;
  if (!permissions.isLoggedIn) {
    return <Redirect to="/login" />;
  }
  const commonRoutes = [
    {
      path: '/',
      component: Selection,
      name: '',
    },
    {
      path: '/settings',
      component: Settings,
      name: 'Settings',
    },
  ];

  const operationsRoutes = [
    {
      path: '/operations',
      component: Pdt.PdtList,
      name: 'PDT List',
    },
    {
      path: `${PDT_BASE_URL}/settings`,
      component: Pdt.Settings,
      name: 'PDT Settings',
    },
    {
      path: `${PDT_BASE_URL}/patient`,
      component: Patient.PatientList,
      name: 'Patient List',
    },
    {
      path: `${PDT_BASE_URL}/provider`,
      component: Provider.ProviderList,
      name: 'Provider List',
    },
    {
      path: `${PDT_BASE_URL}/patient/:patientUid`,
      component: Patient.PatientInfo,
      name: 'Patient Info',
    },
    {
      path: `${PDT_BASE_URL}/provider/:practitionerUid`,
      component: Provider.ProviderInfo,
      name: 'Provider Info',
    },
    {
      path: '/operations/assessment',
      component: Assessment.AssessmentList,
      name: 'Assessment List',
    },
    {
      path: '/operations/assessment/create',
      component: Assessment.CreateAssessment,
      name: 'Create Assessment',
    },
    {
      path: '/operations/assessment/:assessmentUid/edit',
      component: Assessment.EditAssessment,
      name: 'Edit Assessment',
    },
    {
      path: '/operations/assessment/:assessmentUid/test',
      component: Assessment.TestAssessment,
      name: 'Test Assessment',
    },
  ];

  const researchRoutes = [
    {
      path: '/research',
      component: StudyPages.StudyList,
      name: 'Study List',
    },
    {
      path: STUDY_BASE_URL,
      component: StudyPages.ManageParticipants,
      name: 'Manage Participants',
    },
    {
      path: `${STUDY_BASE_URL}/clinical-concerns-log`,
      component: GenericPages.ClinicalConcernsLog,
      name: 'Clinical Concerns Log',
    },
    {
      path: `${STUDY_BASE_URL}/deterioration-alerts`,
      component: StudyPages.DeteriorationAlerts,
      name: 'Deterioration Alerts',
    },
    {
      path: `${STUDY_BASE_URL}/enrollment-tracker`,
      component: StudyPages.EnrollmentTracker,
      name: 'Enrollment Tracker',
    },
    {
      path: `${STUDY_BASE_URL}/free-form-text-review`,
      component: StudyPages.FreeFormTextReview,
      name: 'Free-Form Text Review',
    },
    {
      path: `${STUDY_BASE_URL}/assignments`,
      component: GenericPages.AssignmentList,
      name: 'Assignments List',
    },
    {
      path: `${STUDY_BASE_URL}/settings`,
      component: StudyPages.StudySettings,
      name: 'Study Settings',
    },
    {
      path: `${STUDY_BASE_URL}/assignment-schedules`,
      component: StudyPages.AssignmentSchedules,
      name: 'Assignment Schedules',
    },
    {
      path: `${STUDY_BASE_URL}/assignment-schedules/edit`,
      component: StudyPages.AssignmentSchedules,
      name: 'Assignment Schedules',
    },
    {
      path: `${PARTICIPANT_BASE_URL}`,
      component: ParticipantPages.Details,
      name: 'Participant Details',
    },
    {
      path: `${PARTICIPANT_BASE_URL}/enrollment-tracker-form`,
      component: StudyPages.EnrollmentTrackerForm,
      name: 'Enrollment Tracker Form',
    },
    {
      path: `${PARTICIPANT_BASE_URL}/summary`,
      component: ParticipantPages.Summary,
      name: 'Participant Summary',
    },
    {
      path: `${PARTICIPANT_BASE_URL}/assignments`,
      component: GenericPages.AssignmentList,
      name: 'Participant Assignments',
    },
    {
      path: `${RESEARCH_ASSIGNMENT_BASE_URL}`,
      component: ParticipantPages.ResearchAssignments,
      name: 'Research Assignments about Participant',
    },
    {
      path: `${PARTICIPANT_BASE_URL}/free-form-text-review`,
      component: ParticipantPages.FreeFormTexts,
      name: 'Participant Free-Form Text Review',
    },
    {
      path: `${PARTICIPANT_BASE_URL}/clinical-concerns-log`,
      component: GenericPages.ClinicalConcernsLog,
      name: 'Participant Clinical Concerns Log',
    },
    {
      path: `${PARTICIPANT_BASE_URL}/clinical-concerns-log/:clinicalConcernUid`,
      component: GenericPages.ClinicalConcernForm,
      name: 'Clinical Concern Form',
    },
    {
      path: `${ASSIGNMENT_BASE_URL}`,
      component: AssignmentPages.Details,
      name: 'Assignment Details',
    },
    {
      path: `${RESEARCH_ASSIGNMENT_BASE_URL}/:assignmentUid`,
      component: ParticipantPages.ResearchAssignmentDetails,
      name: 'Research Assignment Details',
    },
  ];

  type ComponentType = React.FC<Partial<RouteComponentProps>>;

  type BreadCrumbType = {
    path: string,
    component: ComponentType,
    name: string,
  }

  const renderComponent = (Component: ComponentType, routeProps: RouteComponentProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Component {...routeProps} />
  );

  const makeBreadCrumbs = (pageList: BreadCrumbType[]) => (
    ([...commonRoutes, ...pageList] as BreadCrumbType[]).map((route) => (
      <Route
        exact
        path={route.path}
        key={route.name}
        render={(routeProps) => {
          const breadcrumbs = pageList
            // Get all routes that contain the current one.
            .filter(({ path }) => routeProps.match.path.includes(path))
            // Swap out any dynamic routes with their param values.
            .map(({ path, name }) => ({
              path: Object.keys(routeProps.match.params).length
                ? Object.keys(routeProps.match.params).reduce(
                  (_path, param) => _path.replace(
                    `:${param}`, routeProps.match.params[param],
                  ), path,
                )
                : path,
              name,
            }));
          return (
            <>
              <BreadCrumbs breadcrumbs={breadcrumbs} />
              {renderComponent(route.component, routeProps)}
            </>
          );
        }}
      />
    )));

  return (
    <>
      {permissions.isOperations && !permissions.isResearch && (
        <Switch>
          {makeBreadCrumbs(operationsRoutes)}
          <Redirect to="/operations" />
        </Switch>
      )}
      {permissions.isResearch && !permissions.isOperations && (
        <Switch>
          {makeBreadCrumbs(researchRoutes)}
          <Redirect to="/research" />
        </Switch>
      )}
      {permissions.isResearch && permissions.isOperations && (
        <Switch>
          {makeBreadCrumbs([...researchRoutes, ...operationsRoutes])}
          <Redirect to="/" />
        </Switch>
      )}
    </>
  );
};

export default Routes;
