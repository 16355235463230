import { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';

import useActions from './useActions';

import { RootState } from '@/store';
import { NavigationType } from '@/types';

type PreventNavigationSetterProps = {
  isBlocked: boolean,
  message?: string,
  listeners?: Array<keyof WindowEventMap>,
}
type usePreventNavigationReturnType = [NavigationType, {
  setPreventNavigation: (props: PreventNavigationSetterProps) => void,
  resetListenerEvent: () => void,
}];

const usePreventNavigation = (): usePreventNavigationReturnType => {
  const history = useHistory();
  const {
    navigation: {
      blockNavigationOnEvent,
      eventOccurred,
      blockNavigation,
      unblockNavigation,
    },
  } = useActions();
  const navigation = useSelector((state: RootState) => (state.navigation));

  // On unmount, unblock navigation
  useEffect(() => (
    () => {
      unblockNavigation();
    }
  ), []);

  // Want same reference to the function for each event handler on each render
  const eventHandler = useCallback(() => {
    if (!navigation.isBlocked) {
      eventOccurred({ value: true });
    }
  }, [navigation.isBlocked]);
  // Add event listeners to window for each requested window event, remove them on unmount
  useDeepCompareEffect(() => {
    navigation.listeners?.forEach((listener) => {
      window.addEventListener(listener, eventHandler);
    });
    return () => {
      navigation.listeners?.forEach((listener) => {
        window.removeEventListener(listener, eventHandler);
      });
    };
  }, [navigation]);

  useDeepCompareEffect(() => (
    navigation.isBlocked && history.block(
      navigation?.message || 'You may have unsaved changes, are you sure you want to leave?',
    )
  ), [navigation]);

  const setPreventNavigation = useCallback((props: PreventNavigationSetterProps) => {
    const { isBlocked, listeners, message } = props;
    if (listeners?.length) {
      blockNavigationOnEvent({ message, listeners });
    } else if (isBlocked && !listeners?.length) {
      blockNavigation({ message });
    } else {
      unblockNavigation();
    }
  }, []);

  const resetListenerEvent = useCallback(() => {
    if (navigation.isBlocked) {
      eventOccurred({ value: false });
    }
  }, [navigation]);

  return [navigation, { setPreventNavigation, resetListenerEvent }];
};

export default usePreventNavigation;
