import React from 'react';

import { TextField } from '@material-ui/core';

import { HTMLInputChangeEvent } from '@/types';

type NumericFieldInputProps = {
  value: number,
  label: string,
  onChange: (event: HTMLInputChangeEvent) => void,
};

const NumericFieldInput: React.FC<NumericFieldInputProps> = ({
  value,
  label,
  onChange,
}) => (
  <TextField
    variant="outlined"
    id={label}
    label={label}
    fullWidth
    value={value}
    size="small"
    type="number"
    onChange={(event) => onChange(event)}
    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
  />
);

export default NumericFieldInput;
