import React, { useState } from 'react';
import { TextField } from '@material-ui/core';

import Styled from '../Modal.styles';

import {
  MutationRemoveStudyCoordinatorArgs,
  MutationRemoveStudyCoInvestigatorArgs,
} from '@/apollo/types';
import { STUDY_QUERY, STUDY_LIST_QUERY } from '@/apollo/queries';
import {
  REMOVE_STUDY_COORDINATOR,
  REMOVE_STUDY_CO_INVESTIGATOR,
} from '@/apollo/mutations';
import { GraphQLErrorsType, MutationResultType } from '@/types';
import ExclamationSVG from '@/assets/ExclamationCircle.svg';
import { ModalPayloadType } from '@/types/ReduxTypes';
import { useMutation } from '@/hooks/apollo';

type Props = {
  payload: ModalPayloadType['REMOVE_STUDY_PERSONNEL'];
  onClose: () => void;
};
const RemoveStudyPersonnel: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;
  const [textFieldValue, setTextFieldValue] = useState('');
  const [errors, setErrors] = useState<GraphQLErrorsType>([]);

  const { limbixStudy, user, type } = payload;

  const refetchQueries = [
    {
      query: STUDY_QUERY,
      variables: {
        studyUid: limbixStudy.uid,
      },
    },
    {
      query: STUDY_LIST_QUERY,
    },
  ];

  const [removeStudyCoordinatorMutation] = useMutation<MutationRemoveStudyCoordinatorArgs>(
    REMOVE_STUDY_COORDINATOR, { refetchQueries },
  );
  const removeStudyCoordinator = (userUid: string): Promise<MutationResultType> => (
    removeStudyCoordinatorMutation({
      variables: {
        studyUid: limbixStudy.uid,
        userUid,
      },
    })
  );

  const [removeStudyCoInvestigatorMutation] = useMutation<MutationRemoveStudyCoInvestigatorArgs>(
    REMOVE_STUDY_CO_INVESTIGATOR, { refetchQueries },
  );
  const removeStudyCoInvestigator = (userUid: string): Promise<MutationResultType> => (
    removeStudyCoInvestigatorMutation({
      variables: {
        studyUid: limbixStudy.uid,
        userUid,
      },
    })
  );

  const removeAndClose = async () => {
    let mutation: (userUid: string) => Promise<MutationResultType>;
    if (type === 'Study Coordinator') {
      mutation = removeStudyCoordinator;
    }
    if (type === 'Study CoInvestigator') {
      mutation = removeStudyCoInvestigator;
    }
    const { errors: resultErrors } = await mutation(user.uid);
    if (resultErrors) {
      setErrors(resultErrors);
    } else {
      onClose();
    }
  };

  const checkDisabled = () => (
    user.email !== textFieldValue
  );

  return (
    <div style={{ width: '416px', height: '425px' }}>
      <Styled.DialogContent>
        <div style={{ display: 'flex' }}>
          <Styled.WarningSVGContainer>
            <Styled.WarningSVG src={ExclamationSVG} />
          </Styled.WarningSVGContainer>
          <Styled.TextContainer>
            <Styled.HeaderText>
              Remove Study Personnel
            </Styled.HeaderText>
            <Styled.BodyText>
              Are you sure that you want to remove this limbix staff member with email:
              <br />
              <strong>
                {` ${user.email}`}
              </strong>
              <br />
              From this Role:
              <strong>
                {` ${type}`}
              </strong>
              <br />
              From this study:
              <strong>
                {` ${limbixStudy.name}`}
              </strong>
              <br />
              <br />
              Enter their email into the text input to confirm
            </Styled.BodyText>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="User Email"
              label="User Email"
              id="User Email"
              onChange={(event) => setTextFieldValue(event.target.value)}
            />
            {errors.length > 0 && (
              <div>
                User could not be removed, contact Eng or your manager
              </div>
            )}
          </Styled.TextContainer>
        </div>
        <Styled.ButtonContainer>
          <Styled.NoButton onClick={onClose}>
            <Styled.NoButtonText>
              No
            </Styled.NoButtonText>
          </Styled.NoButton>
          <Styled.YesButton onClick={removeAndClose} disabled={checkDisabled()}>
            <Styled.YesButtonText>
              Yes - Remove
            </Styled.YesButtonText>
          </Styled.YesButton>
        </Styled.ButtonContainer>
      </Styled.DialogContent>
    </div>
  );
};

export default RemoveStudyPersonnel;
