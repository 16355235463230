import React, { useState } from 'react';

import { Box } from '@material-ui/core';

import Styled from '../Modal.styles';

import { GraphQLErrorsType } from '@/types';
import ExclamationSVG from '@/assets/ExclamationCircle.svg';
import { ModalPayloadType } from '@/types/ReduxTypes';

type Props = {
  payload: ModalPayloadType['CONFIRMATION_POPUP'];
  onClose: () => void;
};

const ConfirmationPopup: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;

  const {
    header, mutation, context, skipMissingContextWarning, body,
  } = payload;

  const [errors, setErrors] = useState<GraphQLErrorsType>([]);

  const triggerMutation = async () => {
    const { errors: resultErrors } = await mutation();
    if (resultErrors) {
      setErrors(resultErrors);
    } else {
      onClose();
    }
  };

  const generateContext = () => {
    if (!context) {
      return skipMissingContextWarning ? null : (
        <>No user context found, please manually verify you have the correct user selected</>
      );
    }
    return (
      <>
        <h3 style={{ margin: '0px', textDecoration: 'underline' }}>
          User Context:
        </h3>
        <ul style={{ paddingLeft: '15px' }}>
          {Object.entries(context).map(([key, value]) => (
            <li key={key}>
              <Styled.BodyText>
                {key}
                :
              </Styled.BodyText>
              <ul style={{ paddingLeft: '15px' }}>
                <Styled.BodyText>
                  <li>
                    {`${value}`}
                  </li>
                </Styled.BodyText>
              </ul>
            </li>
          ))}
        </ul>
      </>
    );
  };

  return (
    <Box width="400px">
      <Styled.DialogContent>
        <Box display="flex" marginBottom="16px">
          <Box display="flex">
            <Styled.WarningSVGContainer>
              <Styled.WarningSVG src={ExclamationSVG} />
            </Styled.WarningSVGContainer>
            <Styled.TextContainer>
              <Styled.HeaderText>
                { header || 'Confirmation' }
              </Styled.HeaderText>
              <Styled.BodyText>
                {body}
              </Styled.BodyText>
              <Box paddingTop="20px">
                {generateContext()}
              </Box>
              {errors.map((error) => (
                <div key={error.message} style={{ color: 'red' }}>
                  {error.message}
                </div>
              ))}
            </Styled.TextContainer>
          </Box>
        </Box>
        <Styled.FlexButtonContainer>
          <Styled.NoButton onClick={onClose}>
            <Styled.NoButtonText>
              Close
            </Styled.NoButtonText>
          </Styled.NoButton>
          <Styled.YesButton onClick={triggerMutation}>
            <Styled.YesButtonText>
              Confirm
            </Styled.YesButtonText>
          </Styled.YesButton>
        </Styled.FlexButtonContainer>
      </Styled.DialogContent>
    </Box>
  );
};

export default ConfirmationPopup;
