import moment from 'moment';

export const formatDate = (date: Date): string => {
  if (date && moment(date, true).isValid()) {
    return moment(date).format('MMM D YYYY, h:mm A');
  }
  return 'None';
};

export const stringToMoment = (date: string): moment.Moment => (
  moment(date, 'MMM D YYYY, h:mm A', true)
);

export const isDate = (value: unknown): boolean => (
  moment(value, moment.ISO_8601, true).isValid()
);

export const toDate = (value: unknown): Date => (
  moment(value, moment.ISO_8601, true).toDate()
);
