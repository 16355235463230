import React from 'react';
import { Container, Button } from '@material-ui/core';
import { Text } from 'react-limbix-ui';

import { QRCode } from '@/components';
import { TOTP_AUTHENTICATOR_QUERY } from '@/apollo/queries';
import { CREATE_TOTP_AUTH_MUTATION } from '@/apollo/mutations';
import { useQuery, useMutation } from '@/hooks/apollo';
import { usePreventNavigation } from '@/hooks/redux';

const Settings: React.FC = () => {
  const [, { setPreventNavigation }] = usePreventNavigation();
  const { data, loading } = useQuery(TOTP_AUTHENTICATOR_QUERY);
  const [createTotpAuth] = useMutation(
    CREATE_TOTP_AUTH_MUTATION,
    {
      refetchQueries: [
        { query: TOTP_AUTHENTICATOR_QUERY },
      ],
    },
  );

  const renderMFASettings = () => {
    if (data.totpAuthenticator) {
      return <QRCode svgData={data.totpAuthenticator.qrcodeSvg} />;
    }
    const handleClick = () => {
      createTotpAuth();
      setPreventNavigation({ isBlocked: true });
    };
    return (
      <Button variant="contained" color="primary" onClick={handleClick}>
        Activate MFA
      </Button>
    );
  };
  return (
    <Container component="main" maxWidth="xs">
      <Text as="h1" margin="10px 0px">
        Settings
      </Text>
      {!loading && (
        <>
          <Text as="h3">
            MFA Settings
          </Text>
          <Text>
            {'Before Activating Multi-Factor Authentication (MFA), please be sure to download \
            Google Authenticator from the Google Play Store or the Apple Store. Once you activate, \
            MFA, scan the QR Code in the Google Authenticator app. If you activate MFA and fail to scan \
            the QR Code, you won\'t be able to login again and will need to reach out to Eng to fix your account.'}
          </Text>
          {renderMFASettings()}
        </>
      )}
    </Container>
  );
};

export default Settings;
