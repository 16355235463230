import { useCallback } from 'react';

import useActions from './useActions';

import { ModalContentType, ModalPayloadType, UseModalReturnType } from '@/types/ReduxTypes';

const useModal = (): UseModalReturnType => {
  const {
    modal: {
      showModal: showModalAction,
      hideModal: hideModalAction,
    },
  } = useActions();

  const showModal = useCallback((modalType: ModalContentType, payload: ModalPayloadType[ModalContentType]) => {
    showModalAction({
      modalType,
      payload,
    });
  }, []);

  const hideModal = useCallback(() => {
    hideModalAction();
  }, []);

  return { showModal, hideModal };
};

export default useModal;
