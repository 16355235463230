import {
  EditedDataGridRow as Row,
  DataGridColumn as Column,
} from 'react-limbix-ui';

import {
  AssignmentScheduleDeletionResult,
  ClinicalConcernDeletionResult,
  IdleTimerPayloadType,
  MutationResultType,
  StudyPermissions, UnknownAsyncCallback,
} from './DataTypes';
import {
  AssignmentFiltersActionsType,
} from '../store/slices/assignmentFilters';

import {
  CareTeamType,
  LimbixStudyType,
  OrganizationType,
  PractitionerType,
  StudyParticipantType,
  UserType,
  QueryStudyAssignmentsArgs,
  LanguageType,
  AssessmentType,
  AssignmentScheduleInputType,
} from '@/apollo/types';

export enum GlobalActionTypes {
  ClearStore = 'CLEAR_STORE',
}

export type GlobalAction = {
  type: GlobalActionTypes;
}

export type ResopsUserType = {
  isLoggedIn: boolean;
  isResearch: boolean;
  isOperations: boolean;
  studyPermissions: StudyPermissions;
};

export type AssignmentFiltersStateType = QueryStudyAssignmentsArgs;

export type UseAssignmentFiltersReturnType = {
  assignmentFilters: AssignmentFiltersStateType,
  assignmentFiltersActions: AssignmentFiltersActionsType,
}

export type ModalPayloadType = {
  NONE: null;
  IDLE_WARNING: IdleTimerPayloadType;
  TRANSITION_TO_ADULT: { studyParticipant: StudyParticipantType, onRefetch?: UnknownAsyncCallback };
  DELETE_PARTICIPANT: { studyParticipant: StudyParticipantType, onRefetch?: UnknownAsyncCallback };
  STUDY_ACTIONS: {
    type: string,
    studyParticipant: StudyParticipantType,
    onRefetch?: UnknownAsyncCallback;
  };
  CONTACT_INFO: { studyParticipant: StudyParticipantType };
  REVOKE_ACCESS: { studyParticipant: StudyParticipantType, onRefetch?: UnknownAsyncCallback };
  REGISTER_NEW_USER: { studyUid: string, onRefetch?: UnknownAsyncCallback };
  REGISTER_EXISTING_USER: { studyUid: string, onRefetch?: UnknownAsyncCallback };
  CHANGE_STUDY_NAME: { limbixStudy: LimbixStudyType };
  ADD_STUDY_PERSONNEL: {
    limbixStudy: LimbixStudyType;
    type: string;
  };
  REMOVE_STUDY_PERSONNEL: {
    limbixStudy: LimbixStudyType;
    user: UserType,
    type: string;
  };
  CLINICAL_CONCERNS: {
    studyUid: string,
    userUid?: string,
    participantId?: string,
    navigate: boolean,
    onRefetch?: UnknownAsyncCallback,
  };
  DELETE_CLINICAL_CONCERN: {
    clinicalConcernUid: string,
    studyUid: string,
    onDelete: (
      clinicalConcernUid: string,
      clinicalConcernStudyUid: string,
      changeReason: string
    ) => ClinicalConcernDeletionResult,
  };
  PARTICIPANT_NOTES: {
    studyParticipant: StudyParticipantType,
    canModifyParticipants: boolean,
    onRefetch?: UnknownAsyncCallback,
  };
  EDIT_ASSIGNMENT_SCHEDULE: {
    assignmentSchedule: AssignmentScheduleInputType,
    assessments: AssessmentType[],
    onSave: (assignmentSchedule: AssignmentScheduleInputType, changeReason: string) => Promise<void>,
  };
  DELETE_ASSIGNMENT_SCHEDULE: {
    assignmentScheduleUid: string,
    onDelete: (assignmentScheduleUid: string, changeReason: string) => AssignmentScheduleDeletionResult,
  };
  EDIT_PARENTS: { studyParticipant: StudyParticipantType, onRefetch?: UnknownAsyncCallback };
  ERROR_POPUP: {
    header?: string;
    errors: Array<string>;
  };
  CONFIRMATION_POPUP: {
    header: string;
    mutation: () => Promise<MutationResultType>;
    context?: Record<string, string>;
    body?: string;
    skipMissingContextWarning?: boolean;
  };
  CONFIRMATION_POPUP_CHANGE_REASON: {
    header: string;
    body?: string;
    mutation?: (changeReason: string) => Promise<MutationResultType>;
    mutations?: Array<(changeReason: string) => Promise<MutationResultType>>,
  };
  ALL_CONTACT_INFO: { studyParticipant: StudyParticipantType };
  UPDATE_PID: { studyParticipant: StudyParticipantType };
  ACCESS_CODES: {
    practitioner: PractitionerType;
    pdtUid: string;
    onRefetch?: UnknownAsyncCallback;
  };
  PATIENT_NOTES: { careteam: CareTeamType, onRefetch?: UnknownAsyncCallback };
  PRACTITIONER_IDS: { practitioner: PractitionerType };
  PRACTITIONER_NOTES: {
    practitioner: PractitionerType;
    pdtUid: string;
    onRefetch?: UnknownAsyncCallback;
  };
  VIEW_PATIENTS: {
    practitioner: PractitionerType;
    pdtUid: string;
  };
  PORTAL_ACCESS: {
    practitioner: PractitionerType;
    onRefetch?: UnknownAsyncCallback;
  };
  VIEW_PROVIDERS: { careteam: CareTeamType };
  PATIENT_CONTACT_INFO: { careteam: CareTeamType, onRefetch?: UnknownAsyncCallback };
  REVOKE_PATIENT_ACCESS: { careteam: CareTeamType, onRefetch?: UnknownAsyncCallback };
  ORGANIZATION_INFO: { organization: OrganizationType };
  DATA_GRID_CONFIRMATION: {
    columns: Column[],
    editedRows: Row[],
    mutations: Array<(changeReason: string) => Promise<MutationResultType>>,
    onFinishMutations: () => void,
  };
  GENERIC_WARNING: {
    primaryButtonText: string;
    secondaryButtonText: string;
    onClickPrimaryButton?: () => void;
    onClickSecondaryButton?: () => void;
    headerText: string;
    body: React.ReactNode;
  };
  GENERIC_MODAL: {
    primaryButtonText?: string;
    secondaryButtonText?: string;
    onClickPrimaryButton?: () => void;
    onClickSecondaryButton?: () => void;
    headerText: string,
    body: React.ReactNode,
  };
  UPDATE_PARTICIPANT_LANGUAGE: {
    participantId?: string,
    participantEmail: string,
    currentLanguage: LanguageType,
    candidateLanguages: LanguageType[],
    onSubmit: (language: LanguageType) => Promise<void>;
  };
};

export type ModalContentType = keyof ModalPayloadType;

export type UseModalReturnType = {
  showModal: (modalType: ModalContentType, payload: ModalPayloadType[ModalContentType]) => void,
  hideModal: () => void,
}

/* Navigation Action/Reducer Types */
export type NavigationType = {
  isBlocked: boolean;
  listeners: Array<keyof WindowEventMap>,
  message?: string;
};
