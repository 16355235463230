import React from 'react';

import { Link } from 'react-limbix-ui';

import { LimbixStudyType } from '@/apollo/types';

type Props = {
  study: LimbixStudyType;
}

const StudyActionLinks: React.FC<Props> = ({ study }) => {
  const baseStudyUrl = `/research/study/${study?.uid}`;

  return (
    <>
      {study?.enrollmentTrackerAssessment && (
        <>
          <Link.RouterLink underlined to={`${baseStudyUrl}/enrollment-tracker`}>
            Enrollment Tracker
          </Link.RouterLink>
          {' | '}
        </>
      )}
      {!study?.isBlinded && study?.clinicalConcernAssessment && (
        <>
          <Link.RouterLink underlined to={`${baseStudyUrl}/clinical-concerns-log`}>
            Clinical Concerns Log
          </Link.RouterLink>
          {' | '}
        </>
      )}
      {!study?.isBlinded && (
        <>
          <Link.RouterLink underlined to={`${baseStudyUrl}/free-form-text-review`}>
            Free-Form Text Review
          </Link.RouterLink>
          {' | '}
        </>
      )}
      <Link.RouterLink underlined to={`${baseStudyUrl}/deterioration-alerts`}>
        Deterioration Alerts
      </Link.RouterLink>
      {' | '}
      <Link.RouterLink underlined to={`${baseStudyUrl}/assignments`}>
        Assignment List
      </Link.RouterLink>
      {' | '}
      <Link.RouterLink underlined to={`${baseStudyUrl}/assignment-schedules`}>
        Assignment Schedules
      </Link.RouterLink>
    </>
  );
};

export default StudyActionLinks;
