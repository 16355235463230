import {
  AssessmentType, QuestionType, TextTranslationType,
} from '@/apollo/types';

export type ConditionType = {
  questionText?: string,
  question: number,
  answer: string | number,
  uid?: string,
  isNegated?: boolean,
  error?: string,
};

export type ConditionField = keyof Omit<ConditionType, 'uid'>;

export type TextTranslation = Omit<TextTranslationType, '__typename' | 'uid'>;
export type LanguageKey = keyof TextTranslation;

export type AssessmentAnswerType = {
  uid?: string,
  text?: string,
  textTranslations?: TextTranslation,
  assessmentValue?: number,
  error?: string,
};

export type AssessmentQuestionType = {
  uid?: string,
  answers?: Array<AssessmentAnswerType>,
  conditions?: Array<ConditionType>,
  error?: string,
  textTranslations?: TextTranslation,
} & Required<Omit<QuestionType,
  '__typename' | 'assessment' | 'ordinal' | 'uid' | 'answers' | 'conditions' | 'textTranslations' | 'total'>>;

export type StateType = {
  questions: AssessmentQuestionType[],
  uid?: string,
  error?: string,
  hasErrors?: boolean,
  contextTextTranslations?: TextTranslation;
  introTextTranslations?: TextTranslation;
  nameTranslations?: TextTranslation;
  preQuestionTextTranslations?: TextTranslation;
} & Omit<AssessmentType, 'uid' | '__typename' | 'questions' | 'contextTextTranslations' | 'introTextTranslations' |
'nameTranslations' | 'preQuestionTextTranslations' >;

export type AssessmentField = keyof Omit<StateType, 'questions' | 'error' | 'hasErrors'| 'translations'>;

export type QuestionField = keyof Omit<AssessmentQuestionType, 'answers' | 'conditions'>;

export type AssessmentTranslationField = 'contextTextTranslations' | 'introTextTranslations' | 'nameTranslations' |
  'preQuestionTextTranslations';

export enum ActionTypeEnum {
  EDIT_ASSESSMENT_FIELD,
  ADD_QUESTION,
  MOVE_QUESTION,
  REMOVE_QUESTION,
  EDIT_QUESTION,
  ADD_ANSWER,
  REMOVE_ANSWER,
  EDIT_ANSWER_TEXT,
  EDIT_ANSWER_ASSESSMENT_VALUE,
  ADD_CONDITION,
  EDIT_CONDITION,
  REMOVE_CONDITION,
  SAVE,
  UPLOAD_ASSESSMENT,
  EDIT_ASSESSMENT_TEXT_TRANSLATION,
  EDIT_ANSWER_TEXT_TRANSLATION,
  EDIT_QUESTION_TEXT_TRANSLATION
}

export type ActionType = {
  type: ActionTypeEnum.EDIT_ASSESSMENT_FIELD,
  field: AssessmentField
  value: AssessmentType[AssessmentField],
} | {
  type: ActionTypeEnum.ADD_QUESTION,
} | {
  type: ActionTypeEnum.MOVE_QUESTION,
  questionIndex: number,
  direction: 'up' | 'down',
} | {
  type: ActionTypeEnum.REMOVE_QUESTION,
  questionIndex: number,
} | {
  type: ActionTypeEnum.EDIT_QUESTION,
  questionIndex: number,
  field: QuestionField,
  value: AssessmentQuestionType[QuestionField],
} | {
  type: ActionTypeEnum.ADD_ANSWER,
  questionIndex: number,
} | {
  type: ActionTypeEnum.REMOVE_ANSWER,
  questionIndex: number,
} | {
  type: ActionTypeEnum.EDIT_ANSWER_TEXT,
  questionIndex: number,
  answerIndex: number,
  value: string,
} | {
  type: ActionTypeEnum.EDIT_ANSWER_ASSESSMENT_VALUE,
  questionIndex: number,
  answerIndex: number,
  value: number,
} | {
  type: ActionTypeEnum.ADD_CONDITION,
  questionIndex: number,
} | {
  type: ActionTypeEnum.EDIT_CONDITION,
  questionIndex: number,
  conditionIndex: number,
  field: ConditionField,
  value: ConditionType[ConditionField],
} | {
  type: ActionTypeEnum.REMOVE_CONDITION,
  questionIndex: number,
} | {
  type: ActionTypeEnum.SAVE,
  onSave: () => void,
} | {
  type: ActionTypeEnum.UPLOAD_ASSESSMENT,
  uploadedAssessment: StateType,
} | {
  type: ActionTypeEnum.EDIT_ASSESSMENT_TEXT_TRANSLATION
  fieldName: AssessmentTranslationField,
  language: LanguageKey,
  text: string,
} | {
  type: ActionTypeEnum.EDIT_ANSWER_TEXT_TRANSLATION,
  questionIndex: number,
  answerIndex: number,
  language: LanguageKey,
  text: string,
} | {
  type: ActionTypeEnum.EDIT_QUESTION_TEXT_TRANSLATION,
  questionIndex: number,
  language: LanguageKey,
  text: string,
};
