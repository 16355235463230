import {
  IconButton as MuiIconButton,
  DialogTitle as MuiDialogTitle,
} from '@material-ui/core';
import styled from 'styled-components/macro';

const IconButton = styled(MuiIconButton)`
  right: 2%;
  top: 3%;
  position: absolute;
`;

const DialogTitle = styled(MuiDialogTitle)`
  display: flex;
  margin: 0;
`;

export default {
  IconButton,
  DialogTitle,
};
