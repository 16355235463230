import React, { useCallback } from 'react';
import {
  PaginatedTable,
  Link,
  Column,
  Row,
  Box,
  createCell,
  createColumn, calculateNewSortState,
} from 'react-limbix-ui';
import { Button } from '@material-ui/core';

import AdminActions from './AdminActions';

import {
  PractitionerType,
  OrganizationType,
} from '@/apollo/types';

import { SearchBar } from '@/components';
import { formatPractitionerName } from '@/utils/stringUtils';
import { formatDate } from '@/utils/dateUtils';
import { useModal } from '@/hooks/redux';
import { PaginationState, UpdatePaginationStateType } from '@/hooks/usePagination';
import { UnknownAsyncCallback } from '@/types/DataTypes';
import { renderIdentifierLabel } from '@/utils/tableUtils';

type Props = {
  pdtUid: string;
  practitioners?: PractitionerType[];
  searchString?: string;
  onUpdateSearchString?: (searchString: string) => void;
  paginationState: PaginationState;
  updatePaginationState: UpdatePaginationStateType;
  totalCount: number;
  onRefetch?: UnknownAsyncCallback;
};

const ProviderTable: React.FC<Props> = (props: Props) => {
  const {
    practitioners,
    searchString,
    pdtUid,
    paginationState: { sortState, currentPage, itemsPerPage },
    updatePaginationState,
    totalCount,
    onRefetch,
    onUpdateSearchString,
  } = props;
  const { showModal } = useModal();

  const onSearchFilterSubmit = useCallback(() => updatePaginationState({
    searchFilterState: {
      user__email: searchString,
      user__uid: searchString,
      organization__name: searchString,
    },
  }), [searchString]);

  const renderName = (practitioner: PractitionerType) => {
    const basePDTUrl = `/operations/pdt/${pdtUid}`;
    const linkToUID = `${basePDTUrl}/provider/${practitioner.uid}`;
    return <Link.RouterLink to={linkToUID} fontSize="16px">{formatPractitionerName(practitioner)}</Link.RouterLink>;
  };

  const renderAccessCodes = (practitioner: PractitionerType) => (
    <Box>
      <Box>
        {practitioner?.accessCodes?.find((accessCode) => !accessCode.expirationTime)?.code || ''}
      </Box>
      <Link.ButtonLink
        onClick={
          () => showModal(
            'ACCESS_CODES',
            {
              practitioner,
              pdtUid,
              onRefetch,
            },
          )
        }
      >
        view codes
      </Link.ButtonLink>
    </Box>
  );

  const renderPractitionerIds = (practitioner: PractitionerType) => (
    <Box>
      {practitioner.identifiers?.length > 0 && (
        <>
          <Box>
            {renderIdentifierLabel(practitioner?.identifiers[0])}
          </Box>
          {practitioner.identifiers?.length > 1 && (
            <Link.ButtonLink
              onClick={
                () => showModal(
                  'PRACTITIONER_IDS',
                  { practitioner },
                )
              }
            >
              view all identifiers
            </Link.ButtonLink>
          )}
        </>
      )}
    </Box>
  );

  const renderOrganization = (organization: OrganizationType) => (
    <Link.ButtonLink
      onClick={
        () => showModal(
          'ORGANIZATION_INFO',
          {
            organization,
          },
        )
      }
    >
      {organization.name}
    </Link.ButtonLink>
  );

  const renderAdminActions = (practitioner: PractitionerType) => (
    <AdminActions practitioner={practitioner} pdtUid={pdtUid} />
  );

  const renderNotes = (practitioner: PractitionerType) => (
    <Link.ButtonLink
      onClick={
        () => showModal(
          'PRACTITIONER_NOTES',
          {
            practitioner,
            pdtUid,
            onRefetch,
          },
        )
      }
    >
      view
    </Link.ButtonLink>
  );

  const renderPortalAccess = (practitioner: PractitionerType) => (
    <Link.ButtonLink
      onClick={
        () => showModal(
          'PORTAL_ACCESS',
          {
            practitioner,
            onRefetch,
          },
        )
      }
    >
      {formatDate(practitioner.portalAccessTime)}
    </Link.ButtonLink>
  );

  const columns: Column[] = [
    createColumn('name', 'Name', 'string', null, true),
    createColumn('phone', 'Phone Number', 'string', null, true),
    createColumn('email', 'Email', 'string', null, true),
    createColumn('practitionerIds', 'Practitioner Identifier', 'string', null, false),
    createColumn('createdAt', 'Created At', 'date', null, true),
    createColumn('accessCode', 'Access Code', 'string', null, false),
    createColumn('role', 'Role', 'string', '5%', true),
    createColumn('position', 'Position', 'string', null, false),
    createColumn('organization', 'Provider Organization', 'string', null, false),
    createColumn('admin', 'Admin Actions', 'string', null, false),
    createColumn('notes', 'Notes', 'string', '5%', false),
    createColumn('providerPortalAccess', 'Provider Portal Access', 'string', null, true),
  ];

  const rows: Row[] = practitioners
    .map((practitioner) => {
      const { organization, user } = practitioner;
      return {
        id: practitioner.uid,
        cells: [
          createCell('name', formatPractitionerName(practitioner), renderName(practitioner)),
          createCell('phone', user.phone),
          createCell('email', user.email),
          createCell('practitionerIds', 'Practitioner Identifier', renderPractitionerIds(practitioner)),
          createCell(
            'createdAt',
            practitioner.user.createdAt,
            formatDate(practitioner.user.createdAt),
          ),
          createCell('accessCode', 'Access Code', renderAccessCodes(practitioner)),
          createCell('role', practitioner.practitionerRole),
          createCell('position', practitioner.position),
          createCell('organization', 'ProviderOrganization', renderOrganization(organization)),
          createCell('admin', 'Admin', renderAdminActions(practitioner)),
          createCell('notes', 'Notes', renderNotes(practitioner)),
          createCell('providerPortalAccess', 'Provider Portal Access', renderPortalAccess(practitioner)),
        ],
      } as Row;
    });

  const handleSortByColumn = useCallback((colId: string) => (
    updatePaginationState({ sortState: calculateNewSortState(colId, sortState) })
  ), [sortState.columnId, sortState.direction]);

  const onGoToPage = useCallback((pageNum: number) => (
    updatePaginationState({ currentPage: pageNum })
  ), []);

  return (
    <Box>
      <Box marginBottom="20px" float="right" display="flex" flexDirection="row">
        <SearchBar
          placeholder="Filter Providers"
          value={searchString}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => (
            onUpdateSearchString(event.currentTarget.value?.toLowerCase() || '')
          )}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={onSearchFilterSubmit}
        >
          Filter
        </Button>
      </Box>
      <PaginatedTable
        ariaLabel="provider_table"
        columns={columns}
        rows={rows}
        sortState={sortState}
        onSortByColumn={handleSortByColumn}
        onGoToPage={onGoToPage}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        totalCount={totalCount}
        skipRowSort
      />
    </Box>
  );
};

export default ProviderTable;
