import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import moment from 'moment';
import {
  LoadingSpinner,
  Text,
  Box,
  Column,
  Row,
  createColumn,
  createCell, calculateNewSortState,
} from 'react-limbix-ui';
import useDeepCompareEffect from 'use-deep-compare-effect';

import {
  QueryLimbixStudyArgs,
  StudyParticipantType,
  QueryStudyParticipantsArgs,
} from '@/apollo/types';
import { formatDate } from '@/utils/dateUtils';
import { STUDY_ENROLLMENT_TRACKER_QUERY, STUDY_ENROLLMENT_TRACKER_ASSESSMENT } from '@/apollo/queries';
import { UseParamsType } from '@/types';
import { usePermissions } from '@/hooks/redux';
import { useQuery, useLazyQuery } from '@/hooks/apollo';
import { isPermissionDeniedError } from '@/utils/errorUtils';
import { REDACTED_FOR_BLINDING_MESSAGE } from '@/utils/constants';
import { PaginatedAssessmentTable } from '@/components/PaginatedAssessmentTable';
import usePagination, { ColumnToOrderByMap, getDefaultPaginationState } from '@/hooks/usePagination';

const DEFAULT_PAGINATION_STATE = getDefaultPaginationState(
  { columnId: 'createdAt', direction: 'desc' },
  20,
);

const columnToOrderByMap: ColumnToOrderByMap = {
  pid: 'participant_id',
  createdAt: 'created_at',
  studyArm: 'study_arm__name',
  referralSource: 'referral_source',
  firstName: 'user__first_name',
  dateOfBirth: 'user__date_of_birth',
  email: 'user__email',
  phone: 'user__phone',
};

const EnrollmentTracker: React.FC = () => {
  const [{ studyPermissions }] = usePermissions();
  const { studyUid } = useParams<UseParamsType>();

  const [
    paginationState,
    paginationQueryVariables,
    updatePaginationState,
  ] = usePagination(
    DEFAULT_PAGINATION_STATE,
    columnToOrderByMap,
  );
  const { sortState } = paginationState;

  const enrollmentTrackerAssessmentQuery = useQuery<QueryLimbixStudyArgs>(STUDY_ENROLLMENT_TRACKER_ASSESSMENT, {
    variables: {
      studyUid,
    },
  });

  const variables = {
    ...paginationQueryVariables,
    studyUid,
  };
  const [getEnrollmentTracker, {
    data,
    error,
    loading: loadingLazyQuery,
  }] = useLazyQuery<QueryStudyParticipantsArgs>(STUDY_ENROLLMENT_TRACKER_QUERY, {
    variables,
    fetchPolicy: 'no-cache',
  });

  useDeepCompareEffect(() => {
    getEnrollmentTracker({ variables });
  }, [paginationState]);

  const handleSortByColumn = (colId: string) => updatePaginationState({
    sortState: calculateNewSortState(colId, sortState),
  });
  const handleGoToPage = (pageNum: number) => updatePaginationState({
    currentPage: pageNum,
  });

  const permissions = studyPermissions[studyUid];

  if (enrollmentTrackerAssessmentQuery.loading) {
    return <LoadingSpinner />;
  }

  if (isPermissionDeniedError(error)) {
    return <Redirect to="/research" />;
  }

  const enrollmentTrackerAssessment = enrollmentTrackerAssessmentQuery.data?.limbixStudy?.enrollmentTrackerAssessment;
  if (!enrollmentTrackerAssessment) {
    return (
      <Text as="h1">
        No Enrollment Tracker seems to be attached to this study, please add one and try again
      </Text>
    );
  }

  const participants = (data?.studyParticipants || []).filter((participant) => !!participant.enrollmentTrackerResult);
  const totalCount = participants.length > 0 ? participants[0].total : 0;

  const enrollmentTrackerResults = participants
    .filter((participant) => participant.enrollmentTrackerResult)
    .map((participant) => participant.enrollmentTrackerResult) || [];

  const staticColumnData = [
    {
      field: 'pid',
      headerName: 'PID',
      sortable: true,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      sortable: true,
    },
    {
      field: 'studyArm',
      headerName: 'Study Arm',
      sortable: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
    },
    {
      field: 'referralSource',
      headerName: 'Referral Source',
      sortable: true,
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      sortable: true,
    },
    {
      field: 'dateOfBirth',
      headerName: 'DOB',
      sortable: true,
    },
    {
      field: 'ageAtEnrollment',
      headerName: 'Age at Enrollment',
      sortable: false,
    },
    {
      field: 'turnsEighteenWhileEnrolled',
      headerName: 'Turns 18 While Enrolled',
      sortable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      sortable: true,
    },
    {
      field: 'phone',
      headerName: 'Phone',
      sortable: true,
    },
    {
      field: 'caregiverFirstName',
      headerName: 'Caregiver First Name',
      sortable: false,
    },
    {
      field: 'caregiverEmail',
      headerName: 'Caregiver Email',
      sortable: false,
    },
    {
      field: 'caregiverPhone',
      headerName: 'Caregiver Phone',
      sortable: false,
    },
    {
      field: 'interventionCompletionDate',
      headerName: 'Intervention Completion Date',
      sortable: false,
    },
  ];
  const staticColumns: Column[] = staticColumnData.map((columnData) => createColumn(
    columnData.field,
    columnData.headerName,
    'string',
    null,
    columnData.sortable,
  ));

  const getInterventionCompletionDate = (activationTime: Date): string => {
    const activationMoment = moment(activationTime);
    if (!activationMoment.isValid()) {
      return 'N/A - user not yet activated';
    }
    const interventionDays = data?.limbixStudy?.appAccessDurationDays;
    if (!interventionDays) {
      return 'N/A - user has unlimited access';
    }
    const interventionCompletionMoment = activationMoment.add(interventionDays, 'days');
    return formatDate(interventionCompletionMoment.toDate());
  };

  const getTurnsEighteenWhileEnrolled = (participant: StudyParticipantType): string => {
    const turnsEighteenWhileEnrolled = participant?.turnsEighteenWhileEnrolled?.toString();

    if (!turnsEighteenWhileEnrolled) {
      const activationMoment = moment(participant?.activationTime);
      if (!activationMoment.isValid()) {
        return 'N/A - user not yet activated';
      }
      return 'N/A - could not be calculated';
    }
    return turnsEighteenWhileEnrolled;
  };

  const staticRows: Row[] = participants.filter((participant) => !!participant.enrollmentTrackerResult)
    .map((participant): Row => {
      const pid = participant?.participantId;
      const createdAt = formatDate(participant.enrollmentTrackerResult.createdAt);
      const studyArm = permissions.isBlinded ? REDACTED_FOR_BLINDING_MESSAGE : participant?.studyArm?.name;
      const status = participant?.status;
      const referralSource = participant?.referralSource;
      const firstName = participant?.user?.firstName;
      const dateOfBirth = formatDate(participant?.user?.dateOfBirth);
      const ageAtEnrollment = `${participant?.user?.ageAtEnrollment}`;
      const turnsEighteenWhileEnrolled = getTurnsEighteenWhileEnrolled(participant);
      const email = participant?.user?.email;
      const phone = participant?.user?.phone;
      const firstCaregiver = participant?.user?.parents[0];
      const caregiverFirstName = firstCaregiver ? `${firstCaregiver?.firstName}` : '';
      const caregiverEmail = firstCaregiver?.email;
      const caregiverPhone = firstCaregiver?.phone;
      const interventionCompletionDate = getInterventionCompletionDate(participant?.activationTime);
      return {
        id: participant.user.uid,
        cells: [
          createCell('pid', pid),
          createCell('createdAt', createdAt),
          createCell('studyArm', studyArm),
          createCell('status', status),
          createCell('referralSource', referralSource),
          createCell('firstName', firstName),
          createCell('dateOfBirth', dateOfBirth),
          createCell('ageAtEnrollment', ageAtEnrollment),
          createCell('turnsEighteenWhileEnrolled', turnsEighteenWhileEnrolled),
          createCell('email', email),
          createCell('phone', phone),
          createCell('caregiverFirstName', caregiverFirstName),
          createCell('caregiverEmail', caregiverEmail),
          createCell('caregiverPhone', caregiverPhone),
          createCell('interventionCompletionDate', interventionCompletionDate),
        ],
      };
    });

  const handleEditRow = (rowId: string) => {
    const clickedParticipant = participants.find((participant) => participant.user.uid === rowId);
    const formURL = `/research/study/${studyUid}/participant/${clickedParticipant.user.uid}`
      + '/enrollment-tracker-form';
    window.open(formURL, '_blank');
  };
  const handleClickRow = permissions.canModifyParticipants
    ? handleEditRow
    : null;

  return (
    <Box display="inline" height="100%">
      {loadingLazyQuery && (
        <LoadingSpinner />
      )}
      <PaginatedAssessmentTable
        ariaLabel="enrollment-tracker"
        onGoToPage={handleGoToPage}
        totalCount={totalCount}
        onSortByColumn={handleSortByColumn}
        assessment={enrollmentTrackerAssessment}
        assessmentResults={enrollmentTrackerResults}
        staticColumns={staticColumns}
        staticRows={staticRows}
        onClickRow={handleClickRow}
        {...paginationState}
        skipRowSort
      />
    </Box>
  );
};

export default EnrollmentTracker;
