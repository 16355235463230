import {
  FetchResult,
  OperationVariables,
  QueryResult as ApolloQueryResult,
  QueryHookOptions as ApolloQueryHookOptions,
  QueryTuple as ApolloQueryTuple,
  MutationFunction as ApolloMutationFunction,
  MutationHookOptions as ApolloMutationHookOptions,
  MutationTuple as ApolloMutationTuple,
} from '@apollo/client';
import { GraphQLError } from 'graphql';

import {
  AssignmentScheduleInputType,
  ClinicalConcernType,
  Mutation,
  Query,
} from '@/apollo/types';
import { GENDER_MAP } from '@/utils/constants';

export type UseParamsType = Record<string, string | undefined>;

export type MutationResultType = FetchResult<Mutation, Record<string, unknown>, Record<string, unknown>>;

export type GraphQLErrorsType = readonly GraphQLError[];

export type QueryResult<T extends OperationVariables = OperationVariables> = ApolloQueryResult<Query, T>;

export type QueryHookOptions<T extends OperationVariables = OperationVariables> = ApolloQueryHookOptions<Query, T>;

export type QueryTuple<T extends OperationVariables = OperationVariables> = ApolloQueryTuple<Query, T>;

export type MutationHookOptions<
  T extends OperationVariables = OperationVariables
> = ApolloMutationHookOptions<Mutation, T>;

export type MutationFunction<T extends OperationVariables = OperationVariables> = ApolloMutationFunction<Mutation, T>;

export type MutationTuple<T extends OperationVariables = OperationVariables> = ApolloMutationTuple<Mutation, T>;

export type KeyValuePairType = string
  | number
  | boolean
  | Date
  | JSX.Element
  | string[]
  | number[]
  | boolean[];

export type AssignmentScheduleDeletionResult = Promise<FetchResult<
  Mutation,
  Record<string, AssignmentScheduleInputType>,
  Record<string, AssignmentScheduleInputType>
>>

export type ClinicalConcernDeletionResult = Promise<FetchResult<
  Mutation,
  Record<string, ClinicalConcernType>,
  Record<string, ClinicalConcernType>
>>

export type StudyPermission = {
  uid: string;
  canViewStudy: boolean;
  canChangeStudy: boolean;
  canModifyParticipants: boolean;
  canDeleteParticipants: boolean;
  isBlinded: boolean;
}

export type StudyPermissions = Record<string, StudyPermission>;

export type IdleTimerPayloadType = {
  countdownDuration: number;
  stayActive: () => void;
  pause: () => void;
  isLeader: () => boolean;
  onLogout: () => Promise<void> | void;
};

export type ParticipantMetadataObject = {
 [key: string]: Date | boolean | string,
};

export enum AccountType {
  None = '',
  Adult = 'ADULT',
  Child = 'CHILD',
  Parent = 'PARENT',
  Research = 'RESEARCH'
}

export enum AssignmentStatus {
  None = '',
  Complete = 'complete',
  Past_Due = 'past due',
  Started = 'started',
  Visible = 'visible',
  Not_Visible = 'not visible',
}

export type PaginationType = {
  limit?: number,
  offset?: number,
  orderBy?: string[],
}

export type DeteriorationAlertsType = {
  [key: string]: KeyValuePairType;
  createdAt: Date;
  userUid: string;
  participantId: string;
  baseline: number;
  recentScore: number;
  scoreDifference: number;
}

export type AssignmentListType = {
  [key: string]: KeyValuePairType;
  assessmentName: string;
  assignmentUid: string;
  pid: string;
  userUid: string;
  participantStatus: string;
  visibleDate: Date;
  dueDate: Date;
  weekInProgram: string;
  clientType: string;
  assignmentType: string;
  aboutParticipantPid: string;
  aboutParticipantUserUid: string;
}

export type ParticipantAssignmentsType = {
  [key: string]: KeyValuePairType;
  assessmentName: string;
  assignmentUid: string;
  userUid: string;
  status: string;
  visibleDate: Date;
  dueDate: Date;
  clientType: string;
  assignmentType: string;
}

export type ResearchAssignmentType = {
  [key: string]: KeyValuePairType;
  assessmentName: string;
  assignmentUid: string;
  assigneeUid: string;
  assigneeName: string;
  participantId: string;
  aboutUserUid: string;
  status: string;
  visibleDate: Date;
  dueDate: Date;
  clientType: string;
  assignmentType: string;
}

export type QuestionAndAnswer = {
  index: number;
  questionText: string;
  selectedAnswerValue: string;
  selectedAnswerText: string;
  expanded: boolean;
}

export type DataPoint = {
  x: Date;
  y: number;
}

export type LineData = {
  id: string;
  data: Array<DataPoint>;
}

export type UnknownChangeEvent = React.ChangeEvent<{ value: unknown }>;
export type HTMLInputChangeEvent = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

export type CheckedChangeEvent = React.ChangeEvent<{ checked: boolean }>;

export type GenderMapKeys = keyof typeof GENDER_MAP;

export enum GenderType {
  None = '',
  Male = 'M',
  Female = 'F',
  'Non-Binary' = 'X',
  Other = 'O'
}

export type UnknownAsyncCallback = () => Promise<unknown>;

export type AssignmentScheduleInputWithLockState = AssignmentScheduleInputType & { isLocked: boolean };
