import React, { useState } from 'react';
import { TextField } from '@material-ui/core';

import Styled from '../Modal.styles';

import { MutationEditParticipantDataArgs } from '@/apollo/types';
import { STUDY_PARTICIPANT_QUERY } from '@/apollo/queries';
import { UPDATE_PARTICIPANT_PID } from '@/apollo/mutations';
import { MutationResultType, GraphQLErrorsType } from '@/types';
import ExclamationSVG from '@/assets/ExclamationCircle.svg';
import { ModalPayloadType } from '@/types/ReduxTypes';
import { useMutation } from '@/hooks/apollo';

type Props = {
  payload: ModalPayloadType['UPDATE_PID'];
  onClose: () => void;
};

const UpdatePid: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;
  const [pidTextValue, setPidTextValue] = useState('');
  const [changeReasonValue, setChangeReasonValue] = useState<string>();
  const [errors, setErrors] = useState<GraphQLErrorsType>([]);

  const close = () => {
    onClose();
  };

  const {
    studyParticipant,
  } = payload;

  const refetchQueries = [
    {
      query: STUDY_PARTICIPANT_QUERY,
      variables: {
        studyUid: studyParticipant.study.uid,
        userUid: studyParticipant.user.uid,
      },
    },
  ];

  const [updatePIDMutation] = useMutation<MutationEditParticipantDataArgs>(
    UPDATE_PARTICIPANT_PID, { refetchQueries },
  );

  const updatePID = (participantId: string, changeReason: string): Promise<MutationResultType> => (
    updatePIDMutation({
      variables: {
        userUid: studyParticipant.user.uid,
        studyUid: studyParticipant.study.uid,
        participantId,
        changeReason,
      },
    })
  );

  const updatePIDAndClose = async () => {
    const result = await updatePID(pidTextValue, changeReasonValue);
    if (result.errors) {
      setErrors(result.errors);
    } else {
      onClose();
    }
  };

  return (
    <div style={{ width: '375px', height: '375px' }}>
      <Styled.DialogContent>
        <div style={{ display: 'flex' }}>
          <Styled.WarningSVGContainer>
            <Styled.WarningSVG src={ExclamationSVG} />
          </Styled.WarningSVGContainer>
          <Styled.TextContainer>
            <Styled.HeaderText>
              Update Participant PID
            </Styled.HeaderText>
            <Styled.BodyText>
              Currently, this Participant PID is:
              <strong>
                {` "${studyParticipant.participantId || 'None'}" `}
              </strong>
              in Study with ID:
              <strong>
                {` ${studyParticipant.study.uid} `}
              </strong>
              Would you like to change their PID?
            </Styled.BodyText>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="pid"
              label="Participant PID"
              id="pid"
              onChange={(event) => setPidTextValue(event.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="change reason"
              label="Change Reason"
              id="change reason"
              onChange={(event) => setChangeReasonValue(event.target.value?.trim())}
            />
          </Styled.TextContainer>
          {errors.map((error) => (
            <div key={error.message} style={{ color: 'red' }}>
              {error.message}
            </div>
          ))}
        </div>
        <Styled.ButtonContainer>
          <Styled.NoButton onClick={close}>
            <Styled.NoButtonText>
              No
            </Styled.NoButtonText>
          </Styled.NoButton>
          <Styled.YesButton onClick={updatePIDAndClose} disabled={!changeReasonValue}>
            <Styled.YesButtonText>
              Yes - Update
            </Styled.YesButtonText>
          </Styled.YesButton>
        </Styled.ButtonContainer>
      </Styled.DialogContent>
    </div>
  );
};

export default UpdatePid;
