import React, { useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { Button } from '@material-ui/core';
import {
  LoadingSpinner,
  Row,
  Box,
  Text,
  createColumn, createCell, calculateNewSortState, Link,
} from 'react-limbix-ui';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { ClinicianReport } from '@/components';
import {
  MutationDeleteClinicalConcernArgs,
  MutationGenerateClinicalConcernsCsvArgs,
} from '@/apollo/types';
import { GENERATE_CLINICAL_CONCERN_CSV, DELETE_CLINICAL_CONCERN } from '@/apollo/mutations';
import { UseParamsType } from '@/types';
import { formatDate } from '@/utils/dateUtils';
import { useModal, usePermissions } from '@/hooks/redux';
import { useMutation } from '@/hooks/apollo';
import { useClinicalConcerns } from '@/hooks/apollo/queries';
import { isPermissionDeniedError } from '@/utils/errorUtils';
import { REDACTED_FOR_BLINDING_MESSAGE } from '@/utils/constants';
import { PaginatedAssessmentTable } from '@/components/PaginatedAssessmentTable';

const ClinicalConcernsLog: React.FC = () => {
  const { studyUid, userUid } = useParams<UseParamsType>();
  const { showModal } = useModal();
  const [{ studyPermissions }] = usePermissions();

  const {
    loading,
    error,
    clinicalConcernAssessment,
    clinicianReportAssessment,
    clinicalConcerns,
    refetch,
    paginationState,
    updatePaginationState,
    totalCount,
  } = useClinicalConcerns(studyUid, userUid);
  const { sortState } = paginationState;

  const [clinicalConcernsLoading, setClinicalConcernsLoading] = useState(false);

  useDeepCompareEffect(
    () => { refetch(); },
    [paginationState],
  );

  const [generateClinicalConcernsMutatation] = useMutation<MutationGenerateClinicalConcernsCsvArgs>(
    GENERATE_CLINICAL_CONCERN_CSV,
  );

  const [deleteClinicalConcernMutation] = useMutation<MutationDeleteClinicalConcernArgs>(
    DELETE_CLINICAL_CONCERN,
  );

  const handleSortByColumn = (colId: string) => updatePaginationState({
    sortState: calculateNewSortState(colId, sortState),
  });
  const handleGoToPage = (pageNum: number) => updatePaginationState({
    currentPage: pageNum,
  });

  const onDeleteClinicalConcern = async (
    clinicalConcernUid: string,
    clinicalConcernStudyUid: string,
    changeReason: string,
  ) => {
    const result = await deleteClinicalConcernMutation({
      variables: {
        clinicalConcernUid,
        studyUid: clinicalConcernStudyUid,
        changeReason,
      },
    });
    await refetch();
    return result;
  };

  const permissions = studyPermissions[studyUid];
  if (permissions.isBlinded) {
    return (
      <Text as="h1">
        You are blinded for this study. You cannot view clinical concerns.
      </Text>
    );
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  if (isPermissionDeniedError(error)) {
    return <Redirect to="/research" />;
  }

  if (!clinicalConcernAssessment) {
    return (
      <Text as="h1">
        No Clinical Concern seems to be attacted to this study, please add one and try again
      </Text>
    );
  }

  const columns = [
    {
      field: 'pid',
      headerName: 'PID',
      sortable: false,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      sortable: true,
    },
    {
      field: 'studyArm',
      headerName: 'Study Arm',
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
    },
    {
      field: 'referralSource',
      headerName: 'Referral Source',
      sortable: false,
    },
  ];
  if (permissions?.canModifyParticipants) {
    columns.unshift({
      field: 'edit',
      headerName: '',
      sortable: false,
    }, {
      field: 'delete',
      headerName: '',
      sortable: false,
    });
  }

  if (clinicianReportAssessment) {
    columns.push(
      {
        field: 'clinicianReport',
        headerName: 'Clinician Report',
        sortable: false,
      },
    );
  }
  const staticColumns = columns.map((column) => createColumn(
    column.field,
    column.headerName,
    'string',
    null,
    column.sortable,
  ));
  const staticRows: Row[] = clinicalConcerns.map((clinicalConcern): Row => {
    const participant = clinicalConcern?.studyParticipant;
    const pid = participant?.participantId;
    const createdAt = formatDate(clinicalConcern?.createdAt);
    const studyArm = permissions.isBlinded ? REDACTED_FOR_BLINDING_MESSAGE
      : clinicalConcern?.studyParticipant?.studyArm?.name;
    const status = clinicalConcern?.studyParticipant?.status;
    const referralSource = participant?.referralSource;

    const cells = [
      createCell('pid', pid),
      createCell('createdAt', createdAt),
      createCell('studyArm', studyArm),
      createCell('status', status),
      createCell('referralSource', referralSource),
    ];
    if (clinicianReportAssessment) {
      cells.push(createCell(
        'clinicianReport',
        'n/a',
        (
          <ClinicianReport
            studyUid={studyUid}
            participantUserUid={clinicalConcern.user.uid}
            clinicalConcernUid={clinicalConcern.uid}
            clinicianReportUid={clinicalConcern?.clinicianReport?.uid}
            completeTime={clinicalConcern?.clinicianReport?.completeTime}
            onRefetch={refetch}
          />
        ),
      ));
    }
    if (permissions?.canModifyParticipants) {
      const formURL = `/research/study/${studyUid}/participant/${clinicalConcern?.studyParticipant.user.uid}`
        + `/clinical-concerns-log/${clinicalConcern?.uid}`;
      const editCell = createCell(
        'edit',
        'n/a',
        <Link.RouterLink to={formURL} target="_blank" fontSize="16px">EDIT</Link.RouterLink>,
      );
      const deleteCell = createCell(
        'delete',
        'n/a',
        <Link.ButtonLink
          onClick={() => {
            showModal('DELETE_CLINICAL_CONCERN', {
              clinicalConcernUid: clinicalConcern.uid,
              studyUid,
              onDelete: onDeleteClinicalConcern,
            });
          }}
          fontSize="16px"
        >
          DELETE
        </Link.ButtonLink>,
      );
      cells.unshift(editCell, deleteCell);
    }
    return {
      id: clinicalConcern.uid,
      cells,
    };
  });

  const onPressAddNewClinicalConcern = () => showModal(
    'CLINICAL_CONCERNS',
    {
      studyUid,
      userUid,
      navigate: false,
      onRefetch: refetch,
    },
  );

  const onPressGenerateCSV = async () => {
    setClinicalConcernsLoading(true);
    const participantAttributesObject = {
      PID: 'participant_id',
      Status: 'status',
      'Referral Source': 'referral_source',
    };
    if (!permissions.isBlinded) {
      Object.assign(participantAttributesObject, { 'Study Arm': 'study_arm.name' });
    }
    const participantAttributes = JSON.stringify(participantAttributesObject);
    const result = await generateClinicalConcernsMutatation({
      variables: {
        studyUid,
        userUid,
        participantAttributes,
      },
    });
    if (result.errors) {
      showModal(
        'ERROR_POPUP',
        {
          header: 'Error while generating CSV:',
          errors: result.errors.map((mutationError) => mutationError.message),
        },
      );
    } else {
      const csvUrl = result?.data?.generateClinicalConcernsCsv?.url;
      if (csvUrl) {
        window.open(csvUrl, '_blank');
      }
    }
    setClinicalConcernsLoading(false);
  };

  const generateClinicalConcernsButton = clinicalConcernsLoading
    ? <LoadingSpinner /> : (
      <Button
        onClick={onPressGenerateCSV}
        style={{ marginBottom: 16 }}
        variant="contained"
        color="primary"
        disabled={clinicalConcernsLoading}
      >
        Download Clinical Concerns CSV
      </Button>
    );

  return (
    <Box height="100%" display="inline">
      {permissions.canModifyParticipants && (
        <Box display="block">
          <Button
            onClick={onPressAddNewClinicalConcern}
            style={{ marginBottom: 16, marginRight: 16 }}
            variant="contained"
            color="primary"
          >
            Create New Clinical Concern
          </Button>
        </Box>
      )}
      {permissions.canViewStudy && generateClinicalConcernsButton}
      <PaginatedAssessmentTable
        ariaLabel="clinical-concerns-table"
        assessment={clinicalConcernAssessment}
        assessmentResults={clinicalConcerns}
        staticColumns={staticColumns}
        staticRows={staticRows}
        totalCount={totalCount}
        onSortByColumn={handleSortByColumn}
        onGoToPage={handleGoToPage}
        {...paginationState}
        skipRowSort
      />
    </Box>
  );
};

export default ClinicalConcernsLog;
