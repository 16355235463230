import React, { useContext, useState } from 'react';
import 'date-fns';
import { TextField, Select } from '@material-ui/core';
import { LoadingSpinner } from 'react-limbix-ui';
import { GraphQLError } from 'graphql';

import Styled from '../Modal.styles';

import {
  LimbixUserAccountType,
  MutationEditParentsArgs,
  QueryStudyParticipantsArgs,
} from '@/apollo/types';
import ExclamationSVG from '@/assets/ExclamationCircle.svg';
import { STUDY_QUERY_PARTICIPANT_DATA } from '@/apollo/queries';
import { EDIT_PARENTS } from '@/apollo/mutations';
import { ModalPayloadType } from '@/types/ReduxTypes';
import { useQuery, useMutation } from '@/hooks/apollo';
import { ModalLoadingContext } from '@/components/Modal/Modal';

type Props = {
  payload: ModalPayloadType['EDIT_PARENTS'];
  onClose: () => void;
};
const EditParents: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;

  const { studyParticipant, onRefetch } = payload;

  const { loading: queryLoading, data, error } = useQuery<QueryStudyParticipantsArgs>(STUDY_QUERY_PARTICIPANT_DATA, {
    variables: {
      studyUid: studyParticipant.study.uid,
    },
  });

  const existingParents = studyParticipant.user.parents;

  const [selectedParentUids, setSelectedParentUids] = useState(
    existingParents.map((parent) => parent.uid),
  );
  const [mutationErrors, setMutationErrors] = useState<readonly GraphQLError[]>(null);
  const [isConfirming, setIsConfirming] = useState(false);
  const [changeReasonValue, setChangeReasonValue] = useState<string>();
  const { modalLoading, setModalLoading } = useContext(ModalLoadingContext);

  const [editParentsMutation] = useMutation<MutationEditParentsArgs>(
    EDIT_PARENTS,
  );
  const editParents = async (parentUids: Array<string>, changeReason: string) => {
    setModalLoading(true);
    const result = await editParentsMutation({
      variables: {
        childUid: studyParticipant.user.uid,
        studyUid: studyParticipant.study.uid,
        parentUids,
        changeReason,
      },
    });
    if (onRefetch) {
      await onRefetch();
    }
    setModalLoading(false);
    return result;
  };

  const loading = queryLoading || modalLoading;
  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <p>{error.message}</p>
    );
  }

  const parentOptions = data.studyParticipants
    .map((participant) => participant.user)
    .filter((user) => user.accountType === LimbixUserAccountType.Parent);

  const submitAndClose = async () => {
    const result = await editParents(selectedParentUids, changeReasonValue);
    if (result.errors) {
      setMutationErrors(result.errors);
    } else {
      onClose();
    }
  };

  const handleChangeMultiple = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { options } = event.target as HTMLSelectElement;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setSelectedParentUids(value);
  };

  const isParentSelected = selectedParentUids.length > 0;

  const formModalContent = (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 16 }}>
        <Styled.HeaderText style={{ marginBottom: 32 }}>
          {`Edit parents for participant ${studyParticipant.participantId}`}
        </Styled.HeaderText>
        <Select
          labelId="select-parents"
          id="select-parents"
          multiple
          native
          value={selectedParentUids}
          onChange={handleChangeMultiple}
          inputProps={{
            id: 'select-multiple-native',
          }}
        >
          {parentOptions.map((parent) => (
            <option key={parent.uid} value={parent.uid}>
              {parent.email}
            </option>
          ))}
        </Select>
      </div>
      <div style={{ display: 'flex' }}>
        <Styled.NoButton onClick={onClose}>
          <Styled.NoButtonText>
            Cancel
          </Styled.NoButtonText>
        </Styled.NoButton>
        <Styled.YesButton
          style={{ backgroundColor: isParentSelected ? null : 'gray' }}
          disabled={!isParentSelected}
          onClick={() => setIsConfirming(true)}
          color="primary"
        >
          <Styled.YesButtonText>
            Submit
          </Styled.YesButtonText>
        </Styled.YesButton>
      </div>
    </>
  );

  const confirmModalContent = (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ marginRight: 16, marginTop: 4 }}>
        <Styled.WarningSVG src={ExclamationSVG} />
      </div>
      <div>
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 16 }}>
          <Styled.HeaderText style={{ marginBottom: 16 }}>
            {`Confirm Change to Parents for PID${studyParticipant.participantId}`}
          </Styled.HeaderText>
          <b>Old parents:</b>
          <p>{existingParents.map((parent) => parent.email).join(', ')}</p>
          <b>New parents:</b>
          <p>
            {selectedParentUids.length > 0 && selectedParentUids
              .map((uid) => parentOptions.find((parent) => parent.uid === uid).email)
              .join(', ')}
          </p>
          {mutationErrors && (
            <div>
              {mutationErrors.map((mutationError) => mutationError.message)}
            </div>
          )}
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="change reason"
            label="Change Reason"
            id="change reason"
            onChange={(event) => setChangeReasonValue(event.target.value?.trim())}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <Styled.NoButton onClick={() => setIsConfirming(false)}>
            <Styled.NoButtonText>
              Back
            </Styled.NoButtonText>
          </Styled.NoButton>
          <Styled.YesButton
            onClick={submitAndClose}
            color="primary"
          >
            <Styled.YesButtonText>
              Confirm
            </Styled.YesButtonText>
          </Styled.YesButton>
        </div>
      </div>
    </div>
  );

  return (
    <div style={{ width: '416px', height: '75%' }}>
      <Styled.DialogContent>
        { isConfirming ? confirmModalContent : formModalContent }
      </Styled.DialogContent>
    </div>
  );
};

export default EditParents;
