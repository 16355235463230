import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { LoadingSpinner, Box } from 'react-limbix-ui';

import history from '@/utils/history';
import Routes from '@/routes';
import {
  Header,
  Modal,
  IdleTimer,
} from '@/components';
import { StudyPermission, StudyPermissions } from '@/types';
import { useActions, usePermissions } from '@/hooks/redux';
import { getUserInfoPermission } from '@/hooks/apollo/queries';

const IDLE_DURATION_MS = 480000;
const COUNTDOWN_DURATION_MS = 120000;

const App: React.FC = () => {
  const { global: { logout } } = useActions();
  const [permissions, setPermissions] = usePermissions();
  const client = useApolloClient();
  const [user, studyList] = getUserInfoPermission();

  if (user.loading || studyList.loading) {
    return <LoadingSpinner />;
  }

  if (user.error || !user.data) {
    console.log(user.error?.message);
    return <Redirect to="/login" />;
  }

  if (user?.data && studyList?.data) {
    const permissionsUninitialized = Object.values(permissions).every(
      (value) => (typeof value === 'object' ? !Object.keys(value).length : !value),
    );
    if (permissionsUninitialized) {
      const isLoggedIn = !!user.data?.me;
      const { me: { isResearch, isOperations } } = user.data;
      const studyPermissions = {} as StudyPermissions;
      studyList.data?.limbixStudies?.forEach((study) => {
        studyPermissions[study.uid] = study as StudyPermission;
      });
      setPermissions({
        isLoggedIn,
        isResearch,
        isOperations,
        studyPermissions,
      });
    }
  }

  const handleLogout = async () => {
    await client.clearStore();
    localStorage.clear();
    history.push('/login');
    logout();
  };

  return (
    <Box minHeight="100vh">
      <Header onLogout={handleLogout} limbixUser={user.data.me} />
      <Box display="flex" maxWidth="xl">
        <Box
          display="block"
          width="100%"
          padding="15px 30px 64px 30px"
          marginTop="88px"
        >
          <Switch>
            <Routes permissions={permissions} />
          </Switch>
        </Box>
      </Box>
      <IdleTimer
        idleDuration={IDLE_DURATION_MS}
        countdownDuration={COUNTDOWN_DURATION_MS}
        onLogout={handleLogout}
      />
      <Modal />
    </Box>
  );
};

export default App;
