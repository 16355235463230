import React, { PropsWithChildren } from 'react';

import { Link } from 'react-limbix-ui';

import { UPDATE_PARTICIPANT_USER_LANGUAGE } from '@/apollo/mutations';
import {
  LanguageType,
  MutationEditParticipantDataArgs,
} from '@/apollo/types';
import { useMutation } from '@/hooks/apollo';
import { useModal } from '@/hooks/redux';
import { LANGUAGE_MAP } from '@/utils/constants';
import { MutationResultType, UnknownAsyncCallback } from '@/types/DataTypes';

type Props = {
  participantId: string;
  participantUserUid: string;
  participantEmail: string;
  participantLanguage: LanguageType;
  studyUid: string;
  candidateLanguages: LanguageType[]
  onRefetch?: UnknownAsyncCallback;
};

const LanguageSetter: React.FC<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>) => {
  const {
    participantUserUid,
    participantId,
    participantEmail,
    participantLanguage,
    studyUid,
    candidateLanguages,
    onRefetch,
  } = props;

  const { showModal } = useModal();

  const [updateLanguageMutation] = useMutation<MutationEditParticipantDataArgs>(
    UPDATE_PARTICIPANT_USER_LANGUAGE,
  );

  const updateLanguage = async (selectedLanguage: LanguageType, changeReason: string): Promise<MutationResultType> => {
    const result = await updateLanguageMutation({
      variables: {
        userUid: participantUserUid,
        language: selectedLanguage,
        studyUid,
        changeReason,
      },
    });
    if (onRefetch) {
      await onRefetch();
    }
    return result;
  };

  const confirmSelectedLanguage = async (selectedLanguage: LanguageType) => {
    const participantIdentifier = participantId ? ` ${participantId}` : '';
    showModal('CONFIRMATION_POPUP_CHANGE_REASON', {
      header: 'Confirm User Language Update',
      body: `Please enter a reason for setting study participant${participantIdentifier}'s language 
      to ${LANGUAGE_MAP[selectedLanguage]}`,
      mutation: (changeReason: string) => updateLanguage(selectedLanguage, changeReason),
    });
  };

  const onClickSetLanguage = async () => {
    showModal('UPDATE_PARTICIPANT_LANGUAGE', {
      currentLanguage: participantLanguage,
      candidateLanguages,
      onSubmit: confirmSelectedLanguage,
      participantId,
      participantEmail,
    });
  };

  return (
    <Link.ButtonLink onClick={onClickSetLanguage}>{props.children || 'set language'}</Link.ButtonLink>
  );
};

export default LanguageSetter;
