import React, { useState } from 'react';
import {
  Column,
  Row,
  Box,
  createCell,
  createColumn,
  calculateNewSortState,
  Table,
  SortState,
} from 'react-limbix-ui';
import { Button } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';

import {
  AssessmentType,
  AssignmentScheduleAccountType,
  AssignmentScheduleInputType,
  AssignmentScheduleScheduleOnStudyState,
  AssignmentScheduleTypeOfNotificationForAssignment,
  AssignmentTypeOfClient,
} from '@/apollo/types';
import { useModal } from '@/hooks/redux';
import { formatCapitalSnakeCase } from '@/utils/stringUtils';
import {
  AssignmentScheduleDeletionResult,
  AssignmentScheduleInputWithLockState,
} from '@/types/DataTypes';

type Props = {
  assignmentSchedules: AssignmentScheduleInputWithLockState[];
  assessments: AssessmentType[];
  onSave: (assignmentSchedule: AssignmentScheduleInputType, changeReason: string) => Promise<void>,
  onDelete: (assignmentScheduleUid: string, changeReason: string) => AssignmentScheduleDeletionResult,
};

const DEFAULT_SORT_STATE: SortState = { columnId: 'assessmentName', direction: 'asc' };

const AssignmentScheduleEditor: React.FC<Props> = (props: Props) => {
  const {
    assignmentSchedules,
    assessments,
    onSave,
    onDelete,
  } = props;

  const [sortState, setSortState] = useState(DEFAULT_SORT_STATE);

  const { showModal } = useModal();

  const defaultAssignmentSchedule: AssignmentScheduleInputType = {
    uid: undefined as string,
    assessmentUid: assessments[0].uid,
    count: 1,
    ordinal: 1,
    repeatFrequencyDays: 0,
    startTimeOffsetDays: 0,
    visibleDurationDays: 0,
    canEditAfterCompletion: false,
    canSkipQuestions: false,
    sendPushNotifications: true,
    sendEmailNotifications: true,
    sendSmsNotifications: true,
    accountType: AssignmentScheduleAccountType.Adult,
    scheduleOnStudyState: AssignmentScheduleScheduleOnStudyState.Activation,
    typeOfClientForAssignment: AssignmentTypeOfClient.Mobile,
    typeOfNotificationForAssignment: AssignmentScheduleTypeOfNotificationForAssignment.IntraStudy,
  };

  const columns: Column[] = [
    createColumn('assessmentName', 'Assessment Name', 'string', null, true),
    createColumn('accountType', 'Account Type', 'string', null, true),
    createColumn('clientType', 'Client Type', 'string', null, true),
    createColumn('schedule', 'Scheduling Trigger', 'string', null, true),
    createColumn('notificationType', 'Notification Type', 'string', null, true),
    createColumn('editAssessment', '', 'string', null, false),
    createColumn('duplicateAssessment', '', 'string', null, false),
    createColumn('deleteAssessment', '', 'string', null, false),
  ];

  const handleSortByColumn = (columnId: string) => {
    const newSortState = calculateNewSortState(columnId, sortState);
    setSortState(newSortState);
  };

  const renderDuplicateButton = (assignmentSchedule: AssignmentScheduleInputWithLockState) => (
    <Button
      startIcon={<FileCopyIcon />}
      onClick={() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { isLocked, ...assignmentScheduleWithoutLockState } = assignmentSchedule;
        showModal('EDIT_ASSIGNMENT_SCHEDULE', {
          assignmentSchedule: {
            ...assignmentScheduleWithoutLockState,
            uid: undefined as string,
          },
          assessments,
          onSave,
        });
      }}
    >
      duplicate
    </Button>
  );

  const renderEditButton = (assignmentSchedule: AssignmentScheduleInputWithLockState) => (
    <Button
      startIcon={<Edit />}
      onClick={() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { isLocked, ...assignmentScheduleWithoutLockState } = assignmentSchedule;
        showModal('EDIT_ASSIGNMENT_SCHEDULE', {
          assignmentSchedule: assignmentScheduleWithoutLockState,
          assessments,
          onSave,
        });
      }}
      disabled={assignmentSchedule.isLocked}
    >
      {assignmentSchedule.isLocked ? 'locked' : 'edit'}
    </Button>
  );

  const renderDeleteButton = (assignmentSchedule: AssignmentScheduleInputWithLockState) => (
    <Button
      startIcon={<Delete />}
      onClick={() => showModal('DELETE_ASSIGNMENT_SCHEDULE', {
        assignmentScheduleUid: assignmentSchedule.uid,
        onDelete,
      })}
      disabled={assignmentSchedule.isLocked}
    >
      {assignmentSchedule.isLocked ? 'locked' : 'delete'}
    </Button>
  );

  const addNewAssignmentScheduleButton = (
    <Box style={{ padding: '16px 0px 16px 0px' }}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => showModal('EDIT_ASSIGNMENT_SCHEDULE', {
          assignmentSchedule: defaultAssignmentSchedule,
          assessments,
          onSave,
        })}
      >
        Add new assignment schedule
      </Button>
    </Box>
  );

  const rows: Row[] = assignmentSchedules
    .map((assignmentSchedule) => ({
      id: assignmentSchedule.uid,
      cells: [
        createCell(
          'assessmentName',
          assessments.find((assessment) => assessment.uid === assignmentSchedule.assessmentUid).nameInternal,
        ),
        createCell('accountType', formatCapitalSnakeCase(assignmentSchedule.accountType)),
        createCell('clientType', formatCapitalSnakeCase(assignmentSchedule.typeOfClientForAssignment)),
        createCell('schedule', formatCapitalSnakeCase(assignmentSchedule.scheduleOnStudyState)),
        createCell('notificationType', formatCapitalSnakeCase(assignmentSchedule.typeOfNotificationForAssignment)),
        createCell('editAssignment', 'Edit', renderEditButton(assignmentSchedule)),
        createCell('duplicateAssignment', 'Duplicate', renderDuplicateButton(assignmentSchedule)),
        createCell('deleteAssignment', 'Delete', renderDeleteButton(assignmentSchedule)),
      ],
    }));

  return (
    <Box>
      {addNewAssignmentScheduleButton}
      <Table
        columns={columns}
        rows={rows}
        ariaLabel="edit_assignment_schedules"
        sortState={sortState}
        onSortByColumn={handleSortByColumn}
      />
    </Box>
  );
};

export default AssignmentScheduleEditor;
