import React, { useState, useEffect } from 'react';

import Styled from '../Modal.styles';

import ExclamationSVG from '@/assets/ExclamationCircle.svg';
import { ModalPayloadType } from '@/types/ReduxTypes';

type Props = {
  payload: ModalPayloadType['IDLE_WARNING'];
  onClose: () => void;
};

const IdleWarning: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;

  const {
    countdownDuration,
    stayActive,
    isLeader,
    pause,
    onLogout,
  } = payload;

  const [counter, setCounter] = useState(countdownDuration / 1000);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (counter > 0) {
      timeout = setTimeout(() => setCounter(counter - 1), 1000);
    } else if (!isLeader()) {
      pause();
    } else {
      onLogout();
    }
    return () => clearTimeout(timeout);
  }, [counter]);

  const closeAndStayActive = () => {
    onClose();
    stayActive();
  };

  const closeAndLogout = () => {
    onClose();
    onLogout();
  };

  return (
    <div style={{ width: '416px', height: '250px' }}>
      <Styled.DialogContent>
        <div style={{ display: 'flex' }}>
          <Styled.WarningSVGContainer>
            <Styled.WarningSVG src={ExclamationSVG} />
          </Styled.WarningSVGContainer>
          <Styled.TextContainer>
            <Styled.HeaderText>
              Your session is about to expire!
            </Styled.HeaderText>
            <Styled.BodyText>
              {'You\'ve been inactive for a while. For your security, we\'ll automatically sign \
              out after 10 minutes of inactivity unless you extend your session.'}
            </Styled.BodyText>
            <Styled.BodyText>
              {`You will be logged off in ${counter} seconds`}
            </Styled.BodyText>
          </Styled.TextContainer>
        </div>
        <Styled.ButtonContainer>
          <Styled.NoButton onClick={closeAndLogout}>
            <Styled.NoButtonText>
              Logout
            </Styled.NoButtonText>
          </Styled.NoButton>
          <Styled.YesButton onClick={closeAndStayActive}>
            <Styled.YesButtonText>
              Keep me signed in
            </Styled.YesButtonText>
          </Styled.YesButton>
        </Styled.ButtonContainer>
      </Styled.DialogContent>
    </div>
  );
};

export default IdleWarning;
