import React, { useCallback, useState } from 'react';
import { Box, Memo, Text } from 'react-limbix-ui';

import { TextField } from '@material-ui/core';

import { AssessmentFieldTextInput, EditQuestionTextField } from '../AssessmentEditor.components';

import {
  ActionType, ActionTypeEnum, AssessmentField, AssessmentTranslationField, LanguageKey, StateType, TextTranslation,
} from '../AssessmentEditor.types';

import LanguageDropdown from '@/components/Select/LanguageDropdown';
import { LimbixUserLanguage } from '@/apollo/types';
import { HTMLInputChangeEvent, UnknownChangeEvent } from '@/types';

const EXCLUDED_LANGUAGES = [LimbixUserLanguage.En];

type TranslationEditorProps = {
  assessmentEditorState: StateType
  dispatch: (value: ActionType) => void;
};

const TranslationEditor: React.FC<TranslationEditorProps> = Memo(({
  assessmentEditorState, dispatch,
}) => {
  const {
    nameTranslations,
    introTextTranslations,
    contextTextTranslations,
    preQuestionTextTranslations,
    questions,
  } = assessmentEditorState;
  const [language, setLanguage] = useState('es' as LanguageKey);

  const handleChangeAssessmentText = useCallback((event: HTMLInputChangeEvent, field: AssessmentField) => (
    dispatch({
      type: ActionTypeEnum.EDIT_ASSESSMENT_TEXT_TRANSLATION,
      fieldName: field as AssessmentTranslationField,
      text: event.target.value,
      language,
    })
  ), [language]);

  const handleChangeQuestionText = useCallback((event: UnknownChangeEvent, questionIndex: number) => {
    dispatch({
      type: ActionTypeEnum.EDIT_QUESTION_TEXT_TRANSLATION,
      questionIndex,
      text: event.target.value as string,
      language,
    });
  }, [language]);

  const handleChangeAnswerText = useCallback((
    event: HTMLInputChangeEvent,
    questionIndex: number,
    answerIndex: number,
  ) => {
    dispatch({
      type: ActionTypeEnum.EDIT_ANSWER_TEXT_TRANSLATION,
      questionIndex,
      answerIndex,
      language,
      text: event.target.value as string,
    });
  }, [language]);

  const localize = useCallback((textTranslation: TextTranslation) => {
    if (!textTranslation) {
      return '';
    }
    return textTranslation[language.toLowerCase() as keyof TextTranslation] as string || '';
  }, [language]);

  return (
    <Box>
      <Box>
        <LanguageDropdown
          currentLanguage={language as LimbixUserLanguage}
          onChange={(e) => {
            setLanguage(e.target.value as LanguageKey);
          }}
          excludedLanguages={EXCLUDED_LANGUAGES}
        />
        <AssessmentFieldTextInput
          field="nameTranslations"
          label="Assessment Name"
          fieldValue={localize(nameTranslations)}
          onEditAssessmentField={handleChangeAssessmentText}
          helperText={nameTranslations?.en}
        />
        <AssessmentFieldTextInput
          field="contextTextTranslations"
          label="Assessment Context"
          fieldValue={localize(contextTextTranslations)}
          onEditAssessmentField={handleChangeAssessmentText}
          helperText={contextTextTranslations?.en}

        />
        <AssessmentFieldTextInput
          field="introTextTranslations"
          label="Intro Text"
          fieldValue={localize(introTextTranslations)}
          onEditAssessmentField={handleChangeAssessmentText}
          helperText={introTextTranslations?.en}
        />
        <AssessmentFieldTextInput
          field="preQuestionTextTranslations"
          label="Pre-Question Text"
          fieldValue={localize(preQuestionTextTranslations)}
          onEditAssessmentField={handleChangeAssessmentText}
          helperText={preQuestionTextTranslations?.en}
        />
        {questions && (
          <Text as="h2">
            Questions:
          </Text>
        )}
        {questions.map((question, questionIndex) => (
          <Box key={+questionIndex} id={`question-${questionIndex}`} marginTop="8px" marginBottom="8px">
            <EditQuestionTextField
              id={`${+questionIndex}-text`}
              label="Question Text"
              value={localize(question.textTranslations)}
              questionIndex={questionIndex}
              onChange={(event) => handleChangeQuestionText(event, questionIndex)}
              helperText={question.text}
            />
            {(question.answers?.length > 0) && (
              <>
                {question?.answers?.map((answer, answerIndex) => (
                  <Box key={`${+questionIndex}-${+answerIndex}`} marginLeft="24px" marginBottom="12px">
                    <Box margin="10px 0px">
                      <Text margin="8px 0px">
                        {answer.text}
                      </Text>
                      <Box>
                        <TextField
                          variant="outlined"
                          id={`Answer Option ${answerIndex + 1}`}
                          label={`Answer Option ${answerIndex + 1}`}
                          multiline
                          maxRows={8}
                          style={{ width: '300px' }}
                          value={localize(answer.textTranslations)}
                          size="small"
                          onChange={
                            (event:HTMLInputChangeEvent) => handleChangeAnswerText(event, questionIndex, answerIndex)
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                ))}
              </>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
});

export default TranslationEditor;
