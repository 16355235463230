export const replaceInArray = <T, >(array: Array<T>, item: T, index: number): Array<T> => {
  const copy = [...array];
  copy.splice(index, 1, item);
  return copy;
};

export const removeFromArray = <T, >(array: Array<T>, index: number): Array<T> => {
  const copy = [...array];
  if (index === -1) {
    return copy.length > 0 ? copy.slice(0, copy.length - 1) : [];
  }
  copy.splice(index, 1);
  return copy;
};

export const groupBy = <T, >(array: Array<T>, groupByFunction: (item: T) => string): Record<string, Array<T>> => (
  array.reduce((acc, curr) => {
    const key = groupByFunction(curr);
    acc[key] = acc[key] || [];
    acc[key].push(curr);
    return acc;
  }, {} as Record<string, Array<T>>)
);

export const range = (start: number, stop: number): Array<number> => (
  Array.from({ length: stop - start }, (_, key) => key + start)
);
