import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ModalContentType,
  ModalPayloadType,
} from '@/types/ReduxTypes';

export const defaultModalState = {
  visible: false,
  modalType: 'NONE' as ModalContentType,
  payload: (null as ModalPayloadType[ModalContentType]),
};

export type ModalStateType = typeof defaultModalState;

type ShowModalPayload = {
  modalType: ModalContentType,
  payload: ModalPayloadType[ModalContentType],
};

const modalSlice = createSlice({
  name: 'modal',
  initialState: defaultModalState,
  reducers: {
    showModal: (state, action: PayloadAction<ShowModalPayload>) => {
      const { payload } = action;
      state.visible = true;
      state.modalType = payload.modalType;
      state.payload = payload.payload;
    },
    hideModal: () => ({ ...defaultModalState }),
  },
});

export const { actions, reducer } = modalSlice;
