import React from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Button } from '@material-ui/core';

import history from '@/utils/history';
import { usePermissions } from '@/hooks/redux';

const Selection: React.FC = () => {
  const [permissions] = usePermissions();
  if (permissions.isResearch && permissions.isOperations) {
    return (
      <Container component="main" maxWidth="xs">
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={() => history.push('/operations')}
          style={{ float: 'left' }}
        >
          Operations
        </Button>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={() => history.push('/research')}
          style={{ float: 'right' }}
        >
          Research
        </Button>
      </Container>
    );
  }
  if (!permissions.isResearch && !permissions.isOperations) {
    return <h1>Are you allowed here?</h1>;
  }
  return permissions.isResearch ? <Redirect to="/research" /> : <Redirect to="/operations" />;
};

export default Selection;
