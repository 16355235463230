import {
  Typography as MuiTypography,
} from '@material-ui/core';
import MuiSkeleton from '@material-ui/lab/Skeleton';
import styled from 'styled-components/macro';

const ChartContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const QuestionnaireContainer = styled.div`
  width: 40%;
  height: 100%;
`;

const SkeletonContainter = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Skeleton = styled(MuiSkeleton)`
  position: absolute;
`;

const PHQHeaderContainer = styled.div`
  display: flex;
`;

const BoldText = styled(MuiTypography)`
  font-family: Proxima Nova;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;
`;

const PHQBaselineText = styled(MuiTypography)`
  font-family: Proxima Nova;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: center;
  padding-left: 48px;
`;

const ChartDiv = styled.div`
  min-height: 450px;
  height: 100%;
  min-width: 600px;
  @media (max-width: 1279px) {
    overflow-x: hidden;
  }
  @media (min-width: 1280px) {
    width: 100%;
  }
`;

const OrderedList = styled.ol`
  @media (max-width: 726px) {
    padding: 0px;
  }
`;

export default {
  ChartContainer,
  QuestionnaireContainer,
  SkeletonContainter,
  Skeleton,
  PHQHeaderContainer,
  BoldText,
  PHQBaselineText,
  ChartDiv,
  OrderedList,
};
