import React, { useState } from 'react';

import { LoadingSpinner } from 'react-limbix-ui';
import { TextField } from '@material-ui/core';
import { GraphQLError } from 'graphql';

import Styled from '../Modal.styles';

import { ModalPayloadType } from '@/types/ReduxTypes';
import ExclamationSVG from '@/assets/ExclamationCircle.svg';

type Props = {
  payload: ModalPayloadType['DELETE_CLINICAL_CONCERN'];
  onClose: () => void;
}

const DeleteClinicalConcern: React.FC<Props> = ({
  payload,
  onClose,
}: Props) => {
  const {
    studyUid,
    clinicalConcernUid,
    onDelete,
  } = payload;
  const [changeReasonText, setChangeReasonText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [errors, setErrors] = useState([] as GraphQLError[]);

  const canDelete = changeReasonText.length > 0;

  const handleDelete = async () => {
    setIsDeleting(true);
    const response = await onDelete(clinicalConcernUid, studyUid, changeReasonText);
    setIsDeleting(false);
    if (response?.errors) {
      setErrors([...response.errors]);
    } else {
      onClose();
    }
  };

  const modalBody = (
    <>
      <Styled.BodyText>
        {'Are you sure that you want to delete this clinical concern? \
        Please enter a change reason:'}
      </Styled.BodyText>
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        name="change reason"
        label="Change Reason"
        id="change reason"
        onChange={(event) => setChangeReasonText(event.target.value)}
      />
    </>
  );

  const errorText = (
    <>
      <Styled.ErrorText>
        Unable to delete clinical concern log:
      </Styled.ErrorText>
      {errors.map((error) => (
        <Styled.ErrorText key={error.message}>
          {error.message}
        </Styled.ErrorText>
      ))}
    </>
  );

  return (
    <Styled.DialogContent>
      <div style={{ display: 'flex', paddingBottom: 32 }}>
        <Styled.WarningSVGContainer>
          <Styled.WarningSVG src={ExclamationSVG} />
        </Styled.WarningSVGContainer>
        <Styled.TextContainer>
          <Styled.HeaderText>
            Delete Clinical Concern Log
          </Styled.HeaderText>
          { errors.length > 0
            ? errorText
            : modalBody }
        </Styled.TextContainer>
      </div>
      <Styled.ButtonContainer>
        { isDeleting
          ? <LoadingSpinner />
          : (
            <>
              <Styled.NoButton onClick={onClose}>
                <Styled.NoButtonText>
                  {errors.length > 0 ? 'Close' : 'No' }
                </Styled.NoButtonText>
              </Styled.NoButton>
              {errors.length === 0 && (
                <Styled.YesButton
                  onClick={handleDelete}
                  disabled={!canDelete}
                  color={canDelete ? 'primary' : 'secondary'}
                >
                  <Styled.YesButtonText>
                    Delete
                  </Styled.YesButtonText>
                </Styled.YesButton>
              )}
            </>
          )}
      </Styled.ButtonContainer>
    </Styled.DialogContent>
  );
};

export default DeleteClinicalConcern;
