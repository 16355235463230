import {
  Typography as MuiTypography,
} from '@material-ui/core';
import styled from 'styled-components/macro';

const BoldText = styled(MuiTypography)<{ size?: string }>`
  font-family: Proxima Nova;
  font-size: ${(props) => (props.size ? props.size : '18px')};
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0px;
`;

const DetailText = styled(MuiTypography)`
  font-family: Proxima Nova;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`;

const OrderedList = styled.ol`
  @media (max-width: 1279px) {
    >li:nth-child(odd){
      background-color: #EAEAEA;
    }
    padding-left: 10px;
  }
`;

export default {
  BoldText,
  DetailText,
  OrderedList,
};
