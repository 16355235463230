import React, { useState } from 'react';
import { TextField } from '@material-ui/core';

import Styled from '../Modal.styles';

import { GraphQLErrorsType, MutationResultType } from '@/types';
import { STUDY_QUERY, STUDY_LIST_QUERY } from '@/apollo/queries';
import {
  ADD_STUDY_COORDINATOR,
  ADD_STUDY_CO_INVESTIGATOR,
} from '@/apollo/mutations';
import {
  MutationAddStudyCoordinatorArgs,
  MutationAddStudyCoInvestigatorArgs,
} from '@/apollo/types';
import ExclamationSVG from '@/assets/ExclamationCircle.svg';
import { ModalPayloadType } from '@/types/ReduxTypes';
import { useMutation } from '@/hooks/apollo';

type Props = {
  payload: ModalPayloadType['ADD_STUDY_PERSONNEL'];
  onClose: () => void;
};
const AddStudyPersonnel: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;
  const [textFieldValue, setTextFieldValue] = useState('');
  const [errors, setErrors] = useState<GraphQLErrorsType>([]);

  const { limbixStudy, type } = payload;

  const refetchQueries = [
    {
      query: STUDY_QUERY,
      variables: {
        studyUid: limbixStudy.uid,
      },
    },
    {
      query: STUDY_LIST_QUERY,
    },
  ];

  const [addStudyCoordinatorMutation] = useMutation<MutationAddStudyCoordinatorArgs>(
    ADD_STUDY_COORDINATOR, { refetchQueries },
  );
  const addStudyCoordinator = (userEmail: string): Promise<MutationResultType> => (
    addStudyCoordinatorMutation({
      variables: {
        studyUid: limbixStudy.uid,
        userEmail,
      },
    })
  );

  const [addStudyCoInvestigatorMutation] = useMutation<MutationAddStudyCoInvestigatorArgs>(
    ADD_STUDY_CO_INVESTIGATOR, { refetchQueries },
  );
  const addStudyCoInvestigator = (userEmail: string): Promise<MutationResultType> => (
    addStudyCoInvestigatorMutation({
      variables: {
        studyUid: limbixStudy.uid,
        userEmail,
      },
    })
  );

  const addAndClose = async () => {
    let mutation: (userEmail: string) => Promise<MutationResultType>;
    if (type === 'Study Coordinator') {
      mutation = addStudyCoordinator;
    }
    if (type === 'Study CoInvestigator') {
      mutation = addStudyCoInvestigator;
    }
    const { errors: resultErrors } = await mutation(textFieldValue);
    if (resultErrors) {
      setErrors(resultErrors);
    } else {
      onClose();
    }
  };

  return (
    <div style={{ width: '416px', height: '425px' }}>
      <Styled.DialogContent>
        <div style={{ display: 'flex' }}>
          <Styled.WarningSVGContainer>
            <Styled.WarningSVG src={ExclamationSVG} />
          </Styled.WarningSVGContainer>
          <Styled.TextContainer>
            <Styled.HeaderText>
              Add Study Personnel
            </Styled.HeaderText>
            <Styled.BodyText>
              Enter the email of the limbix member you want to add for this Role:
              <strong>
                {` ${type}`}
              </strong>
              <br />
              For this study:
              <strong>
                {` ${limbixStudy.name}`}
              </strong>
              <br />
              <strong>
                User must be marked as research staff by eng first!
              </strong>
              <br />
              Enter their email here and submit
            </Styled.BodyText>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="User Email"
              label="User Email"
              id="User Email"
              onChange={(event) => setTextFieldValue(event.target.value)}
            />
            {errors.map((error) => (
              <div key={error.message}>
                {error.message}
              </div>
            ))}
          </Styled.TextContainer>
        </div>
        <Styled.ButtonContainer>
          <Styled.NoButton onClick={onClose}>
            <Styled.NoButtonText>
              No
            </Styled.NoButtonText>
          </Styled.NoButton>
          <Styled.YesButton onClick={addAndClose}>
            <Styled.YesButtonText>
              Yes - Add
            </Styled.YesButtonText>
          </Styled.YesButton>
        </Styled.ButtonContainer>
      </Styled.DialogContent>
    </div>
  );
};

export default AddStudyPersonnel;
