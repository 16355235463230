import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  NavigationType,
} from '@/types';

export const defaultNavigationState: NavigationType = {
  isBlocked: false,
  message: null,
  listeners: [],
};

type BlockNavigationOnEventPayloadType = {
  listeners: Array<keyof WindowEventMap>,
  message?: string,
};

type EventOccurredPayloadType = {
  value: boolean,
};

type BlockNavigationPayloadType = {
  message: string,
};

const navigationSlice = createSlice({
  name: 'navigation',
  initialState: defaultNavigationState,
  reducers: {
    blockNavigationOnEvent: (state, action: PayloadAction<BlockNavigationOnEventPayloadType>) => {
      const { payload } = action;
      state.isBlocked = false;
      state.message = payload.message;
      state.listeners = payload.listeners;
    },
    eventOccurred: (state, action: PayloadAction<EventOccurredPayloadType>) => {
      const { payload } = action;
      state.isBlocked = payload.value;
    },
    blockNavigation: (state, action: PayloadAction<BlockNavigationPayloadType>) => {
      const { payload } = action;
      state.isBlocked = true;
      state.message = payload.message;
      state.listeners = [];
    },
    unblockNavigation: () => ({ ...defaultNavigationState }),
  },
});

export const { actions, reducer } = navigationSlice;
