import React from 'react';

import Styled from '../Modal.styles';
import ModalTitle from '../ModalTitle';

import { genderKeyToValue } from '@/utils/stringUtils';
import { formatDate } from '@/utils/dateUtils';
import { ModalPayloadType } from '@/types/ReduxTypes';

type Props = {
  payload: ModalPayloadType['ALL_CONTACT_INFO'];
  onClose: () => void;
};

const AllContactInfo: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;

  const { studyParticipant } = payload;

  return (
    <div style={{ width: '450px', height: '500px' }}>
      <Styled.DialogContent>
        <div style={{ display: 'block' }}>
          <ModalTitle onClose={onClose}>
            <div>
              Participant Contact Info for
              <strong>
                {` "${studyParticipant.participantId || 'None'}" `}
              </strong>
            </div>
          </ModalTitle>
          <Styled.TextContainer>
            <Styled.BodyText>
              First Name:
              <strong>
                {` ${studyParticipant.user?.firstName || '-'}`}
              </strong>
              <br />
              Email:
              <strong>
                {` ${studyParticipant.user?.email || '-'}`}
              </strong>
              <br />
              Phone Number:
              <strong>
                {` ${studyParticipant.user?.phone || '-'}`}
              </strong>
              <br />
              Local Timezone:
              <strong>
                {` ${studyParticipant.user?.localTimezone || '-'}`}
              </strong>
              <br />
              Gender:
              <strong>
                {` ${genderKeyToValue(studyParticipant.user?.gender) || '-'}`}
              </strong>
              <br />
              Date of Birth:
              <strong>
                {` ${formatDate(studyParticipant.user?.dateOfBirth) || '-'}`}
              </strong>
              <br />
              Current Age:
              <strong>
                {` ${studyParticipant.user?.currentAge || '-'}`}
              </strong>
              <br />
              Age at Enrollment:
              <strong>
                {` ${studyParticipant.user?.ageAtEnrollment || '-'}`}
              </strong>
            </Styled.BodyText>
            {studyParticipant.user?.parents.map((parent, index) => (
              <Styled.BodyText key={parent.uid} style={{ marginTop: '20px' }}>
                {`Parent #${index + 1} First Name:`}
                <strong>
                  {` ${parent?.firstName || '-'}`}
                </strong>
                <br />
                {`Parent #${index + 1} Email:`}
                <strong>
                  {` ${parent?.email || '-'}`}
                </strong>
                <br />
                {`Parent #${index + 1} Phone Number:`}
                <strong>
                  {` ${parent?.phone || '-'}`}
                </strong>
              </Styled.BodyText>
            ))}
          </Styled.TextContainer>
        </div>
      </Styled.DialogContent>
    </div>
  );
};

export default AllContactInfo;
