import { combineReducers, AnyAction } from '@reduxjs/toolkit';

import { reducer as ResopsUserReducer } from './resopsUser';
import { reducer as ModalReducer } from './modal';
import { reducer as NavigationReducer } from './navigation';
import { reducer as AssignmentFiltersReducer } from './assignmentFilters';

import { GlobalActionTypes } from '@/types';

const appReducer = combineReducers({
  resopsUser: ResopsUserReducer,
  modal: ModalReducer,
  navigation: NavigationReducer,
  assignmentFilters: AssignmentFiltersReducer,
});

// eslint-disable-next-line
const rootReducer = (
  state: ReturnType<typeof appReducer>,
  action: AnyAction,
) => {
  if (action.type === GlobalActionTypes.ClearStore) {
    return appReducer(undefined, ({ type: undefined } as never));
  }
  return appReducer(state, (action as never));
};

export default rootReducer;
