import React from 'react';

import Styled from '../Modal.styles';
import ModalTitle from '../ModalTitle';

import { ModalPayloadType } from '@/types/ReduxTypes';

type Props = {
  payload: ModalPayloadType['ORGANIZATION_INFO'];
  onClose: () => void;
};

const OrganizationInfo: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;

  const { organization } = payload;

  return (
    <div style={{ width: '375px', height: '350px' }}>
      <Styled.DialogContent>
        <div style={{ display: 'block' }}>
          <ModalTitle onClose={onClose}>
            <div>
              Organization Contact Info for
              <br />
              <strong>
                {` "${organization.name || 'None'}" `}
              </strong>
            </div>
          </ModalTitle>
          <Styled.TextContainer>
            <Styled.BodyText>
              Email:
              <strong>
                {` ${organization?.email || '-'}`}
              </strong>
              <br />
              Phone:
              <strong>
                {` ${organization?.phone || '-'}`}
              </strong>
            </Styled.BodyText>
          </Styled.TextContainer>
        </div>
      </Styled.DialogContent>
    </div>
  );
};

export default OrganizationInfo;
