import React, { useEffect, useState, useCallback } from 'react';
import { Button } from '@material-ui/core';
import { Memo, Box, Text } from 'react-limbix-ui';
import isEqual from 'react-fast-compare';

import {
  AssessmentSwitch,
  Select,
} from '@/components';
import {
  AccountType,
  AssignmentStatus,
  UnknownChangeEvent,
  CheckedChangeEvent,
} from '@/types';
import { useAssignmentFilters } from '@/hooks/redux';

type AssignmentFilterProps = {
  assessmentNameToUidMap: Record<string, string>;
};

const AssignmentFilters: React.FC<AssignmentFilterProps> = (props: AssignmentFilterProps) => {
  const { assessmentNameToUidMap } = props;
  const { assignmentFilters, assignmentFiltersActions } = useAssignmentFilters();
  const {
    assessmentUids,
    status,
    accountType,
  } = assignmentFilters;
  const [assessmentUidList, setAssessmentUidList] = useState<Array<string>>(assessmentUids);

  useEffect(() => {
    setAssessmentUidList(Object.values(assessmentNameToUidMap));
    assignmentFiltersActions.updateAssessmentUids(Object.values(assessmentNameToUidMap));
  }, []);

  const applyAssesssmentFilter = () => {
    assignmentFiltersActions.updateAssessmentUids(assessmentUidList);
  };

  const resetUnappliedAssessmentFilters = () => {
    setAssessmentUidList(assessmentUids);
  };

  const handleAssessmentSwitchFlip = useCallback((assessmentUid: string) => (event: CheckedChangeEvent) => {
    const copy = new Set(assessmentUidList);
    if (event.target.checked) {
      copy.add(assessmentUid);
    } else {
      copy.delete(assessmentUid);
    }
    setAssessmentUidList(Array.from(copy).sort());
  }, [assessmentUidList]);

  const handleClickResetFiltersButton = () => {
    assignmentFiltersActions.resetFilters(Object.values(assessmentNameToUidMap));
    setAssessmentUidList(Object.values(assessmentNameToUidMap));
  };

  const handleClickShowNoneButton = () => {
    setAssessmentUidList([]);
  };

  const handleClickShowAllButton = () => {
    setAssessmentUidList(Object.values(assessmentNameToUidMap));
  };

  const handleSelectAccountType = (event: UnknownChangeEvent) => {
    assignmentFiltersActions.updateAccountType(event.target.value as AccountType);
  };

  const handleSelectAssignmentStatus = (event: UnknownChangeEvent) => {
    assignmentFiltersActions.updateStatusType(event.target.value as AssignmentStatus);
  };

  const hasNoUnappliedFilters = (assessmentUids.length === assessmentUidList.length
  && assessmentUids.every((value, index) => value === assessmentUidList[index]));
  return (
    <Box>
      <Box padding="10px">
        <Text as="h2">
          Assignment Filters
        </Text>
        <Box>
          <Button
            onClick={handleClickResetFiltersButton}
            variant="contained"
            color="default"
            size="small"
          >
            Reset Filters
          </Button>
        </Box>
      </Box>
      <Box display="block">
        <Select.Enum
          value={accountType as AccountType}
          enumType={AccountType}
          label="*Account Type: "
          allowNone
          onSelect={handleSelectAccountType}
        />
      </Box>
      <Box display="block" marginBottom="30px">
        <Select.Enum
          value={status as AssignmentStatus}
          enumType={AssignmentStatus}
          label="*Assignment Status: "
          allowNone
          onSelect={handleSelectAssignmentStatus}
        />
        <Text marginBottom="10px" fontSize="12px">
          *Defaults to &quot;Past Due&quot;
        </Text>
      </Box>
      <Box>
        <Text margin="12px" fontSize="18px">
          Assessment Names:
        </Text>
        <Box display="inline-flex" marginBottom="10px">
          <Box marginRight="10px">
            <Button
              onClick={handleClickShowNoneButton}
              variant="contained"
              color="default"
              size="small"
            >
              Show None
            </Button>
          </Box>
          <Box>
            <Button
              onClick={handleClickShowAllButton}
              variant="contained"
              color="primary"
              size="small"
            >
              Show All
            </Button>
          </Box>
        </Box>
        <Box marginRight="15px">
          {Object.entries(assessmentNameToUidMap).map(([assessmentName, uid]) => (
            <Box key={uid}>
              <AssessmentSwitch
                assessmentName={assessmentName}
                checked={assessmentUidList.includes(uid)}
                onSwitchFlip={handleAssessmentSwitchFlip(uid)}
              />
            </Box>
          ))}
        </Box>
        <Box display="inline-flex" marginBottom="10px" marginTop="15px">
          <Button
            onClick={applyAssesssmentFilter}
            variant="contained"
            color="default"
            size="small"
            disabled={hasNoUnappliedFilters}
          >
            Apply Assessment filter
          </Button>
          <Box marginLeft="10px">
            <Button
              onClick={resetUnappliedAssessmentFilters}
              variant="contained"
              color="primary"
              size="small"
              disabled={hasNoUnappliedFilters}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Memo(AssignmentFilters, (prevProps, nextProps) => (
  isEqual(prevProps.assessmentNameToUidMap, nextProps.assessmentNameToUidMap)
));
