import AssessmentList from './AssessmentList';
import EditAssessment from './EditAssessment';
import CreateAssessment from './CreateAssessment';
import TestAssessment from './TestAssessment';

export default {
  AssessmentList,
  EditAssessment,
  CreateAssessment,
  TestAssessment,
};
