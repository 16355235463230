import { useSelector } from 'react-redux';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';

import useActions from './useActions';

import { RootState } from '@/store';
import { ResopsUserType } from '@/types/ReduxTypes';

const usePermissions = (): [ResopsUserType, ActionCreatorWithOptionalPayload<ResopsUserType>] => {
  const { resopsUser: { updateResopsUser } } = useActions();
  const resopsUser = useSelector((state: RootState) => (state.resopsUser));
  return [resopsUser, updateResopsUser];
};

export default usePermissions;
