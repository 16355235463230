import {
  StateType,
  ActionTypeEnum,
  ActionType,
  LineType,
  ScheduleType,
} from './AssignmentScheduleViewer.types';

import { range } from '@/utils/arrayUtils';
import { AssignmentPathInfo } from '@/types';

const updateAccountTypeFilter = (state: StateType, action: ActionType): StateType => {
  if (action.type === ActionTypeEnum.UPDATE_ACCOUNT_TYPE_FILTER) {
    return {
      ...state,
      accountTypeFilter: action.value,
    };
  }
  return state;
};

const flipAssessmentFilter = (state: StateType, action: ActionType): StateType => {
  if (action.type === ActionTypeEnum.FLIP_ASSESSMENT_FILTER) {
    const assessmentFiltersCopy = { ...state.assessmentFilters };
    assessmentFiltersCopy[action.assessment] = !assessmentFiltersCopy[action.assessment];
    return {
      ...state,
      assessmentFilters: assessmentFiltersCopy,
    };
  }
  return state;
};

// Helper function for setting all assessment filters to either active or inactive depending on input
const setAllAssessmentFilters = (
  assessmentNames: string[],
  value: boolean,
): Record<string, boolean> => (
  assessmentNames.reduce((agg, name) => {
    const aggCopy = { ...agg };
    aggCopy[name] = value;
    return aggCopy;
  }, {} as Record<string, boolean>)
);

const replaceAssessmentFilters = (state: StateType, action: ActionType): StateType => {
  if (action.type === ActionTypeEnum.REPLACE_ASSESSMENT_FILTERS) {
    return {
      ...state,
      assessmentFilters: setAllAssessmentFilters(action.assessmentFilters, true),
    };
  }
  return state;
};

const applyAllAssessmentFilters = (state: StateType, action: ActionType): StateType => {
  if (action.type === ActionTypeEnum.APPLY_ALL_ASSESSMENT_FILTERS) {
    const assessmentFilters = setAllAssessmentFilters(Object.keys(state.assessmentFilters), true);
    return {
      ...state,
      assessmentFilters,
    };
  }
  return state;
};

const unapplyAllAssessmentFilters = (state: StateType, action: ActionType): StateType => {
  if (action.type === ActionTypeEnum.UNAPPLY_ALL_ASSESSMENT_FILTERS) {
    const assessmentFilters = setAllAssessmentFilters(Object.keys(state.assessmentFilters), false);
    return {
      ...state,
      assessmentFilters,
    };
  }
  return state;
};

const setPathBeingHovered = (state: StateType, action: ActionType): StateType => {
  if (action.type === ActionTypeEnum.SET_PATH_BEING_HOVERED) {
    return {
      ...state,
      pathBeingHovered: (state.pathBeingHovered !== action.value) ? action.value : null,
    };
  }
  return state;
};

const setCellSize = (state: StateType, action: ActionType): StateType => {
  if (action.type === ActionTypeEnum.SET_CELL_SIZE) {
    return {
      ...state,
      cellSize: action.value,
    };
  }
  return state;
};

// Helper function for creating 'assignments' out of assignment schedules, does so getting calendar info
// of the assignments availability. What day and week it starts and what day and week it ends.
const makeAssignments = (
  cellSize: number,
  numberOfAssignments: number,
  startTimeOffsetDays: number,
  visibleDurationDays: number,
  repeatFrequencyDays: number,
): LineType[][] => (
  Array(numberOfAssignments).fill(null).map((_, index): LineType[] => {
    const adjustedStartingPoint = (startTimeOffsetDays + (repeatFrequencyDays * index));
    const adjustedEndingPoint = adjustedStartingPoint + visibleDurationDays + 1;

    return range(adjustedStartingPoint, adjustedEndingPoint).map((day): LineType => {
      const startingX = day % 7;
      const startingY = Math.trunc(day / 7);

      const endingX = (day % 7) + 1;
      const endingY = Math.trunc(day / 7);

      return {
        start: {
          x: startingX * cellSize,
          y: startingY * cellSize,
        },
        end: {
          x: endingX * cellSize,
          y: endingY * cellSize,
        },
      };
    });
  })
);

// Helper function for going looping through grouped assignment schedules and creating assignment runs,
/// adding their assessment names, and defining a fill color
const generateSchedules = (state: StateType, action: ActionType): StateType => {
  if (action.type === ActionTypeEnum.GENERATE_SCHEDULES) {
    const generatedSchedules = Object.entries(action.assignmentSchedules).map((
      [name, assessmentAssignmentSchedules],
      index,
    ): ScheduleType => {
      const fillColor = state.colorPalette[index % state.colorPalette?.length];
      const schedules = assessmentAssignmentSchedules.flatMap((assignmentSchedule) => {
        const startTimeOffsetDays = assignmentSchedule?.startTimeOffsetDays || 0;
        const repeatFrequencyDays = assignmentSchedule?.repeatFrequencyDays || 0;
        const visibleDurationDays = assignmentSchedule?.visibleDurationDays || 0;
        const count = assignmentSchedule?.count || 1;

        return makeAssignments(state.cellSize, count, startTimeOffsetDays, visibleDurationDays, repeatFrequencyDays);
      });
      return {
        name,
        fillColor,
        schedules,
      };
    });
    return {
      ...state,
      generatedSchedules,
    };
  }
  return state;
};

const calculateCalendarHeight = (state: StateType, action: ActionType): StateType => {
  if (action.type === ActionTypeEnum.CALCULATE_CALENDAR_HEIGHT) {
    const calendarHeight = state.generatedSchedules.flatMap((generatedSchedule) => (
      generatedSchedule.schedules.map((schedule) => (
        schedule[schedule.length - 1].end.y
      ))
    )).reduce((max, curr) => (
      Math.max(max, curr)
    ), 0);

    return {
      ...state,
      calendarHeight: calendarHeight + state.cellSize,
    };
  }
  return state;
};

// Helper function that takes all the generated schedules and builds 'psuedo' svg groups containting the title
// element information, the rect's that contain the actual placement information, and attaches the functions for
// onMouseOver and onMouseLeave
const generateAssignmentPathInfoHelper = (
  assignments: ScheduleType[],
  assessmentFilters: Record<string, boolean>,
  handleHover: (key: string) => void,
): AssignmentPathInfo[] => (
  assignments.flatMap((assignment, index) => (
    assessmentFilters[assignment.name]
      ? assignment.schedules.map((scheduleLines, i) => ({
        key: `${assignment.name}-${+i}`,
        onMouseOver: () => handleHover(`${assignment.name}-${+i}`),
        onMouseLeave: () => handleHover(`${assignment.name}-${+i}`),
        title: assignment.name,
        rect: scheduleLines.map((scheduleLine) => (
          {
            key: `${assignment.name}-${+i}-${scheduleLine.start.x}-${scheduleLine.start.y}`,
            x: scheduleLine.start.x,
            y: scheduleLine.start.y + (index * 10) + 30,
            height: 10,
            width: scheduleLine.end.x - scheduleLine.start.x,
            fill: assignment.fillColor,
            fillOpacity: 0.75,
          }
        )),
      }))
      : []
  ))
);

const generateAssignmentPathInfo = (state: StateType, action: ActionType): StateType => {
  if (action.type === ActionTypeEnum.GENERATE_ASSIGNMENT_PATH_INFO) {
    const assignmentPathInfo = generateAssignmentPathInfoHelper(
      state.generatedSchedules,
      state.assessmentFilters,
      action.handleHover,
    );
    return {
      ...state,
      assignmentPathInfo,
    };
  }
  return state;
};

export const reducer = (state: StateType, action: ActionType): StateType => {
  switch (action.type) {
  case ActionTypeEnum.UPDATE_ACCOUNT_TYPE_FILTER:
    return updateAccountTypeFilter(state, action);
  case ActionTypeEnum.FLIP_ASSESSMENT_FILTER:
    return flipAssessmentFilter(state, action);
  case ActionTypeEnum.REPLACE_ASSESSMENT_FILTERS:
    return replaceAssessmentFilters(state, action);
  case ActionTypeEnum.APPLY_ALL_ASSESSMENT_FILTERS:
    return applyAllAssessmentFilters(state, action);
  case ActionTypeEnum.UNAPPLY_ALL_ASSESSMENT_FILTERS:
    return unapplyAllAssessmentFilters(state, action);
  case ActionTypeEnum.SET_PATH_BEING_HOVERED:
    return setPathBeingHovered(state, action);
  case ActionTypeEnum.SET_CELL_SIZE:
    return setCellSize(state, action);
  case ActionTypeEnum.GENERATE_SCHEDULES:
    return generateSchedules(state, action);
  case ActionTypeEnum.CALCULATE_CALENDAR_HEIGHT:
    return calculateCalendarHeight(state, action);
  case ActionTypeEnum.GENERATE_ASSIGNMENT_PATH_INFO:
    return generateAssignmentPathInfo(state, action);
  default:
    throw new Error('Unexpected State');
  }
};
