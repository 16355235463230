import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { Paper, Divider, Grid } from '@material-ui/core';
import {
  Link,
  LoadingSpinner,
  Box,
  Text,
} from 'react-limbix-ui';

import ResearchAssignment from '../ResearchAssignment';

import { formatDate } from '@/utils/dateUtils';
import {
  QueryAssignmentArgs, LimbixUserAccountType, UserType,
} from '@/apollo/types';
import { RESEARCH_ASSIGNMENT_QUERY } from '@/apollo/queries';
import { UseParamsType } from '@/types';
import { useQuery } from '@/hooks/apollo';
import { usePermissions } from '@/hooks/redux';

const Details: React.FC = () => {
  const { studyUid, assignmentUid } = useParams<UseParamsType>();
  const { loading, data, error } = useQuery<QueryAssignmentArgs>(RESEARCH_ASSIGNMENT_QUERY, {
    variables: {
      studyUid,
      assignmentUid,
    },
  });
  const [{ studyPermissions }] = usePermissions();
  const permissions = studyPermissions[studyUid];

  if (permissions?.isBlinded) {
    return (
      <Text as="h1">
        You are blinded for this study and are not able to view Research Assignments.
      </Text>
    );
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error?.message === 'Assignment matching that UID does not exist') {
    return <Redirect to="/research" />;
  }

  const { assignment } = data;

  const renderUser = (
    userUid: string,
    participantId?: string,
    accountType?: LimbixUserAccountType,
    userEmail?: string,
  ) => {
    if (accountType === LimbixUserAccountType.Research) {
      return userEmail;
    }
    if (!userUid) {
      return null;
    }

    const baseStudyUrl = `/research/study/${studyUid}`;
    const linkToPID = `${baseStudyUrl}/participant/${userUid}`;
    return <Link.RouterLink to={linkToPID} fontSize="16px">{participantId || 'None'}</Link.RouterLink>;
  };

  const selectUser = (
    lastUserToEdit: UserType,
    assignedUser: UserType,
  ) => {
    const user = lastUserToEdit || assignedUser;
    return renderUser(user?.uid, '', user?.accountType, user?.email);
  };

  return (
    <Box width="100%" display="flex" justifyContent="center">
      <Box width="100%" position="relative">
        <Paper elevation={2} style={{ width: '100%' }}>
          <Box width="100%" height="100%" padding="30px">
            <Text fontWeight="700" fontSize="22px">
              Assignment Details
            </Text>
            <Divider />
            <Box padding="15px">
              <Grid
                container
                justifyContent="space-between"
                alignItems="stretch"
                style={{ height: '100%' }}
              >
                <Grid item xs={3}>
                  <Box>
                    <Text fontWeight="700" fontSize="18px">
                      Assessment:
                    </Text>
                    <Text>
                      {assignment.assessment?.name || 'Adverse Event Questionnaire'}
                    </Text>
                  </Box>
                  <Box marginTop="20px">
                    <Text fontWeight="700" fontSize="18px">
                      Visible Date:
                    </Text>
                    <Text>
                      {formatDate(assignment.visibleDate)}
                    </Text>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box>
                    <Text fontWeight="700" fontSize="18px">
                      Status:
                    </Text>
                    <Text>
                      {assignment.status}
                    </Text>
                  </Box>
                  <Box marginTop="20px">
                    <Text fontWeight="700" fontSize="18px">
                      Due Date:
                    </Text>
                    <Text>
                      {formatDate(assignment.dueDate)}
                    </Text>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box>
                    <Text fontWeight="700" fontSize="18px">
                      User:
                    </Text>
                    <Text>
                      {selectUser(
                        assignment?.researchAssessmentResult?.lastUserToEdit,
                        assignment?.user,
                      )}
                    </Text>
                  </Box>
                  <Box marginTop="20px">
                    <Text fontWeight="700" fontSize="18px">
                      Start Time:
                    </Text>
                    <Text>
                      {formatDate(assignment.startTime)}
                    </Text>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box>
                    <Text fontWeight="700" fontSize="18px">
                      About User:
                    </Text>
                    <Text>
                      {renderUser(
                        assignment?.aboutStudyParticipant?.user?.uid,
                        assignment?.aboutStudyParticipant?.participantId,
                      )}
                    </Text>
                  </Box>
                  <Box marginTop="20px">
                    <Text fontWeight="700" fontSize="18px">
                      Complete Time:
                    </Text>
                    <Text>
                      {formatDate(assignment.completeTime)}
                    </Text>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <ResearchAssignment
              assignmentUid={assignmentUid}
              studyUid={studyUid}
            />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default Details;
