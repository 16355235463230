import styled from 'styled-components/macro';

const PaperDiv = styled.div`
  width: 100%;
  height: 100%;
  @media (max-width: 1279px) {
    padding: 10px;
  }
  @media (min-width: 1280px) {
    padding: 30px;
  }
`;

export default {
  PaperDiv,
};
