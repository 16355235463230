import { QuestionAndAnswer, SubAnswer } from '@/types';
import { AssessmentFormState } from '@/types/AssessmentTypes';
import {
  AnswerType,
  AssessmentResultItemType,
  AssignmentScheduleType,
  ParticipantAssessmentResultType,
  QuestionQuestionType,
  QuestionType,
} from '@/apollo/types';
import { AssignmentScheduleInputWithLockState } from '@/types/DataTypes';

const MASKED_VALUE_STRING = '***';

/** Prepare and return the assessment answers to be reported to the backend. */
export const getAssessmentAnswers = (state: AssessmentFormState):string[] => {
  const stateValues = Object.values(state);
  return stateValues.map((stateValue) => {
    const selectedAnswer = stateValue.answer;
    if (selectedAnswer === null || !selectedAnswer?.answers?.length) {
      return '';
    }

    switch (selectedAnswer.type) {
    case QuestionQuestionType.MultiSelect:
      return JSON.stringify(selectedAnswer.answers.map((answer) => answer.uid));
    case QuestionQuestionType.MultipleChoice:
      return selectedAnswer.answers[0].uid;
    case QuestionQuestionType.FreeFormText:
      return selectedAnswer.answers[0].text;
    case QuestionQuestionType.DateField:
      return selectedAnswer.answers[0].text;
    default:
      throw new Error(`Cannot get assessment answers. Got selected answer with unexpected type: ${selectedAnswer}`);
    }
  });
};

export const getAnswerUidToAnswerMap = (answers: AnswerType[]):{ [key: string]: SubAnswer} => (
  answers.reduce((accumulated, possibleAnswer) => (
    Object.assign(accumulated, {
      [possibleAnswer.uid]: {
        text: possibleAnswer.text,
        uid: possibleAnswer.uid,
      },
    })
  ), {} as Record<string, SubAnswer>)
);

export const matchAnswersToQuestions = (questions: QuestionType[], answerUids: string[]): QuestionAndAnswer[] => {
  if (questions.length !== answerUids.length) {
    throw new Error('Lengths do not match');
  }
  return questions.map((question, index): QuestionAndAnswer => {
    const selectedAnswer = answerUids[index];
    const questionText = question.text;
    const matchingAnswer = question.answers.find((answer) => answer.uid === selectedAnswer);
    const selectedAnswerValue = matchingAnswer ? `${matchingAnswer.assessmentValue}` : '-';
    const selectedAnswerText = matchingAnswer ? `${matchingAnswer.text}` : '';
    return {
      index,
      questionText,
      selectedAnswerValue,
      selectedAnswerText,
      expanded: false,
    };
  });
};

export const resultHasSkippedQuestions = (assessmentResult: ParticipantAssessmentResultType): boolean => {
  const answers = assessmentResult?.assessmentResultItems;
  const questions = assessmentResult?.assessment?.questions;
  return !answers || !questions || answers.length < questions.length;
};

export const getAssignmentScheduleInputsWithLockState = (
  assignmentSchedules: AssignmentScheduleType[],
) => assignmentSchedules.filter((assignmentSchedule) => (
  !!assignmentSchedule?.assessment?.uid
)).map((assignmentSchedule): AssignmentScheduleInputWithLockState => ({
  accountType: assignmentSchedule.accountType,
  assessmentUid: assignmentSchedule.assessment.uid,
  canEditAfterCompletion: assignmentSchedule.canEditAfterCompletion,
  canSkipQuestions: assignmentSchedule.canSkipQuestions,
  count: assignmentSchedule.count,
  ordinal: assignmentSchedule.ordinal,
  repeatFrequencyDays: assignmentSchedule.repeatFrequencyDays || 0,
  scheduleOnStudyState: assignmentSchedule.scheduleOnStudyState,
  sendPushNotifications: assignmentSchedule.sendPushNotifications,
  sendEmailNotifications: assignmentSchedule.sendEmailNotifications,
  sendSmsNotifications: assignmentSchedule.sendSmsNotifications,
  startTimeOffsetDays: assignmentSchedule.startTimeOffsetDays || 0,
  typeOfClientForAssignment: assignmentSchedule.typeOfClientForAssignment,
  typeOfNotificationForAssignment: assignmentSchedule.typeOfNotificationForAssignment,
  uid: assignmentSchedule.uid,
  visibleDurationDays: assignmentSchedule.visibleDurationDays || 0,
  isLocked: assignmentSchedule.isLocked,
}));

export const assessmentResultItemToAnswerString = (assessmentResultItem: AssessmentResultItemType): string => {
  if (!assessmentResultItem) {
    return '';
  }

  const {
    question,
    dateValue,
    textValue,
    selectedAnswers,
  } = assessmentResultItem;
  const { questionType, isSensitive } = question;

  if (isSensitive) {
    return MASKED_VALUE_STRING;
  }

  switch (questionType) {
  case QuestionQuestionType.FreeFormText:
    return textValue?.text;
  case QuestionQuestionType.DateField:
    return dateValue ? new Date(dateValue).toISOString() : '';
  case QuestionQuestionType.MultipleChoice:
    return selectedAnswers.length > 0 ? selectedAnswers[0].text : '';
  case QuestionQuestionType.MultiSelect:
    return selectedAnswers.map((answer) => answer.text).join(', ');
  default:
    return '';
  }
};
