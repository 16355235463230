import React from 'react';
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import Styled from './BreadCrumbs.styles';

type Props = {
  breadcrumbs: {
    name: string,
    path: string,
  }[];
};

const BreadCrumbs: React.FC<Props> = (props: Props) => {
  const { breadcrumbs } = props;

  return (
    <Styled.BreadCrumbs>
      {breadcrumbs.map((breadcrumb, index) => (
        <Link
          component={RouterLink}
          key={breadcrumb.name}
          to={breadcrumb.path}
          color={index === breadcrumbs.length - 1 ? 'textPrimary' : 'inherit'}
        >
          {breadcrumb.name}
        </Link>
      ))}
    </Styled.BreadCrumbs>
  );
};

export default BreadCrumbs;
