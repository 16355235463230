import React, { useCallback, useState } from 'react';
import {
  PaginatedTable,
  Link,
  Column,
  Row,
  Box,
  createCell,
  createColumn,
  calculateNewSortState,
} from 'react-limbix-ui';
import { Button } from '@material-ui/core';

import StudyActions from './StudyActions';
import AdminActions from './AdminActions';
import MobileAccess from './MobileAccess';

import { StudyParticipantType } from '@/apollo/types';
import {
  StudyPermission,
  MutationResultType,
} from '@/types';
import { SearchBar } from '@/components';
import {
  renderPID,
} from '@/utils/tableUtils';
import { useModal } from '@/hooks/redux';
import CaseReportFormDownloader from '@/components/CaseReportFormDownloader';
import { LANGUAGE_MAP } from '@/utils/constants';
import { PaginationState, UpdatePaginationStateType } from '@/hooks/usePagination';
import { UnknownAsyncCallback } from '@/types/DataTypes';

type Props = {
  studyPermissions: StudyPermission;
  studyUid: string;
  studyParticipants?: StudyParticipantType[];
  resetAccessAttempts?: (userUid: string) => Promise<MutationResultType>;
  generateCRF?: (userUid: string) => Promise<MutationResultType>;
  totalCount: number;
  onTableUpdate?: () => void;
  paginationState: PaginationState;
  updatePaginationState: UpdatePaginationStateType;
  loading?: boolean;
  onRefetch?: UnknownAsyncCallback;
};
const ParticipantTable: React.FC<Props> = (props: Props) => {
  const {
    studyParticipants,
    studyPermissions,
    resetAccessAttempts,
    generateCRF,
    studyUid,
    totalCount,
    onTableUpdate,
    loading,
    paginationState,
    updatePaginationState,
    onRefetch,
  } = props;

  const { sortState, itemsPerPage, currentPage } = paginationState;
  const { showModal } = useModal();
  const [searchString, setSearchString] = useState('');

  const onSearchFilterSubmit = useCallback(() => updatePaginationState({
    searchFilterState: {
      user__email: searchString,
      participant_id: searchString,
    },
  }), [searchString]);

  const generateCRFsLoadingDefault: { [key: string]: boolean } = {};
  studyParticipants.forEach((participant) => {
    generateCRFsLoadingDefault[participant.user.uid] = false;
  });

  const renderAccountType = (studyParticipant: StudyParticipantType) => (
    studyParticipant.user.accountType !== 'CHILD' ? studyParticipant.user.accountType : (
      <div>
        <div>
          {studyParticipant.user.accountType}
        </div>
        {studyPermissions.canModifyParticipants && (
          <Link.ButtonLink
            onClick={
              () => showModal(
                'TRANSITION_TO_ADULT',
                { studyParticipant, onRefetch },
              )
            }
          >
            Transition to Adult
          </Link.ButtonLink>
        )}
      </div>
    )
  );

  const renderFailedAttempts = (studyParticipant: StudyParticipantType) => (
    !studyParticipant.user.accessAttempts ? '0' : (
      <div>
        {`${studyParticipant.user.accessAttempts} `}
        {studyPermissions.canModifyParticipants && (
          <Link.ButtonLink
            onClick={
              () => (
                resetAccessAttempts(studyParticipant.user.uid)
              )
            }
          >
            (reset)
          </Link.ButtonLink>
        )}
      </div>
    )
  );

  const renderStudyActions = (studyParticipant: StudyParticipantType) => (
    <StudyActions participant={studyParticipant} onRefetch={onRefetch} />
  );

  const renderAdminActions = (studyParticipant: StudyParticipantType) => (
    <AdminActions participant={studyParticipant} onRefetch={onRefetch} />
  );

  const renderMobileAccess = (studyParticipant: StudyParticipantType) => (
    <MobileAccess
      participant={studyParticipant}
      studyPermissions={studyPermissions}
    />
  );

  const renderRemove = (studyParticipant: StudyParticipantType) => (
    <Link.ButtonLink
      onClick={
        () => showModal(
          'DELETE_PARTICIPANT',
          {
            studyParticipant,
            onRefetch,
          },
        )
      }
    >
      Delete
    </Link.ButtonLink>
  );

  const renderNotes = (studyParticipant: StudyParticipantType) => (
    <Link.ButtonLink
      onClick={
        () => showModal(
          'PARTICIPANT_NOTES',
          {
            studyParticipant,
            canModifyParticipants: studyPermissions.canModifyParticipants,
            onRefetch,
          },
        )
      }
    >
      view
    </Link.ButtonLink>
  );

  const renderDownloadCRF = (studyParticipant: StudyParticipantType) => (
    <CaseReportFormDownloader
      studyParticipantUserUid={studyParticipant.user.uid}
      studyUid={studyUid}
    />
  );

  const columns: Column[] = [
    createColumn('pid', 'PID', 'string', null, true),
    createColumn('accountType', 'Account Type', 'string', null, true),
    createColumn('email', 'Email', 'string', null, true),
    createColumn('emailConfirmed', 'Email Confirmed', 'string', null, true),
    createColumn('failedAttempts', 'Failed Attempts', 'number', null, false),
    createColumn('status', 'Status', 'string', null, false),
    createColumn('language', 'Language', 'string', null, true),
  ];
  // Hide study arm if blinded.
  if (!studyPermissions.isBlinded) {
    columns.push(createColumn('studyArm', 'Study Arm', 'string', null, true));
  }

  if (generateCRF) {
    columns.push(createColumn('crf', 'CRF', 'string', null, false));
  }

  if (studyPermissions.canModifyParticipants) {
    columns.push(createColumn('studyActions', 'Study Actions', 'string', null, false));
    columns.push(createColumn('adminActions', 'Admin Actions', 'string', null, false));
  }
  columns.push(createColumn('mobileAccess', 'Mobile Access', 'string', null, false));
  if (studyPermissions.canDeleteParticipants) {
    columns.push(createColumn('remove', 'Remove', 'string', null, false));
  }
  columns.push(createColumn('notes', 'Notes', 'string', null, false));

  const rows: Row[] = studyParticipants
    .map((participant) => {
      const row: Row = {
        id: participant.user.uid,
        cells: [
          createCell(
            'pid',
            participant.participantId,
            renderPID(studyUid, participant.user.uid, participant.participantId),
          ),
          createCell('accountType', participant.user.accountType, renderAccountType(participant)),
          createCell('email', participant.user.email, participant.user.email || 'None'),
          createCell(
            'emailConfirmed',
            `${participant.user.emailVerified}`,
          ),
          createCell(
            'failedAttempts',
            participant.user.accessAttempts,
            renderFailedAttempts(participant),
          ),
          createCell('status', participant.status),
          createCell('language', LANGUAGE_MAP[participant.user.language]),
        ],
      };
      if (!studyPermissions.isBlinded) {
        row.cells.push(createCell('studyArm', participant?.studyArm?.name, participant?.studyArm?.name || 'None'));
      }
      if (generateCRF) {
        row.cells.push(createCell('crf', renderDownloadCRF(participant)));
      }
      if (studyPermissions.canModifyParticipants) {
        row.cells.push(createCell('studyActions', renderStudyActions(participant)));
        row.cells.push(createCell('adminActions', renderAdminActions(participant)));
      }
      row.cells.push(createCell('mobileAccess', renderMobileAccess(participant)));
      if (studyPermissions.canDeleteParticipants) {
        row.cells.push(createCell('remove', renderRemove(participant)));
      }
      row.cells.push(createCell('notes', renderNotes(participant)));
      return row;
    });

  const handleSortByColumn = useCallback(async (colId: string) => {
    const newSortState = calculateNewSortState(colId, sortState);
    updatePaginationState({ sortState: newSortState });
  }, [sortState.columnId, sortState.direction]);

  return (
    <Box>
      <Box marginBottom="20px" float="right" display="flex" flexDirection="row">
        <SearchBar
          placeholder="Filter Participants"
          value={searchString}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => (
            setSearchString(event.currentTarget.value?.toLowerCase() || '')
          )}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={onSearchFilterSubmit}
        >
          Filter
        </Button>
      </Box>
      <PaginatedTable
        columns={columns}
        rows={rows}
        ariaLabel="manage_participants"
        sortState={sortState}
        onSortByColumn={handleSortByColumn}
        currentPage={currentPage}
        onGoToPage={(pageNum) => updatePaginationState({ currentPage: pageNum })}
        itemsPerPage={itemsPerPage}
        totalCount={totalCount}
        onTableUpdate={onTableUpdate}
        loading={loading}
        skipRowSort
      />
    </Box>
  );
};

export default ParticipantTable;
