import {
  OperationVariables,
  DocumentNode,
  useLazyQuery as apolloUseLazyQuery,
} from '@apollo/client';

import { Query } from '@/apollo/types';
import { QueryHookOptions, QueryTuple } from '@/types';

const useLazyQuery = <T extends OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<T | OperationVariables>,
): QueryTuple<T | OperationVariables> => apolloUseLazyQuery<Query, T | OperationVariables>(query, options);

export default useLazyQuery;
