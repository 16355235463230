import React from 'react';
import { Switch } from '@material-ui/core';
import { Memo, Box, Text } from 'react-limbix-ui';
import isEqual from 'react-fast-compare';

import { Tag } from '@/components';
import { CheckedChangeEvent } from '@/types';

type AssessmentSwitchProps = {
  checked: boolean,
  assessmentName: string,
  onSwitchFlip: ((event: CheckedChangeEvent) => void) | (() => void),
  tagColor?: string,
}
const AssessmentSwitch: React.FC<AssessmentSwitchProps> = (props: AssessmentSwitchProps) => {
  const {
    checked,
    assessmentName,
    onSwitchFlip,
    tagColor,
  } = props;

  return (
    <Box display="block">
      <Box display="inline-flex" alignItems="center">
        <Switch
          checked={checked}
          onChange={onSwitchFlip}
          color="primary"
          name={assessmentName}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
        {tagColor && (
          <Box display="inline-flex">
            <Tag
              bgcolor={tagColor}
              height="14px"
              width="14px"
              borderRadius="7px"
              margin="0 5px 0 0"
            />
          </Box>
        )}
        <Box display="inline-flex">
          <Text>
            {assessmentName}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default Memo(AssessmentSwitch, isEqual);
