import {
  Typography as MuiTypography,
} from '@material-ui/core';
import styled from 'styled-components/macro';

const QuestionTextCollapsed = styled(MuiTypography)`
  font-family: Proxima Nova;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;
  word-break: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
`;

const QuestionTextExpanded = styled(QuestionTextCollapsed)`
  font-family: Proxima Nova;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;
  word-break: normal;
  overflow: visible;
  white-space: normal;
  height: auto;
`;

export default {
  QuestionTextCollapsed,
  QuestionTextExpanded,
};
