import React from 'react';

import Styled from '../Modal.styles';

import { ModalPayloadType } from '@/types/ReduxTypes';
import { renderIdentifierLabel } from '@/utils/tableUtils';
import { formatPractitionerName } from '@/utils/stringUtils';

type Props = {
  payload: ModalPayloadType['PRACTITIONER_IDS'];
  onClose: () => void;
};

const PractitionerIds: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;

  const { practitioner } = payload;

  return (
    <div style={{ width: '416px', height: '475px' }}>
      <Styled.DialogContent>
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ width: '100%' }}>
            <Styled.HeaderText style={{ marginBottom: 5 }}>
              Practitioner Identifiers
            </Styled.HeaderText>
            <Styled.BodyText style={{ marginBottom: '5%' }}>
              Practitioner:
              {` ${formatPractitionerName(practitioner)}`}
            </Styled.BodyText>
            <Styled.BodyText>
              {practitioner.identifiers.map(renderIdentifierLabel)}
            </Styled.BodyText>
          </div>
          <Styled.ButtonContainer>
            <Styled.NoButton onClick={onClose}>
              <Styled.NoButtonText>
                Close
              </Styled.NoButtonText>
            </Styled.NoButton>
          </Styled.ButtonContainer>
        </div>
      </Styled.DialogContent>
    </div>
  );
};

export default PractitionerIds;
