import {
  Typography as MuiTypography,
} from '@material-ui/core';
import styled from 'styled-components/macro';

const Container = styled.div`
  width: 100%;
  min-height: calc(50% - 30px);
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
`;

const ElevatedCard = styled.div`
  width: 100%;
  min-height: 100%;
  padding: 24px 32px;
  box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.06),
              4px -4px 8px rgba(0, 0, 0, 0.06),
              -4px 4px 8px rgba(0, 0, 0, 0.06),
              4px 4px 8px rgba(0, 0, 0, 0.06);
`;

const TextGroupContainer = styled.div<{ width: string, paddingright?: string }>`
  width: ${(props) => props.width};
  padding-right: ${(props) => props.paddingright || '0px'};
`;

const BoldText = styled(MuiTypography)<{ marginbottom?: string }>`
  font-family: Proxima Nova;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: ${(props) => (props?.marginbottom ? props.marginbottom : '0px')};
`;

const Text = styled(MuiTypography)<{ marginbottom: string, marginleft?: string }>`
  font-family: Proxima Nova;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: ${(props) => props.marginbottom};
  margin-left: ${(props) => (props?.marginleft ? props.marginleft : '0px')};
`;

const StudyInfoContainer = styled.div`
  width 40%;
  display: flex;
`;

const ParticipantInfoContainer = styled.div`
  width 50%;
  margin-left: 30px;
  display: block;
`;

const StudyPersonnelContainer = styled.div<{ marginleft?: string }>`
  width 30%;
  display: block;
  margin-left: ${(props) => (props?.marginleft ? props.marginleft : '0px')};
`;

export default {
  Container,
  ElevatedCard,
  StudyInfoContainer,
  ParticipantInfoContainer,
  TextGroupContainer,
  StudyPersonnelContainer,
  Text,
  BoldText,
};
