import React, { useContext, useState } from 'react';
import { TextField, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { LoadingSpinner } from 'react-limbix-ui';

import Styled from '../Modal.styles';

import { MutationEditParticipantDataArgs } from '@/apollo/types';
import { MutationResultType, GraphQLErrorsType } from '@/types';
import { EDIT_PARTICIPANT_NOTES } from '@/apollo/mutations';
import { ModalPayloadType } from '@/types/ReduxTypes';
import { useMutation } from '@/hooks/apollo';
import { ModalLoadingContext } from '@/components/Modal/Modal';

type Props = {
  payload: ModalPayloadType['PARTICIPANT_NOTES'];
  onClose: () => void;
};

const ParticipantNotes: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;

  const {
    studyParticipant,
    canModifyParticipants,
    onRefetch,
  } = payload;

  const inputRef = React.useRef<HTMLInputElement>();
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [participantNotes, setParticipantNotes] = useState(studyParticipant.notes);
  const [changeReasonValue, setChangeReasonValue] = useState<string>();
  const [errors, setErrors] = useState<GraphQLErrorsType>([]);
  const { modalLoading, setModalLoading } = useContext(ModalLoadingContext);

  if (isEditingNotes && participantNotes === null) {
    setParticipantNotes('');
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Tab') {
      event.preventDefault();
    }
  };

  const abandonChanges = () => {
    setParticipantNotes(studyParticipant.notes);
    setIsEditingNotes(false);
    setChangeReasonValue(null);
  };

  const [editParticipantNotesMutation] = useMutation<MutationEditParticipantDataArgs>(
    EDIT_PARTICIPANT_NOTES,
  );
  const editParticipantNotes = async (notes: string, changeReason: string): Promise<MutationResultType> => {
    setModalLoading(true);
    const result = await editParticipantNotesMutation({
      variables: {
        userUid: studyParticipant.user.uid,
        studyUid: studyParticipant.study.uid,
        notes,
        changeReason,
      },
    });
    if (onRefetch) {
      await onRefetch();
    }
    setModalLoading(false);
    return result;
  };

  const updateNotes = async () => {
    const { errors: resultErrors } = await editParticipantNotes(participantNotes, changeReasonValue);
    if (resultErrors) {
      setErrors(resultErrors);
    } else {
      onClose();
    }
  };

  const viewNotes = (
    <div style={{ display: 'flex', width: '100%' }}>
      <div style={{ width: '100%' }}>
        <Styled.HeaderText style={{ marginBottom: 5 }}>
          View Participant Notes
        </Styled.HeaderText>
        <Styled.BodyText>
          Participant:
          {` ${studyParticipant.participantId}`}
        </Styled.BodyText>
        <TextField
          id="standard-multiline-flexible"
          variant="outlined"
          label=""
          multiline
          maxRows={10}
          minRows={10}
          value={participantNotes || 'None'}
          fullWidth
        />
      </div>
      <Styled.ButtonContainer>
        <Button
          variant="contained"
          onClick={onClose}
          style={{ marginRight: 15 }}
        >
          Close
        </Button>
        {canModifyParticipants && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            onClick={() => setIsEditingNotes(true)}
          >
            Edit Notes
          </Button>
        )}
      </Styled.ButtonContainer>
    </div>
  );

  const editNotes = (
    <div style={{ display: 'flex', width: '100%' }}>
      <div style={{ width: '100%' }}>
        <Styled.HeaderText style={{ marginBottom: 5 }}>
          Edit Participant Notes
        </Styled.HeaderText>
        <Styled.BodyText>
          Participant:
          {` ${studyParticipant.participantId}`}
        </Styled.BodyText>
        <TextField
          id="standard-multiline-flexible"
          variant="outlined"
          label=""
          multiline
          maxRows={10}
          minRows={10}
          value={participantNotes}
          inputProps={{ ref: inputRef }}
          onKeyDown={handleKeyPress}
          fullWidth
          onChange={(event) => setParticipantNotes(event.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="change reason"
          label="Change Reason"
          id="change reason"
          onChange={(event) => setChangeReasonValue(event.target.value?.trim())}
        />
      </div>
      {errors.map((error) => (
        <div key={error.message} style={{ color: 'red' }}>
          {error.message}
        </div>
      ))}
      <Styled.ButtonContainer>
        <Button
          onClick={abandonChanges}
          variant="contained"
          style={{ marginRight: 15 }}
        >
          <Styled.NoButtonText>
            Abandon Changes
          </Styled.NoButtonText>
        </Button>
        <Styled.YesButton onClick={updateNotes} disabled={!changeReasonValue}>
          <Styled.YesButtonText>
            Update Notes
          </Styled.YesButtonText>
        </Styled.YesButton>
      </Styled.ButtonContainer>
    </div>
  );

  if (modalLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div style={{ width: '416px', height: isEditingNotes ? '475px' : '375px' }}>
      <Styled.DialogContent>
        {isEditingNotes ? editNotes : viewNotes}
      </Styled.DialogContent>
    </div>
  );
};

export default ParticipantNotes;
