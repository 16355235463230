/* eslint-disable */
import { configureStore } from '@reduxjs/toolkit'

import rootReducer from './slices';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    // It is generally not recommended to pass functions to redux state but we need to for the modal system
    serializableCheck: false
  }),
});
export type RootState = ReturnType<typeof store.getState>;
export default store;
