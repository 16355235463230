import { gql } from '@apollo/client';

import useMutation from '../useMutation';

import { MutationTokenAuthMfaArgs } from '@/apollo/types';
import { MutationHookOptions, MutationFunction } from '@/types';

const useLoginMutation = (
  options?: MutationHookOptions<MutationTokenAuthMfaArgs>,
): MutationFunction<MutationTokenAuthMfaArgs> => {
  const REQUEST_TOKEN_MUTATION = gql`
    mutation RequestToken($email: String!, $password: String!, $clientType: String!, $mfaCode: String) {
      tokenAuthMfa(email: $email, password: $password, clientType: $clientType, mfaCode: $mfaCode) {
        token,
        refreshToken,
        user {
          uid
          email
          isResearch
          isOperations
        }
      }
    }
  `;
  const [requestToken] = useMutation<MutationTokenAuthMfaArgs>(
    REQUEST_TOKEN_MUTATION,
    options,
  );
  return requestToken;
};

export default useLoginMutation;
