import React from 'react';
import { Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import Styled from './ModalTitle.styles';

export interface ModalTitleProps {
  children: React.ReactNode;
  onClose: () => void;
}

const ModalTitle: React.FC<ModalTitleProps> = ((props: ModalTitleProps) => {
  const { children, onClose } = props;
  return (
    <Styled.DialogTitle disableTypography>
      <Typography variant="h6" style={{ display: 'flex' }}>{children}</Typography>
      {onClose ? (
        <Styled.IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </Styled.IconButton>
      ) : null}
    </Styled.DialogTitle>
  );
});

export default ModalTitle;
