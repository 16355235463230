/* eslint-disable no-underscore-dangle */
import React, { useReducer, useCallback, useState } from 'react';
import { Button, Paper } from '@material-ui/core';
import { Box } from 'react-limbix-ui';

import {
  ConditionType,
  AssessmentAnswerType,
  AssessmentQuestionType,
  StateType,
  AssessmentField,
  QuestionField,
  ActionTypeEnum,
  TextTranslation,
} from './AssessmentEditor.types';
import {
  ErrorMessage,
  AssessmentHeader,
  AssessmentFieldTextInput,
  QuestionHeader,
  SetQuestionType,
  EditQuestionTextField,
  EditQuestionProperty,
  AnswerHeader,
  Answer,
  ConditionHeader,
  Condition,
  AssessmentFooter,
  IsArchivedSwitch,
} from './AssessmentEditor.components';
import { blankTranslation, reducer } from './AssessmentEditor.reducer';
import TranslationEditor from './TranslationsEditor/TranslationsEditor';

import {
  AssessmentType,
  AssessmentInputType,
  QuestionQuestionType,
  TextTranslationType,
} from '@/apollo/types';
import { UnknownChangeEvent, HTMLInputChangeEvent } from '@/types';

const initialState = {
  uid: '',
  name: '',
  nameInternal: '',
  contextText: '',
  introText: '',
  preQuestionText: '',
  isArchived: false,
  questions: [] as AssessmentQuestionType[],
  nameTranslations: blankTranslation,
  contextTextTranslations: blankTranslation,
  introTextTranslations: blankTranslation,
  preQuestionTextTranslations: blankTranslation,
} as StateType;

const shouldHaveAnswers = (questionType: QuestionQuestionType): boolean => (
  questionType === QuestionQuestionType.MultiSelect || questionType === QuestionQuestionType.MultipleChoice
);

const initializeTranslation = (textTranslation: TextTranslationType): TextTranslation => {
  if (!textTranslation) {
    return blankTranslation;
  }
  const copy = {
    ...textTranslation,
  };

  // Remove typename from translations because they cause mutations to fail when we attempt to mutate assessment data.
  delete copy?.__typename;
  return copy;
};

export const convertAssessmentToState = (assessment: AssessmentType): StateType => {
  const questions: AssessmentQuestionType[] = assessment.questions.map((question) => {
    let answers: AssessmentAnswerType[] = null;
    if (shouldHaveAnswers(question.questionType)) {
      answers = question?.answers?.length > 0 ? question?.answers?.map((answer) => ({
        uid: answer.uid,
        text: answer.text,
        textTranslations: initializeTranslation(answer.textTranslations),
        assessmentValue: answer.assessmentValue || 0,
      })) : [];
    }
    const conditions: ConditionType[] = question.conditions?.map((condition) => {
      const questionIndex = assessment.questions
        .findIndex((_question) => _question.uid === condition.dependsOnQuestion.uid);
      const answerIndex = (assessment.questions[questionIndex]?.answers || [])
        .findIndex((_answer) => _answer.uid === condition.dependsOnAnswer.uid);
      return {
        question: questionIndex,
        answer: answerIndex,
        isNegated: condition.isNegated,
      };
    }) || [];

    return {
      text: question.text || '',
      textTranslations: initializeTranslation(question.textTranslations),
      answers,
      conditions,
      questionType: question.questionType,
      isOptional: question.isOptional,
      isSensitive: question.isSensitive,
      keyString: question.keyString,
    };
  });

  return {
    uid: assessment.uid || '',
    name: assessment.name || '',
    nameInternal: assessment.nameInternal || '',
    contextText: assessment.contextText || '',
    introText: assessment.introText || '',
    preQuestionText: assessment.preQuestionText || '',
    isArchived: assessment.isArchived,
    isLocked: assessment.isLocked,
    questions,
    contextTextTranslations: initializeTranslation(assessment.contextTextTranslations),
    preQuestionTextTranslations: initializeTranslation(assessment.preQuestionTextTranslations),
    introTextTranslations: initializeTranslation(assessment.introTextTranslations),
    nameTranslations: initializeTranslation(assessment.nameTranslations),
  };
};

const prepStateForSaving = (assessmentState: StateType): AssessmentInputType => {
  const assessmentStateCopy = { ...assessmentState };
  delete assessmentStateCopy.isLocked;
  delete assessmentStateCopy.error;
  delete assessmentStateCopy.hasErrors;

  const questions = assessmentStateCopy.questions.map((question) => {
    const questionCopy = {
      ...question,
      text: question.textTranslations,
    };
    delete questionCopy.error;
    delete questionCopy.textTranslations;

    const answers = questionCopy?.answers?.map((answer) => {
      const answerCopy = {
        ...answer,
        assessmentValue: answer.assessmentValue ?? 0,
        text: answer.textTranslations,
      };
      delete answerCopy.error;
      delete answerCopy.textTranslations;
      return answerCopy;
    });

    const conditions = questionCopy.conditions.map((condition) => {
      const conditionCopy = { ...condition };
      delete conditionCopy.error;
      const answerIndex = parseInt(`${conditionCopy.answer}`, 10);
      const answer = assessmentStateCopy.questions[conditionCopy.question].answers[answerIndex].text;
      return {
        ...conditionCopy,
        answer,
      };
    });

    return {
      ...questionCopy,
      answers,
      conditions,
    };
  });

  delete assessmentStateCopy.nameTranslations;
  delete assessmentStateCopy.contextTextTranslations;
  delete assessmentStateCopy.introTextTranslations;
  delete assessmentStateCopy.preQuestionTextTranslations;

  return {
    ...assessmentStateCopy,
    name: assessmentState.nameTranslations,
    contextText: assessmentState.contextTextTranslations,
    introText: assessmentState.introTextTranslations,
    preQuestionText: assessmentState.preQuestionTextTranslations,
    questions,
  };
};

type Props = {
  onSave: (assessmentData: AssessmentInputType) => Promise<void>;
  saving?: boolean;
  assessment?: AssessmentType;
  isLocked?: boolean;
};
const AssessmentEditor: React.FC<Props> = (props: Props) => {
  const {
    onSave,
    assessment,
    saving,
    isLocked,
  } = props;
  const memoizedState = React.useMemo(() => (
    assessment && convertAssessmentToState(assessment)
  ), [assessment, saving]);
  const [state, dispatch] = useReducer(reducer, assessment ? memoizedState : initialState);
  const [editingTranslations, setEditingTranslations] = useState(false);

  const handleEditAssessmentField = useCallback((event: HTMLInputChangeEvent, field: AssessmentField) => (
    dispatch({
      field,
      type: ActionTypeEnum.EDIT_ASSESSMENT_FIELD,
      value: event.target.value,
    })
  ), []);

  const handleFlipArchivedSwitch = useCallback((checked: boolean) => (
    dispatch({
      field: 'isArchived',
      type: ActionTypeEnum.EDIT_ASSESSMENT_FIELD,
      value: checked,
    })
  ), []);

  const handleSelectQuestionType = useCallback((event: UnknownChangeEvent, questionIndex: number) => (
    dispatch({
      questionIndex,
      type: ActionTypeEnum.EDIT_QUESTION,
      field: 'questionType',
      value: event.target.value as string,
    })
  ), []);

  const handleChangeQuestionText = useCallback((event: HTMLInputChangeEvent, questionIndex: number) => (
    dispatch({
      questionIndex,
      type: ActionTypeEnum.EDIT_QUESTION,
      field: 'text',
      value: event.target.value,
    })
  ), []);

  const handleChangeQuestionKeyString = useCallback((event: HTMLInputChangeEvent, questionIndex: number) => (
    dispatch({
      questionIndex,
      type: ActionTypeEnum.EDIT_QUESTION,
      field: 'keyString',
      value: event.target.value,
    })
  ), []);

  const handleFlipSwitch = useCallback((checked: boolean, questionIndex: number, field: QuestionField) => (
    dispatch({
      questionIndex,
      field,
      type: ActionTypeEnum.EDIT_QUESTION,
      value: checked,
    })
  ), []);

  const handleSelectConditionQuestion = useCallback((
    event: UnknownChangeEvent,
    conditionIndex: number,
    questionIndex: number,
  ) => (
    dispatch({
      questionIndex,
      conditionIndex,
      type: ActionTypeEnum.EDIT_CONDITION,
      field: 'question',
      value: parseInt(`${event.target.value}`, 10),
    })
  ), []);

  const handleSelectConditionAnswer = useCallback((
    event: UnknownChangeEvent,
    conditionIndex: number,
    questionIndex: number,
  ) => (
    dispatch({
      questionIndex,
      conditionIndex,
      type: ActionTypeEnum.EDIT_CONDITION,
      field: 'answer',
      value: parseInt(`${event.target.value}`, 10),
    })
  ), []);

  const handleSelectConditionNegation = useCallback((
    event: UnknownChangeEvent,
    conditionIndex: number,
    questionIndex: number,
  ) => (
    dispatch({
      questionIndex,
      conditionIndex,
      type: ActionTypeEnum.EDIT_CONDITION,
      field: 'isNegated',
      value: event.target.value === 'true',
    })
  ), []);

  const handleClickAnswerHeaderButton = useCallback((
    questionIndex: number,
    type: ActionTypeEnum.ADD_ANSWER | ActionTypeEnum.REMOVE_ANSWER,
  ) => (
    dispatch({ type, questionIndex })
  ), []);

  const handleChangeAnswerText = useCallback((
    event: HTMLInputChangeEvent,
    questionIndex: number,
    answerIndex: number,
  ) => (
    dispatch({
      type: ActionTypeEnum.EDIT_ANSWER_TEXT,
      value: event.target.value,
      questionIndex,
      answerIndex,
    })
  ), []);

  const handleChangeAnswerAssessmentValue = useCallback((
    event: HTMLInputChangeEvent,
    questionIndex: number,
    answerIndex: number,
  ) => {
    const assessmentValue = event?.target?.value ? parseInt(event.target.value, 10) : null;
    dispatch({
      type: ActionTypeEnum.EDIT_ANSWER_ASSESSMENT_VALUE,
      value: assessmentValue,
      questionIndex,
      answerIndex,
    });
  }, []);

  const handleClickConditionHeaderButton = useCallback((
    questionIndex: number,
    type: ActionTypeEnum.ADD_CONDITION | ActionTypeEnum.REMOVE_CONDITION,
  ) => (
    dispatch({ type, questionIndex })
  ), []);

  const handleClickRemoveQuestion = useCallback((questionIndex: number) => (
    dispatch({ type: ActionTypeEnum.REMOVE_QUESTION, questionIndex })
  ), []);

  const handleClickMoveQuestion = useCallback((direction: 'up' | 'down', questionIndex: number) => {
    dispatch({ type: ActionTypeEnum.MOVE_QUESTION, questionIndex, direction });
  }, []);

  const getSelectableConditionQuestions = (questionIndex: number) => (
    Array.from(state.questions.entries())
      .filter(([index, question]) => (
        index < questionIndex
        && (question.questionType === 'MULTIPLE_CHOICE' || question.questionType === 'MULTI_SELECT')
      ))
      .map(([index, question]) => ({ index, question }))
  );

  const getSelectableConditionAnswers = (conditionQuestionIndex: number) => {
    if (!conditionQuestionIndex && conditionQuestionIndex !== 0) {
      return [];
    }
    return Array.from(
      state.questions[conditionQuestionIndex].answers.entries(),
    ).map(([index, answer]) => ({ index, answer: answer?.text }));
  };

  const handleUploadFile = useCallback(async (file: File) => {
    const uploadedAssessment: StateType = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(JSON.parse(reader.result as string));
      };
      reader.onerror = reject;
      reader.readAsText(file);
    });
    dispatch({ uploadedAssessment, type: ActionTypeEnum.UPLOAD_ASSESSMENT });
  }, []);

  const stateToUriJson = () => `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(state))}`;

  const onAddQuestion = useCallback(() => (
    dispatch({ type: ActionTypeEnum.ADD_QUESTION })
  ), []);

  const onSaveAssessment = () => dispatch({
    onSave: () => onSave(prepStateForSaving(state)),
    type: ActionTypeEnum.SAVE,
  });

  const translationsEditor = (
    <Box>
      <TranslationEditor
        assessmentEditorState={state}
        dispatch={dispatch}
      />
    </Box>
  );

  const assessmentLogicEditor = (
    <Box display="block">
      <IsArchivedSwitch
        value={state.isArchived}
        label="Archived"
        onFlipSwitch={handleFlipArchivedSwitch}
      />
      <AssessmentFieldTextInput
        field="name"
        label="Assessment Name"
        fieldValue={state.name}
        onEditAssessmentField={handleEditAssessmentField}
      />
      <AssessmentFieldTextInput
        field="nameInternal"
        label="Assessment Name (Internal)"
        fieldValue={state.nameInternal}
        onEditAssessmentField={handleEditAssessmentField}
      />
      <AssessmentFieldTextInput
        field="contextText"
        label="Assessment Context"
        fieldValue={state.contextText}
        onEditAssessmentField={handleEditAssessmentField}
      />
      <AssessmentFieldTextInput
        field="introText"
        label="Intro Text"
        fieldValue={state.introText}
        onEditAssessmentField={handleEditAssessmentField}
      />
      <AssessmentFieldTextInput
        field="preQuestionText"
        label="Pre-Question Text"
        fieldValue={state.preQuestionText}
        onEditAssessmentField={handleEditAssessmentField}
      />
      <ErrorMessage error={state.error} />
      {state.questions.map((question, questionIndex) => (
        <Box key={+questionIndex} id={`question-${questionIndex}`}>
          <QuestionHeader
            questionIndex={questionIndex}
            onClickRemove={() => handleClickRemoveQuestion(questionIndex)}
            onClickMoveUp={() => handleClickMoveQuestion('up', questionIndex)}
            onClickMoveDown={() => handleClickMoveQuestion('down', questionIndex)}
          />
          <ErrorMessage error={question.error} />
          <SetQuestionType
            question={question}
            questionIndex={questionIndex}
            onSelect={handleSelectQuestionType}
          />
          {question.questionType && (
            <>
              <Box marginBottom="16px">
                <EditQuestionTextField
                  id={`${+questionIndex}-text`}
                  label="Question Text"
                  value={question.text}
                  questionIndex={questionIndex}
                  onChange={(event) => handleChangeQuestionText(event, questionIndex)}
                />
              </Box>
              <EditQuestionTextField
                id={`${+questionIndex}-key-string`}
                label="Question Key"
                value={question.keyString}
                questionIndex={questionIndex}
                onChange={(event) => handleChangeQuestionKeyString(event, questionIndex)}
              />
              <EditQuestionProperty
                value={question.isOptional}
                field="isOptional"
                label="is optional?"
                questionIndex={questionIndex}
                onFlipSwitch={handleFlipSwitch}
              />
              <EditQuestionProperty
                value={question.isSensitive}
                field="isSensitive"
                label="is sensitive?"
                questionIndex={questionIndex}
                onFlipSwitch={handleFlipSwitch}
              />
            </>
          )}
          {(question.questionType === 'MULTI_SELECT' || question.questionType === 'MULTIPLE_CHOICE') && (
            <>
              <AnswerHeader questionIndex={questionIndex} onClick={handleClickAnswerHeaderButton} />
              {question?.answers.map((answer, answerIndex) => (
                <Box key={`${+questionIndex}-${+answerIndex}`}>
                  <Answer
                    questionIndex={questionIndex}
                    answer={answer}
                    answerIndex={answerIndex}
                    onChangeAnswerText={handleChangeAnswerText}
                    onChangeAnswerAssessmentValue={handleChangeAnswerAssessmentValue}
                  />
                  <ErrorMessage error={answer.error} />
                </Box>
              ))}
            </>
          )}
          {questionIndex !== 0 && (
            <>
              <ConditionHeader questionIndex={questionIndex} onClick={handleClickConditionHeaderButton} />
              {question.conditions.map((condition, conditionIndex) => (
                <Box key={`${+questionIndex}-${+conditionIndex}`}>
                  <Condition
                    condition={condition}
                    conditionIndex={conditionIndex}
                    questionIndex={questionIndex}
                    onSelectConditionQuestion={handleSelectConditionQuestion}
                    selectableConditionQuestions={getSelectableConditionQuestions(questionIndex)}
                    onSelectConditionAnswer={handleSelectConditionAnswer}
                    selectableConditionAnswers={getSelectableConditionAnswers(condition?.question)}
                    onSelectConditionNegation={handleSelectConditionNegation}
                  />
                  <ErrorMessage error={condition.error} />
                </Box>
              ))}
            </>
          )}
          <hr />
        </Box>
      ))}
    </Box>
  );

  return (
    <Box height="100%" justifyContent="center">
      <Box height="100%" display="inline">
        <Paper
          elevation={2}
          style={{
            width: '75%',
            padding: '20px 40px 60px 40px',
            margin: '0 auto 0 auto',
          }}
        >
          <AssessmentHeader
            creating={!assessment}
            onUploadFile={handleUploadFile}
            stateToUriJson={stateToUriJson}
            assessmentInternalName={state.nameInternal}
            isLocked={isLocked}
          />
          <hr />
          <Box flexDirection="row">
            <Button
              variant="contained"
              color="default"
              component="label"
              disabled={!editingTranslations}
              onClick={() => { setEditingTranslations(false); }}
            >
              Logic
            </Button>
            <Button
              variant="contained"
              color="default"
              component="label"
              disabled={editingTranslations}
              onClick={() => { setEditingTranslations(true); }}
            >
              Translations
            </Button>
          </Box>
          <hr />
          <Box>
            {editingTranslations ? translationsEditor : assessmentLogicEditor}
          </Box>
          <AssessmentFooter
            onAddQuestion={onAddQuestion}
            onSave={onSaveAssessment}
            saving={saving}
          />
          {state.hasErrors && (<ErrorMessage error="Assessment logic has errors. Scroll through Logic tab to view." />)}
        </Paper>
      </Box>
    </Box>
  );
};

export default AssessmentEditor;
