import React, { useState } from 'react';
import { TextField } from '@material-ui/core';

import Styled from '../Modal.styles';

import { STUDY_QUERY, STUDY_LIST_QUERY } from '@/apollo/queries';
import { UPDATE_STUDY_NAME } from '@/apollo/mutations';
import { MutationUpdateStudyNameArgs } from '@/apollo/types';
import { MutationResultType, GraphQLErrorsType } from '@/types';
import ExclamationSVG from '@/assets/ExclamationCircle.svg';
import { ModalPayloadType } from '@/types/ReduxTypes';
import { useMutation } from '@/hooks/apollo';

type Props = {
  payload: ModalPayloadType['CHANGE_STUDY_NAME'];
  onClose: () => void;
};
const ChangeStudyName: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;
  const [textFieldValues, setTextFieldValues] = useState({ current: '', new: '' });
  const [errors, setErrors] = useState<GraphQLErrorsType>([]);

  const { limbixStudy } = payload;

  const refetchQueries = [
    {
      query: STUDY_QUERY,
      variables: {
        studyUid: limbixStudy.uid,
      },
    },
    {
      query: STUDY_LIST_QUERY,
    },
  ];

  const [updateStudyNameMutation] = useMutation<MutationUpdateStudyNameArgs>(
    UPDATE_STUDY_NAME, { refetchQueries },
  );
  const updateStudyName = (name: string): Promise<MutationResultType> => (
    updateStudyNameMutation({
      variables: {
        studyUid: limbixStudy.uid,
        name,
      },
    })
  );

  const checkDisabled = () => (
    limbixStudy.name !== textFieldValues.current || textFieldValues.new === ''
  );

  const changeNameAndClose = async () => {
    const { errors: resultErrors } = await updateStudyName(textFieldValues.new);
    if (resultErrors) {
      setErrors(resultErrors);
    } else {
      onClose();
    }
  };

  return (
    <div style={{ width: '416px', height: '375px' }}>
      <Styled.DialogContent>
        <div style={{ display: 'flex' }}>
          <Styled.WarningSVGContainer>
            <Styled.WarningSVG src={ExclamationSVG} />
          </Styled.WarningSVGContainer>
          <Styled.TextContainer>
            <Styled.HeaderText>
              Change Study Name
            </Styled.HeaderText>
            <Styled.BodyText>
              {'To change this study\'s name enter its current name verbatim:'}
              <strong>
                {` ${limbixStudy.name} `}
              </strong>
              in the first text input and its new name in the second text input.
              <br />
            </Styled.BodyText>
            {errors.map((error) => (
              <div key={error.message} style={{ color: 'red' }}>
                {error.message}
              </div>
            ))}
          </Styled.TextContainer>
        </div>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="current"
          label="Current Study Name"
          id="current name"
          onChange={(event) => setTextFieldValues((values) => ({
            current: event.target.value,
            new: values.new,
          }))}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="current"
          label="New Study Name"
          id="new name"
          onChange={(event) => setTextFieldValues((values) => ({
            new: event.target.value,
            current: values.current,
          }))}
        />
        <Styled.ButtonContainer>
          <Styled.NoButton onClick={onClose}>
            <Styled.NoButtonText>
              No
            </Styled.NoButtonText>
          </Styled.NoButton>
          <Styled.YesButton onClick={changeNameAndClose} disabled={checkDisabled()}>
            <Styled.YesButtonText>
              Yes - Change Name
            </Styled.YesButtonText>
          </Styled.YesButton>
        </Styled.ButtonContainer>
      </Styled.DialogContent>
    </div>
  );
};

export default ChangeStudyName;
