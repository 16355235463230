import React, { useContext, useState } from 'react';
import { TextField } from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { LoadingSpinner } from 'react-limbix-ui';

import Styled from '../Modal.styles';

import {
  MutationRevokeProviderPortalAccessArgs,
  MutationGrantProviderPortalAccessArgs,
} from '@/apollo/types';
import { MutationResultType, GraphQLErrorsType } from '@/types';
import { GRANT_PROVIDER_PORTAL_ACCESS, REVOKE_PROVIDER_PORTAL_ACCESS } from '@/apollo/mutations';
import { formatPractitionerName } from '@/utils/stringUtils';
import { ModalPayloadType } from '@/types/ReduxTypes';
import { useMutation } from '@/hooks/apollo';
import { ModalLoadingContext } from '@/components/Modal/Modal';

type Props = {
  payload: ModalPayloadType['PORTAL_ACCESS'];
  onClose: () => void;
};
const PortalAccess: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;

  const {
    practitioner,
    onRefetch,
  } = payload;

  const [changeReasonValue, setChangeReasonValue] = useState<string>();
  const [portalAccessTime, setPortalAccessTime] = useState(practitioner.portalAccessTime);
  const [errors, setErrors] = useState<GraphQLErrorsType>([]);
  const { modalLoading, setModalLoading } = useContext(ModalLoadingContext);

  const [revokeProviderPortalAccessMutation] = useMutation<MutationRevokeProviderPortalAccessArgs>(
    REVOKE_PROVIDER_PORTAL_ACCESS,
  );
  const revokePortalAccess = async (changeReason: string): Promise<MutationResultType> => {
    setModalLoading(true);
    const result = await revokeProviderPortalAccessMutation({
      variables: {
        practitionerUid: practitioner.uid,
        changeReason,
      },
    });
    if (onRefetch) {
      await onRefetch();
    }
    setModalLoading(false);
    return result;
  };

  const [grantProviderPortalAccessMutation] = useMutation<MutationGrantProviderPortalAccessArgs>(
    GRANT_PROVIDER_PORTAL_ACCESS,
  );
  const grantPortalAccess = async (accessTime: Date, changeReason: string): Promise<MutationResultType> => {
    setModalLoading(true);
    const result = await grantProviderPortalAccessMutation({
      variables: {
        practitionerUid: practitioner.uid,
        portalAccessTime: accessTime,
        changeReason,
      },
    });
    if (onRefetch) {
      await onRefetch();
    }
    setModalLoading(false);
    return result;
  };

  const updateAccess = async () => {
    const { errors: resultErrors } = practitioner.portalAccessTime
      ? await revokePortalAccess(changeReasonValue)
      : await grantPortalAccess(portalAccessTime, changeReasonValue);
    if (resultErrors) {
      setErrors(resultErrors);
    } else {
      onClose();
    }
  };

  const renderDateField = (
    accessDate: Date,
    setAccessDate: (date: Date) => void,
  ) => (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateTimePicker
        id="datetime-local"
        label="Portal Access Time"
        value={accessDate}
        disabled={!!practitioner?.portalAccessTime}
        fullWidth
        variant="inline"
        margin="normal"
        onChange={(event) => {
          setAccessDate(new Date(event));
        }}
      />
    </MuiPickersUtilsProvider>
  );

  if (modalLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div style={{ width: '416px', height: '375px' }}>
      <Styled.DialogContent>
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ width: '100%' }}>
            <Styled.HeaderText style={{ marginBottom: 5 }}>
              Provider Portal Access
            </Styled.HeaderText>
            <Styled.BodyText>
              Practitioner:
              {` ${formatPractitionerName(practitioner)}`}
            </Styled.BodyText>
            {renderDateField(portalAccessTime, setPortalAccessTime)}
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              required
              name="change reason"
              label="Change Reason"
              id="change reason"
              onChange={(event) => setChangeReasonValue(event.target.value?.trim())}
            />
            {errors.map((error) => (
              <div key={error.message} style={{ color: 'red' }}>
                {error.message}
              </div>
            ))}
          </div>
          <Styled.ButtonContainer>
            <Styled.YesButton
              onClick={updateAccess}
              disabled={!portalAccessTime || !changeReasonValue}
            >
              <Styled.YesButtonText>
                {practitioner.portalAccessTime ? 'Revoke Portal Access' : 'Grant Portal Access'}
              </Styled.YesButtonText>
            </Styled.YesButton>
            <Styled.NoButton onClick={onClose}>
              <Styled.NoButtonText>
                Close
              </Styled.NoButtonText>
            </Styled.NoButton>
          </Styled.ButtonContainer>
        </div>
      </Styled.DialogContent>
    </div>
  );
};

export default PortalAccess;
