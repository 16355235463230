import {
  Button as MuiButton,
  Typography as MuiTypography,
  DialogContent as MuiDialogContent,
} from '@material-ui/core';
import styled from 'styled-components/macro';

const DialogContent = styled(MuiDialogContent)`
  padding: 32px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  padding: 12px;
`;

const NoButton = styled(MuiButton)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  height: 32px;
  background: #FEFEFE;
  border: 1px solid #DADADD;
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 4px;
`;

const NoButtonText = styled(MuiTypography)`
  font-family: Proxima Nova;
  font-size: 14px;
  text-transform: none;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #202625;
`;

const YesButton = styled(MuiButton)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  height: 32px;
  background: ${(props) => (props.disabled ? '#999999' : '#1F2E81')};
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 4px;
  :hover {
    background: #303F92;
  }
`;

const YesButtonText = styled(MuiTypography)`
  font-family: Proxima Nova;
  font-size: 14px;
  text-transform: none;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #FEFEFE;
`;

const HeaderText = styled(MuiTypography)`
  font-family: Proxima Nova;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 8px;
`;

const TextContainer = styled.div`
  margin: 16px;
`;

const WarningSVGContainer = styled.div`
  margin-right: 6px;
  display: inline;
`;

const WarningSVG = styled.img`
  height: 18px;
  width: 18px;
`;

export default {
  DialogContent,
  TextContainer,
  ButtonContainer,
  WarningSVGContainer,
  WarningSVG,
  HeaderText,
  NoButton,
  NoButtonText,
  YesButton,
  YesButtonText,
};
