import Details from './Details';
import Summary from './Summary';
import ResearchAssignments from './ResearchAssignments';
import ResearchAssignmentDetails from './ResearchAssignments/Details';
import FreeFormTexts from './FreeFormTexts';

export default {
  Details,
  Summary,
  FreeFormTexts,
  ResearchAssignments,
  ResearchAssignmentDetails,
};
