import React from 'react';

import EnumSelect from '../EnumSelect';

import {
  LanguageType,
  LimbixUserLanguage,
} from '@/apollo/types';
import { UnknownChangeEvent } from '@/types';
import { LANGUAGE_MAP } from '@/utils/constants';

type Props = {
  currentLanguage: LimbixUserLanguage;
  inline?: boolean;
  disabled?: boolean;
  onChange: (event: UnknownChangeEvent) => void;
  excludedLanguages?: LimbixUserLanguage[];
}

const LanguageDropdown: React.FC<Props> = (props: Props) => {
  const {
    currentLanguage,
    inline,
    disabled,
    onChange,
    excludedLanguages,
  } = props;

  return (
    <EnumSelect
      enumType={LanguageType}
      enumValToLabelMap={LANGUAGE_MAP}
      enumValuesToExclude={excludedLanguages}
      inline={inline}
      disabled={disabled}
      label="Language:"
      value={currentLanguage}
      onSelect={onChange}
    />
  );
};

export default LanguageDropdown;
