import React, { useCallback } from 'react';
import {
  PaginatedTable,
  Link,
  Column,
  Row,
  Box,
  createCell,
  createColumn,
  calculateNewSortState,
} from 'react-limbix-ui';
import { Button } from '@material-ui/core';

import MobileAccess from './MobileAccess';
import AdminActions from './AdminActions';

import { CareTeamType } from '@/apollo/types';
import {
  MutationResultType,
} from '@/types';
import { SearchBar } from '@/components';
import { formatDate } from '@/utils/dateUtils';
import { useModal } from '@/hooks/redux';
import { PaginationState, UpdatePaginationStateType } from '@/hooks/usePagination';
import { UnknownAsyncCallback } from '@/types/DataTypes';

type Props = {
  pdtUid: string;
  careteams?: CareTeamType[];
  resetPatientAccessAttempts?: (userUid: string) => Promise<MutationResultType>;
  searchString?: string;
  onUpdateSearchString: (searchString: string) => void;
  paginationState: PaginationState;
  updatePaginationState: UpdatePaginationStateType;
  totalCount: number;
  onRefetch?: UnknownAsyncCallback;
};
const PatientTable: React.FC<Props> = (props: Props) => {
  const {
    careteams,
    resetPatientAccessAttempts,
    searchString,
    onUpdateSearchString,
    pdtUid,
    paginationState: { sortState, itemsPerPage, currentPage },
    updatePaginationState,
    totalCount,
    onRefetch,
  } = props;

  const { showModal } = useModal();

  const onSearchFilterSubmit = useCallback(() => updatePaginationState({
    searchFilterState: {
      patient__user__email: searchString,
      patient__uid: searchString,
    },
  }), [searchString]);

  const renderUID = (patientUid: string) => {
    const basePDTUrl = `/operations/pdt/${pdtUid}`;
    const linkToUID = `${basePDTUrl}/patient/${patientUid}`;
    return <Link.RouterLink to={linkToUID} fontSize="16px">{patientUid?.substring(0, 6) || 'None'}</Link.RouterLink>;
  };

  const renderFailedAttempts = (careteam: CareTeamType) => (
    !careteam.patient.user.accessAttempts ? '0' : (
      <Box>
        {`${careteam.patient.user.accessAttempts} `}
        <Link.ButtonLink onClick={() => (resetPatientAccessAttempts(careteam.patient.uid))}>
          (reset)
        </Link.ButtonLink>
      </Box>
    )
  );

  const renderAdminActions = (careteam: CareTeamType) => (
    <AdminActions careteam={careteam} />
  );

  const renderMobileAccess = (careteam: CareTeamType) => (
    <MobileAccess careteam={careteam} onRefetch={onRefetch} />
  );

  const renderNotes = (careteam: CareTeamType) => (
    <Link.ButtonLink
      onClick={
        () => showModal(
          'PATIENT_NOTES',
          {
            careteam,
            onRefetch,
          },
        )
      }
    >
      view
    </Link.ButtonLink>
  );

  const renderContactInfo = (careteam: CareTeamType) => (
    <Link.ButtonLink
      onClick={
        () => showModal(
          'PATIENT_CONTACT_INFO',
          {
            careteam,
            onRefetch,
          },
        )
      }
    >
      view
    </Link.ButtonLink>
  );

  const columns: Column[] = [
    createColumn('uid', 'UID', 'string', null, true),
    createColumn('ageAtEnrollment', 'Age at Enrollment', 'number', '5%', false),
    createColumn('currentAge', 'Current Age', 'number', '5%', false),
    createColumn('email', 'Email', 'string', null, true),
    createColumn('emailConfirmed', 'Email Confirmed', 'string', null, true),
    createColumn('failedAttempts', 'Failed Attempts', 'number', null, false),
    createColumn('status', 'Status', 'string', null, false),
    createColumn('accountCreation', 'Account Creation Date', 'date', null, true),
    createColumn('appVersion', 'App Version', 'string', null, false),
    createColumn('admin', 'Admin Actions', 'string', null, false),
    createColumn('access', 'Mobile Access', 'string', null, false),
    createColumn('notes', 'Notes', 'string', null, false),
    createColumn('contactInfo', 'Contact Info', 'string', '8%', false),
  ];

  const rows: Row[] = careteams
    .map((careteam) => {
      const { patient: { user }, patient, userPdtData } = careteam;
      return {
        id: careteam.patient.uid,
        cells: [
          createCell('uid', patient.uid, renderUID(patient.uid)),
          createCell('ageAtEnrollment', user.ageAtEnrollment),
          createCell('currentAge', user.currentAge),
          createCell('email', user.email, user.email),
          createCell('emailConfirmed', `${user.emailVerified}`),
          createCell('failedAttempts', user.accessAttempts, renderFailedAttempts(careteam)),
          createCell('status', careteam.status, careteam.status),
          createCell('accountCreation', new Date(careteam.enrolledTime), formatDate(careteam.enrolledTime)),
          createCell('appVersion', userPdtData?.appVersion, userPdtData?.appVersion || ''),
          createCell('admin', 'Admin Actions', renderAdminActions(careteam)),
          createCell('access', 'Mobile Access', renderMobileAccess(careteam)),
          createCell('notes', 'Notes', renderNotes(careteam)),
          createCell('contactInfo', 'contactInfo', renderContactInfo(careteam)),
        ],
      } as Row;
    });

  const handleSortByColumn = useCallback(async (colId: string) => {
    const newSortState = calculateNewSortState(colId, sortState);
    updatePaginationState({ sortState: newSortState });
  }, [sortState.columnId, sortState.direction]);

  const onGoToPage = useCallback(async (pageNum: number) => (
    updatePaginationState({ currentPage: pageNum })
  ), []);

  return (
    <Box>
      <Box marginBottom="20px" float="right" display="flex" flexDirection="row">
        <SearchBar
          placeholder="Filter Patients"
          value={searchString}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => (
            onUpdateSearchString(event.currentTarget.value?.toLowerCase() || '')
          )}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={onSearchFilterSubmit}
        >
          Filter
        </Button>
      </Box>
      <PaginatedTable
        ariaLabel="patients_table"
        columns={columns}
        rows={rows}
        sortState={sortState}
        onSortByColumn={handleSortByColumn}
        onGoToPage={onGoToPage}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        totalCount={totalCount}
        skipRowSort
      />
    </Box>
  );
};

export default PatientTable;
