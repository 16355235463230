import {
  OperationVariables,
  DocumentNode,
  useQuery as apolloUseQuery,
} from '@apollo/client';

import { Query } from '@/apollo/types';
import { QueryResult, QueryHookOptions } from '@/types';

const useQuery = <T extends OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<T | OperationVariables>,
): QueryResult<T | OperationVariables> => apolloUseQuery<Query, T | OperationVariables>(query, options);

export default useQuery;
