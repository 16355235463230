import React from 'react';
import { Link, Box } from 'react-limbix-ui';

import { CareTeamType } from '@/apollo/types';
import { useModal } from '@/hooks/redux';
import { UnknownAsyncCallback } from '@/types/DataTypes';

type Props = {
  careteam: CareTeamType;
  onRefetch?: UnknownAsyncCallback;
}
const MobileAccess: React.FC<Props> = (props: Props) => {
  const { careteam, onRefetch } = props;
  const { showModal } = useModal();

  const showRevokeAccessModal = () => (showModal(
    'REVOKE_PATIENT_ACCESS',
    {
      careteam,
      onRefetch,
    },
  ));

  const renderMobileAccess = () => {
    if (careteam.canAccessPdt) {
      return (
        <Box>
          <Link.ButtonLink onClick={showRevokeAccessModal}>
            Revoke
          </Link.ButtonLink>
        </Box>
      );
    }

    if (!careteam.canAccessPdt && careteam.hasMobileTokens) {
      return (
        <Box>
          <Link.ButtonLink onClick={showRevokeAccessModal}>
            Revoke (Should not have access to PDT)
          </Link.ButtonLink>
        </Box>
      );
    }
    return 'No Mobile Access';
  };

  return (
    <Box>
      {renderMobileAccess()}
    </Box>
  );
};

export default MobileAccess;
